const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { NavLink: Link } = require('react-router-dom');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const UserListItem = require('components/UserListItem');
const UnreadMessagesBadge = require('../../../../../routes/app/components/UnreadMessagesBadge');
const { getSizedImageUrl } = require('utils/image');
const { default: Classes } = require('./styles.scss');
const AppContextFilter = require('containers/AppContextFilter');
const NoUserProfilePic = require('components/NoUserProfilePic');
const FuzzyFilter = require('utils/fuzzyFilter');
const Loader = require('../../../../../components/Loader');
const ItemBody = require('../../../components/Chat/ItemBody');
const { default: TextField } = require('@mui/material/TextField');
const { default: Paper } = require('@mui/material/Paper');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: Grid } = require('@mui/material/Grid');
const IsEqual = require('lodash/isEqual');
const Switch = require('components/Switch');

const { MESSAGES_SORT_TYPE } = require('utils/constants');

const internals = {};

class DirectMessagesPage extends React.PureComponent {

    static propTypes = {
        connectionsFilter: T.string,
        allMessagesSortType: T.string,
        setAllMessagesSortType: T.func,
        push: T.func,
        dmsLoading: T.bool,
        updateSortPreference: T.func,
        lastMessagesLoading: T.bool,
        dms: T.arrayOf(T.shape({
            sid: T.any.isRequired,
            dateUpdated: T.instanceOf(Date),
            numUnreads: T.number,
            attributes: T.shape({
                user: T.shape({
                    id: T.any.isRequired,
                    firstName: T.string.isRequired,
                    lastName: T.string.isRequired,
                    croppedPicture: T.string.isRequired,
                    roleId:  T.number
                })
            })
        })),
        peersNoChannel: T.arrayOf(
            T.shape({
                id: T.any.isRequired,
                firstName: T.string.isRequired,
                lastName: T.string.isRequired,
                croppedPicture: T.string.isRequired,
                roleId:  T.number
            })),
        rolesInteractions:T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool
        })),
        rolePermissions:T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewBadge: T.bool
        }),
        showNotification: T.func,
        setConnectionsFilter: T.func,
        activeFilterRoleGroup: T.object,
        appContextFilter: T.string
    }

    constructor(props) {

        super(props);

        this.state = {
            dms: this.sortDms([...props.dms,...props.peersNoChannel]),
            filter: ''
        };

        this.updateFilter = this._updateFilter.bind(this);
        this.handleSortFilterChange = this._handleSortFilterChange.bind(this);
        this.scrollToTop = this._scrollToTop.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);
    }

    _updateFilter(event) {

        this.setState({ filter: event.target.value });
        this.props.setConnectionsFilter({
            filter:event.target.value
        });
    }

    _scrollToTop() {

        window.scrollTo(0, 0);
    }

    _handleSortFilterChange(event) {

        const { checked } = event.target;

        const filter = checked ? MESSAGES_SORT_TYPE.BY_NAME : MESSAGES_SORT_TYPE.BY_DATE_UPDATED;

        this.props.setAllMessagesSortType({
            sortType: filter
        });
        this.props.updateSortPreference(filter);
    }

    // Only re-sort dms when they change
    UNSAFE_componentWillReceiveProps(newProps) {

        if (!IsEqual(newProps.dms,this.props.dms)) {
            this.setState({
                dms: this.sortDms([...newProps.dms,...newProps.peersNoChannel])
            });
        }
    }

    sortDms(dms) {

        const sortFilter = this.props && this.props.allMessagesSortType;

        return dms.sort((dm1, dm2) => {

            // when it's sort by name they don't want eany other sort to be included
            if (sortFilter === MESSAGES_SORT_TYPE.BY_NAME){

                const user1Name = dm1 && dm1.attributes && dm1.attributes.user ? dm1.attributes.user.firstName : dm1.firstName;
                const user1LastName = dm1 && dm1.attributes && dm1.attributes.user ? dm1.attributes.user.lastName : dm1.lastName;
                const user2Name = dm2 && dm2.attributes && dm2.attributes.user ? dm2.attributes.user.firstName : dm2.firstName;
                const user2LastName = dm2 && dm2.attributes && dm2.attributes.user ? dm2.attributes.user.lastName : dm2.lastName;

                return user1Name.localeCompare(user2Name) || user1LastName.localeCompare(user2LastName);
            }

            let hasUnreadResult;
            let dateUpdatedResult;
            let hasMessageResult;

            if (dm1.numUnreads === dm2.numUnreads){
                hasUnreadResult = 0;
            }
            else {
                hasUnreadResult = (dm1.numUnreads < dm2.numUnreads) ? 1 : -1;
            }

            if (dm1.dateUpdated === dm2.dateUpdated) {
                dateUpdatedResult = 0;
            }
            else if (dm1.dateUpdated === undefined) {
                dateUpdatedResult = 1;
            }
            else if (dm2.dateUpdated === undefined) {
                dateUpdatedResult = -1;
            }
            else {
                dateUpdatedResult = (dm1.dateUpdated < dm2.dateUpdated) ? 1 : -1;
            }

            if ((dm1.lastMessage && dm2.lastMessage) || (dm1.lastMessage === null && dm2.lastMessage === null) || (dm1.lastMessage === undefined && dm2.lastMessage === undefined)) {
                hasMessageResult = 0;
            }
            else if (dm1.lastMessage === null || dm1.lastMessage === undefined) {
                hasMessageResult = 1;
            }
            else if (dm2.lastMessage === null || dm2.lastMessage === undefined) {
                hasMessageResult = -1;
            }
            // Bubble unreads to top
            /*   // TODO: do we want to keep this?
            if (dm1.hasUnreads !== dm2.hasUnreads) {
                return dm2.hasUnreads ? 1 : -1;
            }
            */
            // First those that have message then filter than by numOFUnreads

            return hasMessageResult || (sortFilter === MESSAGES_SORT_TYPE.BY_DATE_UPDATED ? dateUpdatedResult : (dm1.attributes.user.firstName.localeCompare(dm2.attributes.user.firstName) || dm1.attributes.user.lastName.localeCompare(dm2.attributes.user.lastName))) || hasUnreadResult;
        });
    }

    getFilteredDms() {

        const {
            connectionsFilter,
            activeFilterRoleGroup,
            appContextFilter
        } = this.props;

        const { dms } = this.state;

        const contextFiltered = dms.filter((dm) => {

            const roleGroupId = dm && dm.attributes && dm.attributes.user ? dm.attributes.user.role.roleGroupId : dm.role.roleGroupId;

            return appContextFilter === 'all' || activeFilterRoleGroup.id === roleGroupId;
        });

        const sorted = this.sortDms(contextFiltered);

        if (!connectionsFilter) {
            return sorted;
        }

        return sorted.filter((dm) => {

            const key =  dm && dm.attributes && dm.attributes.user ? `${dm.attributes.user.firstName} ${dm.attributes.user.lastName}` : `${dm.firstName} ${dm.lastName}`;

            return FuzzyFilter( connectionsFilter, key );
        });
    }

    renderLastMessage(dm) {

        const { SecondaryTextContainer } = internals;

        if (dm.lastMessage) {
            return <SecondaryTextContainer>
                <ItemBody>
                    {dm.lastMessage.body}
                </ItemBody>
            </SecondaryTextContainer>;
        }

        return <span style={{ fontWeight:'bold' }}>
            Click to start your conversation...
        </span>;
    }

    render() {

        const {
            rolesInteractions,
            showNotification,
            rolePermissions: { canViewBadge },
            dmsLoading,
            lastMessagesLoading,
            allMessagesSortType,
            push
        } = this.props;

        const {
            styles,
            StyledTextField
        } = internals;

        const { filter, dms } = this.state;

        const hasDms = !!dms.length;
        const filteredDms = this.getFilteredDms();

        return <div className={Classes.tabSearchOuterWrapper}>
            <Grid component='div' justifyContent={'center'} container alignItems='center' spacing={1}>
                <Grid item>Sort by Newest Chat</Grid>
                <Grid item>
                    <Switch
                        color='primary'
                        inputProps={{ 'aria-label': 'Sort messages filter switch' }}
                        checked={allMessagesSortType === MESSAGES_SORT_TYPE.BY_NAME}
                        onChange={this.handleSortFilterChange} name='checkedC'
                    />
                </Grid>
                <Grid item>Sort by User Name</Grid>
            </Grid>
            <Paper className={`${Classes.fixedTabSearch} contentWrapper`}>
                <Grid container spacing={1} alignItems='flex-end' justifyContent={'center'}>
                    <Grid item>
                        <SearchIcon />
                    </Grid>
                    <Grid item>
                        <StyledTextField
                            id='connections-search'
                            label='Search names'
                            primary
                            fullWidth
                            InputProps={{
                                disableUnderline:true
                            }}
                            value={filter}
                            inputStyle={styles.search}
                            hintStyle={styles.searchHint}
                            onChange={this.updateFilter}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <div className={Classes.pageWrapper}>
                <div className={Classes.messagesWrapper}>

                    <div style={{
                        margin: '0 0 10px',
                        textAlign: 'center'
                    }}>
                        <AppContextFilter />
                    </div>

                    {(!!dmsLoading || !!lastMessagesLoading) && <Loader /> }

                    {(!dmsLoading && !lastMessagesLoading) && <List style={styles.list}>
                        {!hasDms && <ListItem
                            style={styles.listItem}
                        >
                            <ListItemText
                                secondary={<span className={Classes.center}>
                                    <span className={Classes.maybeBreak}>Start a chat and your message list</span>&nbsp;
                                    <span className={Classes.maybeBreak}>will appear here!</span>
                                </span>}
                            />
                        </ListItem>}
                        {hasDms && !filteredDms.length && <ListItem
                            style={styles.listItem}
                        >
                            <ListItemText
                                secondary={<span className={Classes.center}>No messages found</span>}
                            />
                        </ListItem>}
                        {filteredDms.map((dm, index) => {

                            const user = dm?.attributes?.user ? dm.attributes.user : dm;

                            let foundRoleInteraction = null;
                            foundRoleInteraction = rolesInteractions.find((roleInteraction) => {

                                return roleInteraction.id === user.roleId;
                            });

                            const canViewProfile = foundRoleInteraction ? foundRoleInteraction.canViewProfile : false;
                            const canChat = foundRoleInteraction ? foundRoleInteraction.canChat : false;

                            if (!foundRoleInteraction) {
                                console.warn('User role interaction not found', { rolesInteractions, user });
                            }

                            const userFullName = `${user.firstName} ${user.lastName}`;

                            return (
                                <UserListItem
                                    key={dm.id}
                                    user={user}
                                    dm={dm}
                                    canViewBadge={canViewBadge}
                                    rightArrowAriaLabel={`Go to private chat with ${userFullName}, ${dm.numUnreads || 0} unreads`}
                                    secondaryText={this.renderLastMessage(dm)}
                                    onClick={() => {

                                        if (!canChat) {
                                            showNotification('Sorry, you cannot chat with this user');
                                        }
                                    }}
                                    link={!canChat ? null : `/app/messaging/${dm.sid ? dm.sid : `u:${dm.id}`}`}
                                    push={push}
                                    leftAvatar={<React.Fragment>
                                        {canViewProfile ?
                                            <div>
                                                <Link
                                                    className={Classes.avatarHolder}
                                                    aria-label={`${userFullName}'s profile`}
                                                    to={`/app/profile/${user.id}`}
                                                    style={{ display: 'inline-block' }}
                                                    data-focus-outline='radius:25,padding:2,zIndex:1'
                                                >
                                                    {user.croppedPicture
                                                        ? (
                                                            <Avatar
                                                                src={getSizedImageUrl(user.croppedPicture, 100)}
                                                                alt={`${userFullName}'s profile`}
                                                                aria-label={`${userFullName}'s profile`}
                                                            />
                                                        )
                                                        : <Avatar alt={`${userFullName}'s profile`} aria-label={`${userFullName}'s profile`}>
                                                            <NoUserProfilePic
                                                                iconColor={'#ffffff'}
                                                                insideAvatar={true}
                                                                outlined={false}
                                                                data-focus-outline='radius:25,padding:2,zIndex:1'
                                                            />
                                                        </Avatar>
                                                    }
                                                </Link>
                                                <UnreadMessagesBadge className={Classes.badge} numUnreads={dm.numUnreads || 0} />
                                            </div> :
                                            <div>
                                                <a
                                                    className={Classes.avatarHolder}
                                                    data-focus-outline='radius:25,padding:2,zIndex:1'
                                                    style={{ display: 'inline-block' }}
                                                >
                                                    {user.croppedPicture
                                                        ? (
                                                            <Avatar
                                                                src={getSizedImageUrl(user.croppedPicture, 100)}
                                                                aria-label={`${userFullName}'s profile`}
                                                            />
                                                        )
                                                        : <Avatar aria-label={`${userFullName}'s profile`}>
                                                            <NoUserProfilePic
                                                                iconColor={'#ffffff'}
                                                                insideAvatar={true}
                                                                outlined={false}
                                                                data-focus-outline='radius:25,padding:2,zIndex:1'
                                                            />
                                                        </Avatar>
                                                    }
                                                </a>
                                                <UnreadMessagesBadge className={Classes.badge} numUnreads={dm.numUnreads || 0} />
                                            </div>
                                        }
                                    </React.Fragment>}
                                />
                            );
                        })}
                    </List>}
                </div>
            </div>
        </div>;
    }
}

module.exports = DirectMessagesPage;

internals.styles = {
    list: {
        paddingTop: 0
    },
    listItem:{
        textAlign:'center'
    }
};

internals.StyledTextField = Styled(TextField)`
  &.MuiInputLabel-formControl {
    position: relative;

    &:not(.MuiInputLabel-shrink) {
      transform: translate(0, 22px) scale(1);
    }
  }
`;

internals.SecondaryTextContainer = Styled.div`
    display: inline-block;
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.light};
    padding: 0.25rem 0.5rem;
    border-radius: 8px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;
