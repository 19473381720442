const { connect } = require('react-redux');
const { context } = require('app-context');
const { push: Push } = require('connected-react-router');
const ManageGroupsDialog = require('../components/ManageGroupsDialog');

const selectors = context.selectors.all;
const actions   = context.actions;

const redux = context.redux.hooks;

const internals = {};

module.exports = connect(
    (state) => {

        const allClasses = selectors.getClasses(state);

        const sortedClasses = !!allClasses && !!allClasses.length ? allClasses.sort((a, b) => {

            return a.name.localeCompare(b.name);
        }) : [];

        return ({
            classesSearch: selectors.getClasses_forClassSearch(state),
            allClasses: sortedClasses
        });
    },
    (dispatch, { user }) => ({
        // mapDispatchToProps and mapStateToProps can receive `ownProps` as a 2nd argument in case you
        // need passed props in these funcs
        onRequestSearch: internals.fetchClassSearchResults,
        fetchClasses: ({ managedUserId }) => dispatch(actions.dataFetching.fetchClasses({ managedUserId })),
        onClickAddToGroup: ({ id, name }) => dispatch(actions.classes.join({ id, name, userId: user.id })),
        onClickRemoveFromGroup: ({ id, name }) => dispatch(actions.classes.leave({ id, name, userId: user.id })),
        push: (...args) => dispatch(Push(...args))
    })
)(ManageGroupsDialog);

// Need to store this func so it doesn't get re-generated every time mapDispatchToProps is run
// This is a new need because now that we're reading from passed props, redux knows to re-run mapDispatchToProps
// in case any of them have changed. The func itself is connected

// Normally re-evaluating these actions wouldn't really matter. In this particular component though, we're === checking
// to see if onRequestSearch has changed in the `componentWillReceiveProps` method.
// Without defining this in internals, the anonymous func below will be generated each render and the === check will always fail.
internals.fetchClassSearchResults = (q) => redux.dispatch(actions.dataFetching.fetchClassSearchResults(q));
