const { ELEMENT_IDS } = require('utils/constants');

// The height of header and footer scroll siblings, plus 30px
exports.CHAT_FRAME_OFFSET = 126;

const internals = {};

exports.getMaxScroll = () => {

    const scrollContainer = internals.getScrollContainer();

    if (!scrollContainer) {
        return 0;
    }

    const containerHeight = scrollContainer.clientHeight - exports.CHAT_FRAME_OFFSET;

    return Math.max(
        scrollContainer.scrollHeight,
        scrollContainer.offsetHeight,
        scrollContainer.clientHeight
    ) - containerHeight;
};

exports.scrollToBottom = () => {

    const scrollContainer = internals.getScrollContainer();

    if (!scrollContainer) {
        return;
    }

    setTimeout(() => {

        scrollContainer.scroll(
            0,
            exports.getMaxScroll(scrollContainer)
        );
    }, 0);
};

internals.getScrollContainer = () => {

    const scrollContainer = internals.scrollContainer || document.getElementById(ELEMENT_IDS.scrollContainer);

    if (!internals.scrollContainer) {
        internals.scrollContainer = scrollContainer;
    }

    return scrollContainer;
};
