const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { CHATS: Types } = require('../action-types');
const { MESSAGES_SORT_TYPE } = require('../utils/constants');

module.exports = (context) => {

    const api = context.api.nearpeer;

    return {
        clear:                    makeActionCreator(Types.CLEAR,                         { key: T.string }),
        setAllMessagesSortType:   makeActionCreator(Types.SET_ALL_MESSAGES_SORTING_TYPE, { sortType: T.oneOf([MESSAGES_SORT_TYPE.BY_NAME, MESSAGES_SORT_TYPE.BY_DATE_UPDATED]) }),
        addTwilioUserToChat:      makeActionCreator(Types.ADD_TWILIO_USER,               { userId: T.number, channelSid: T.string }, {
            async: api.communication.addTwilioUserToChannel
        }),
        setCollapseHeadersDms:    makeActionCreator(Types.SET_COLLAPSE_HEADERS_DMS,      { setting: T.bool }),
        setCollapseHeadersGroups: makeActionCreator(Types.SET_COLLAPSE_HEADERS_GROUPS,   { setting: T.bool })
    };
};
