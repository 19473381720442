const AppLayout = require('containers/AppLayout');
const WelcomePage = require('./welcome/containers/WelcomePage');
const NotFound = require('./components/NotFound');
const ProfileDetailPage = require('./profile/containers/DetailPage');
const ProfileEditPage = require('./profile/containers/EditPage');
const ChangePasswordPage = require('./profile/components/ChangePasswordPage');
const InterestsEditPage = require('./profile/containers/EditInterests');
const DirectMessagesPage = require('./connections/containers/DirectMessagesPage');
const BugReportPage = require('./bugReport/containers/BugReportPage');
const Loader = require('components/Loader');
const UserPreferencesPage = require('./userPreferences/containers/UserPreferencesPage');
const DirectMessageDetailPage = require('./containers/Chat/DirectMessageDetailPage');
const MyGroupsPage = require('./classes/containers/MyGroupsPage');
const ClassDetailPage = require('./classes/containers/ClassDetailPage');
const ClassChatFrame = require('./classes/containers/ClassChatFrame');
const AnnouncementClassChatFrame = require('./classes/containers/AnnouncementClassChatFrame');
const ClassmateSearchPage = require('./discover/containers/ClassmateSearchPage');
const CreateGroupPage = require('./classes/containers/CreateGroupPage');
const EditGroupPage = require('./classes/containers/EditGroupPage');
const AdminContainer = require('./admin/components/AdminContainer');
const BatchNotify = require('./admin/containers/BatchNotify');
const PreapprovedEmailUpload = require('./admin/containers/PreapprovedEmailUpload');
const SurveysContainer = require('./surveys/components/SurveysContainer');
const SurveysManagement = require('./surveys/containers/SurveysManagement');
const SurveysCreatePage = require('./surveys/containers/SurveyCreate');
const SurveysEditPage = require('./surveys/containers/SurveyEdit');
const SurveyReportPage = require('./surveys/containers/SurveyReport');
const MySurveysPage = require('./surveys/containers/MySurveys');
const SurveySolvePage = require('./surveys/containers/SurveySolvePage');
const { context } = require('app-context');
const { USERS_SORT_TYPE, SEARCH_REFRESH_TIME_LIMIT } = require('utils/constants');
const { getUserId } = require('utils/analytics');
const FallbackSpinner = require('./helpers/fallback-spinner');
const DelayedFallbackSpinner = require('./helpers/delayed-fallback-spinner');

const twilio = context.api.twilio;
const actions = context.actions;
const selectors = context.selectors.all;
const handlers = context.handlers;
const redux = context.redux.hooks;

module.exports = (store) => ({
    path: '/app',
    component: AppLayout,
    fallback: FallbackSpinner,
    wrapFallbackWithComponent: true,
    onWillMount: async ({ location: { pathname: path }, match }) => {

        try {
            await handlers.auth.resolveIsAuthenticated();
            const state = redux.getState();
            const isVerified = selectors.getVerifiedStatus(state);

            if (!isVerified) {
                redux.dispatch(actions.auth.logout());
                return { replace: '/signup' };
            }

            if (path === '/login' || match.isExact) {
                return { replace: '/app/welcome' };
            }
        }
        catch (err) {
            console.error(err);
            return { replace: '/login' };
        }

        handlers.communication.startGlobalListening();

        await redux.dispatch(actions.dataFetching.fetchLocalConversations());
        redux.dispatch(actions.dataFetching.fetchLocalMessageCounts());
        redux.dispatch(actions.dataFetching.fetchUnreadLocalMessageCounts());
        redux.dispatch(actions.dataFetching.fetchLocalConversationsUpdateDates());
    },
    onUnmount: () => handlers.communication.stopGlobalListening(),
    childRoutes: [
        {
            path: '/not-found',
            component: NotFound
        },
        {
            path: '/welcome',
            component: WelcomePage,
            onWillMount: () => {

                const state = redux.getState();
                const isAuthenticated = selectors.getIsAuthenticated(state);

                if (isAuthenticated) {
                    redux.dispatch(actions.app.fetchUserReviewAvailableStatus());
                }
            }
        },
        // {
        //     path: '/profile',
        //     exact: true,
        //     component: ProfileDetailPage
        // },
        {
            path: '/profile/edit',
            component: ProfileEditPage,
            exact: true,
            props: {
                // See AnalyticsUtils.onRouteUpdate in 'src/utils/analytics.js' for how this ga prop is handled
                ga: ['navigation', 'edit profile', 'my profile: viewed edit profile']
            }
        },
        {
            path: '/profile/edit/interests',
            exact: true,
            component: InterestsEditPage,
            props: {
                // See AnalyticsUtils.onRouteUpdate in 'src/utils/analytics.js' for how this ga prop is handled
                ga: ['navigation', 'edit interests', 'my interests: viewed edit interests']
            }
        },
        {
            path: '/profile/change-password',
            exact: true,
            component: ChangePasswordPage,
            props: {
                // See AnalyticsUtils.onRouteUpdate in 'src/utils/analytics.js' for how this ga prop is handled
                ga: ['navigation', 'change password', 'viewed change-password']
            }
        },
        {
            path: '/profile/:userId',
            component: ProfileDetailPage,
            fallback: DelayedFallbackSpinner,
            onWillMount: async ({ match: { params } }) => {

                await redux.dispatch(actions.dataFetching.fetchUser({ id: params.userId }));
            },
            props: {
                ga: ({ match }) => {

                    // If the user id is you
                    if (parseInt(match.params.userId) === getUserId()) {
                        return {
                            templateArgs: ['view profile', 'my profile: viewed my profile']
                        };
                    }

                    return {
                        templateArgs: ['viewed peer profile', 'peer profile: viewed peer profile']
                    };
                }
            }
        },
        {
            path: '/discover',
            exact: true,
            component: ClassmateSearchPage,
            onWillMount: () => {
                // At this point in the program, we know we're going to this page but
                // history has _not yet changed_ its pathname.

                // We can take advantage of this by reading the historyPathname in redux

                const state = redux.getState();

                // If coming from a profile page, don't re-run the default search
                // const previousPageIsProfile = state.app.historyPreviousPathname.startsWith('/app/profile/');
                const sortType = selectors.getDiscoverSortType(state);
                const hasPassionInterests = selectors.currentUserHasPassionInterests(state);

                const classmateDiscoverSearchFilter =  selectors.getClassmateDiscoverSearchFilter(state);

                if (classmateDiscoverSearchFilter &&  !(Object.keys(classmateDiscoverSearchFilter).length === 0)) {
                    if (classmateDiscoverSearchFilter.contextFilter) {
                        redux.dispatch(actions.app.setAppContextFilter({ filter: classmateDiscoverSearchFilter.contextFilter }));
                    }
                }

                const filter = classmateDiscoverSearchFilter &&  !(Object.keys(classmateDiscoverSearchFilter).length === 0)
                    ? classmateDiscoverSearchFilter
                    : {
                        allByMatches: true,
                        sortType: sortType === USERS_SORT_TYPE.PASSION && !hasPassionInterests ? USERS_SORT_TYPE.BEST_MATCH : sortType
                    };

                if (sortType === USERS_SORT_TYPE.PASSION && !hasPassionInterests) {
                    redux.dispatch(actions.app.setDiscoverSortType({ type: USERS_SORT_TYPE.BEST_MATCH }));
                }

                const passedRefreshThreshold = Date.now() - state.app.refreshSearchTimestamps.discover > SEARCH_REFRESH_TIME_LIMIT;
                if (state.app.shouldRefreshSearch.discover || passedRefreshThreshold) {
                    redux.dispatch(actions.dataFetching.fetchUserSearchResults.firstPage(filter));
                    redux.dispatch(actions.app.setShouldRefreshSearch({ discover: false }));
                }
            }
        },
        {
            path: '/messaging',
            component: DirectMessagesPage,
            exact: true,
            props: {
                ga: ['viewed connections messages', 'connections: loaded connections messages']
            },
            onWillMount: () => {

                redux.dispatch(actions.app.setAppContextFilter({ filter: 'all' }));
                redux.dispatch(actions.app.setConnectionsFilter({ filter: '' }));
                redux.dispatch(actions.dataFetching.fetchConversationStarters({ schoolId: selectors.getCurrentSchoolId(redux.getState()) }));
                redux.dispatch(actions.dataFetching.fetchChannels());
                redux.dispatch(actions.dataFetching.fetchChannelUpdateDates());
                redux.dispatch(actions.dataFetching.fetchChannelLastMessage());
            }
        },
        {
            path: '/messaging/:dmSid',
            exact: true,
            component: DirectMessageDetailPage,
            fallback: FallbackSpinner,
            props: {
                ga: ['viewed peer chat', 'peer: viewed peer chat']
            },
            onWillMount: async ({ match: { params } }) => {

                const id = params.dmSid;

                const currentUserId = selectors.getCurrentUserId(redux.getState());

                // Formatted e.g. 'u:207' for user with id 207.
                // We want to convert this into a channelSid then redirect :)
                // Links with u:userId are found on the DirectMessagesPage
                // and User Details page for the Chat button
                if (id.slice(0, 2) === 'u:') {
                    // Redirect path
                    const { result: channelSid, error } = await twilio.getDMChannelByUsers(
                        currentUserId,
                        id.slice(2)
                    );

                    if (channelSid && !error) {
                        return { replace: `/app/messaging/${channelSid}` };
                    }

                    const data = await redux.dispatch(actions.communication.createTwilioConversation({
                        user1Id: currentUserId,
                        user2Id: id.slice(2)
                    }));

                    const { payload: { result: { sid } } } = data;
                    return { replace: `/app/messaging/${sid}` };
                }

                // startListening fetches the channel and refreshes messages
                // NOTE it's possible there's some local vs staging fighting going on w/
                // perms to startListening to a channel
                await handlers.communication.startListening({ channelSid: id });

                const users = selectors.getDmUsers_forChat(redux.getState(), id);

                const otherUser = users.find(({ id: _id }) => _id !== currentUserId);

                await redux.dispatch(actions.dataFetching.fetchUser({ id: otherUser.id }));
            },
            onUnmount: ({ history: { action } }) => {

                // Don't fire the stop listening action for 'REPLACE'
                if (action === 'POP' || action === 'PUSH') {
                    handlers.communication.stopListening();
                }
            }
        },
        {
            path: '/classes',
            exact: true,
            component: MyGroupsPage,
            onWillMount: () => {

                //resetFilter
                redux.dispatch(actions.app.setAppContextFilter({ filter: 'all' }));
                redux.dispatch(actions.dataFetching.fetchLocalConversationsUpdateDates());
            }
        },
        {
            path: '/classes/create',
            exact: true,
            component: CreateGroupPage,
            onWillMount: () => {

                // Redundant w/ AppLayout container
                // Performed totally out of paranoia, to doublecheck
                // that an unauthorized user never gets here
                const state = redux.getState();
                const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                if (!rolePermissions.canCreateGroups) {
                    return { replace: '/app/not-found' };
                }
            }
        },
        {
            path: '/classes/:id',
            exact: true,
            component: ClassDetailPage,
            onWillMount: ({ match: { params } }) => {

                const classId = params.id;

                const state = redux.getState();

                const classHasBeenSearched = state.dataFetching.index.classUserSearchResults && state.dataFetching.index.classUserSearchResults[classId];

                // If coming from a profile page, don't re-run the default search
                const previousPageIsProfile = state.app.historyPreviousPathname.startsWith('/app/profile/');

                if (!previousPageIsProfile) {
                    redux.dispatch(actions.app.setAppContextFilter({ filter: 'all' }));
                }

                const classSearchText = selectors.getClassSearchTextById(state, params.id);

                const passedRefreshThreshold = Date.now() - state.app.refreshSearchTimestamps.group > SEARCH_REFRESH_TIME_LIMIT;
                if (classSearchText) {
                    redux.dispatch(actions.dataFetching.fetchClassUserSearchResults.firstPage(
                        {
                            classId,
                            name: classSearchText
                        }
                    ));
                }
                else if (state.app.shouldRefreshSearch.group || !classHasBeenSearched || passedRefreshThreshold) {
                    redux.dispatch(actions.dataFetching.fetchClassUserSearchResults.firstPage(
                        {
                            allByMatches: true,
                            classId
                        }
                    ));
                    redux.dispatch(actions.app.setShouldRefreshSearch({ group: false }));
                }
            }
        },
        {
            path: '/classes/:id/edit',
            exact: true,
            component: EditGroupPage,
            onWillMount: () => {

                // Redundant w/ AppLayout container
                // Performed totally out of paranoia, to doublecheck
                // that an unauthorized user never gets here
                const state = redux.getState();
                const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                if (!rolePermissions.canEditGroups) {
                    return { replace: '/app/not-found' };
                }
            }
        },
        {
            path: '/classes/chat/:sid',
            component: ClassChatFrame,
            exact: true,
            props: {
                ga: ['viewed class messages', 'class: viewed class messages']
            },
            onWillMount: async ({ match: { params } }) => {

                const state = redux.getState();
                const { sid } = params;
                const id = selectors.getClassIdBySid(state, sid);

                const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                if ((!id || (!selectors.currentUserIsInClass(state, id) && !rolePermissions.canSeeChatMessagesNoJoin))) {
                    return { replace: '/app/not-found' };
                }

                if (!selectors.currentUserIsInClass(state, id) && rolePermissions.canSeeChatMessagesNoJoin) {
                    const currentUserId = selectors.getCurrentUserId(redux.getState());
                    await redux.dispatch(actions.chats.addTwilioUserToChat({ userId: currentUserId, channelSid: sid }));
                }

                redux.dispatch(actions.dataFetching.fetchClass({ id }));
                handlers.communication.startListening({ channelSid: sid });
            },
            onUnmount: () => handlers.communication.stopListening()
        },
        {
            path: '/classes/announcement/chat/:id',
            component: AnnouncementClassChatFrame,
            exact: true,
            props: {
                ga: ['viewed announcement class messages', 'class: viewed announcement class messages']
            },
            onWillMount: async ({ match: { params } }) => {

                const state = redux.getState();
                const { id } = params;

                const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                if (!id || (!selectors.currentUserIsInClass(state, id) && !rolePermissions.canSeeChatMessagesNoJoin)) {
                    return { replace: '/app/not-found' };
                }

                await redux.dispatch(actions.dataFetching.fetchClass({ id }));

                await redux.dispatch(actions.dataFetching.fetchLocalGroupConversation({ id }));
                await redux.dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id }));
                // FETCH MESSAGES FOR ANNOUNCEMENT CLASS
            }
        },
        {
            path: '/admin',
            component: AdminContainer,
            childRoutes: [
                {
                    path: '/batch-notify-users',
                    component: BatchNotify,
                    onWillMount: () => {

                        // Redundant w/ AppLayout container
                        // Performed totally out of paranoia, to doublecheck
                        // that an unauthorized user never gets here
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canBatchNotify) {
                            return { replace: '/app/not-found' };
                        }
                    }
                },
                {
                    path: '/preapprove-users',
                    component: PreapprovedEmailUpload,
                    onWillMount: () => {

                        // Redundant w/ AppLayout container
                        // Performed totally out of paranoia, to doublecheck
                        // that an unauthorized user never gets here
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canPreapprove) {
                            return { replace: '/app/not-found' };
                        }
                    }
                }
            ]
        },
        {
            path: '/surveys',
            component: SurveysContainer,
            onWillMount: () => {

                redux.dispatch(actions.dataFetching.fetchSurveys());
                //FETCH MESSAGES FOR ANNOUNCEMENT CLASS
            },
            childRoutes: [
                {
                    path: '/create',
                    component: SurveysCreatePage,
                    onWillMount: ({ location }) => {
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canCreateSurveys) {
                            return { replace: '/app/not-found' };
                        }

                        // Check for copyFrom parameter
                        const searchParams = new URLSearchParams(location.search);
                        const copyFromId = searchParams.get('copyFrom');

                        if (copyFromId) {
                            redux.dispatch(actions.dataFetching.fetchSurvey({
                                id: copyFromId,
                                includeUserAnswers: false
                            }));
                        }
                    }
                },
                {
                    path: '/:id/edit',
                    component: SurveysEditPage,
                    onWillMount: ({ match: { params } }) => {

                        // Redundant w/ AppLayout container
                        // Performed totally out of paranoia, to doublecheck
                        // that an unauthorized user never gets here
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canCreateSurveys) {
                            return { replace: '/app/not-found' };
                        }

                        const surveyId = params.id;

                        redux.dispatch(actions.dataFetching.fetchSurvey({ id: surveyId, includeUserAnswers: false }));
                    }
                },
                {
                    path: '/:id/report',
                    component: SurveyReportPage,
                    onWillMount: () => {

                        // Redundant w/ AppLayout container
                        // Performed totally out of paranoia, to doublecheck
                        // that an unauthorized user never gets here
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canSeeSurveysReports) {
                            return { replace: '/app/not-found' };
                        }
                    }
                },
                {
                    path: '/:id/start',
                    component: SurveySolvePage,
                    onWillMount: ({ match: { params } }) => {

                        const surveyId = params.id;

                        const state = redux.getState();
                        const userSurvey = selectors.getCurrentUserSurveys_byId(state,params.id);

                        if (userSurvey && userSurvey.isActive) {
                            redux.dispatch(actions.dataFetching.fetchSurvey({ id: surveyId, includeUserAnswers: true }));
                        }
                        else {
                            return { replace: '/app/not-found' };
                        }
                    }
                },
                {
                    path: '/my-surveys',
                    component: MySurveysPage
                },
                {
                    path: '/management',
                    component: SurveysManagement,
                    onWillMount: () => {

                        // Redundant w/ AppLayout container
                        // Performed totally out of paranoia, to doublecheck
                        // that an unauthorized user never gets here
                        const state = redux.getState();
                        const rolePermissions = selectors.getCurrentUserRolePermissions(state);

                        if (!rolePermissions.canCreateSurveys) {
                            return { replace: '/app/not-found' };
                        }
                    }
                }
            ]
        },
        {
            path: '/bug-report',
            component: BugReportPage
        },
        {
            path: '/preferences',
            component: UserPreferencesPage,
            onWillMount: () => redux.dispatch(actions.dataFetching.fetchPreferences())
        },
        {
            path: '/loading',
            component: Loader
        },
        {
            redirect: {
                // Fallback redirect
                to: '/app/welcome'
            }
        }
    ]
});
