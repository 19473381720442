const React = require('react');
const T = require('prop-types');

const { default: RadioGroup } = require('@mui/material/RadioGroup');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormLabel } = require('@mui/material/FormLabel');

const { useCallback } = React;

module.exports = function RadioButtonGroup(props) {

    const {
        label,
        labelProps,
        labelId,
        valueSelected,
        required,
        ...rest
    } = props;

    // Grab the input ref
    const ref = useCallback((el) => {

        if (el) {
            const radioElements = el.querySelectorAll('.MuiRadio-root');
            // Remove the role attribute to prevent nested focusable inputs
            radioElements.forEach((radio) => {

                radio.removeAttribute('role');
            });
        }
    }, []);

    return (
        <FormControl required={required}>
            <FormLabel id={labelId} {...labelProps}>{label}</FormLabel>
            <div role='radiogroup' aria-labelledby={labelId}>
                <RadioGroup
                    ref={ref}
                    value={valueSelected}
                    aria-label={labelId}
                    {...rest}
                />
            </div>
        </FormControl>
    );
};

module.exports.propTypes = {
    // Just calling out that this is important but not mentioned
    // children: T.node,
    label: T.string.isRequired,
    labelId: T.string.isRequired,
    labelProps: T.string,
    valueSelected: T.any.isRequired,
    required: T.bool
};
