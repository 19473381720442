const React = require('react');
const T = require('prop-types');

const { default: Classes } = require('./styles.scss');

// eslint-disable-next-line react/display-name
const Emoji = React.memo(({ label, symbol, ...rest }) => {

    return (<div className={Classes.emojiContainer} aria-label={label} {...rest}>
        {symbol}
    </div>);
});

Emoji.propTypes = {
    label:T.string,
    symbol:T.string.isRequired
};

module.exports = Emoji;
