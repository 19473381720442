const { connect } = require('react-redux');
const { context } = require('app-context');
const DirectMessagesPage = require('../components/DirectMessagesPage');
const { push: Push } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

const updateSortPreference = (sortType) => {

    return function (dispatch) {

        return dispatch(actions.profileManagement.updateUserPreferences({
            sortMessagesBy: sortType
        }))
            .catch((error) => {

                console.error('Failed to update sort preference:', error);
            });
    };
};

module.exports = connect(
    (state) => ({
        dms: selectors.getDms_forDirectMessagesPage(state) || [],
        peersNoChannel: selectors.getPeers_NoChannel_forDirectMessagesPage(state) || [],
        dmsLoading: selectors.getDmsIsLoading_forDirectMessagesPage(state) ,
        lastMessagesLoading: selectors.getLastMessageIsLoading_forDirectMessagesPage(state) ,
        appContextFilter: selectors.getAppContextFilter(state),
        activeFilterRoleGroup: selectors.getActiveFilterRoleGroup_byName(state,selectors.getAppContextFilter(state)),
        rolesInteractions:selectors.getCurrentUserRoleInteractions(state),
        rolePermissions:selectors.getCurrentUserRolePermissions(state),
        connectionsFilter: selectors.getConnectionsFilter(state),
        allMessagesSortType: selectors.getSortMessagesByPreference(state)
    }), {
        showNotification: (msg) => actions.alerts.notification.push({ message: msg }),
        setConnectionsFilter: actions.app.setConnectionsFilter,
        setAllMessagesSortType: actions.chats.setAllMessagesSortType,
        updateSortPreference,
        push: Push
    }
)(DirectMessagesPage);
