const React = require('react');
const { forwardRef } = require('react');
const T = require('prop-types');
const { default: MenuItem } = require('@mui/material/MenuItem');

const AppMenuItemComponent = forwardRef((props, ref) => {

    const {
        className,
        onClick,
        link,
        children,
        ...rest
    } = props;

    return (
        <MenuItem
            component='li'
            className={className}
            onClick={onClick}
            ref={ref}
            to={link}
            {...rest}
        >
            {children}
        </MenuItem>
    );
});

AppMenuItemComponent.displayName = 'AppMenuItemComponent';

AppMenuItemComponent.propTypes = {
    className: T.string,
    link: T.string,
    onClick: T.func,
    children: T.any
};

module.exports = AppMenuItemComponent;
