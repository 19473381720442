const { connect } = require('react-redux');
const { context } = require('app-context');
const Frame = require('../../components/Chat/Frame');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;
const handlers  = context.handlers;

module.exports = connect(
    (state, { match: { params } }) => ({
        classId: params.id,
        messages: selectors.getLocalMessages_forChat_byId(state, params.id) || [],
        hasUnreadMessages: !!selectors.numUnreadLocalMessages(state, params.id),
        numOfChannelMessages: selectors.getNumOfLocalMessages(state, params.id),
        emptyChatInfo: selectors.getInfoMessage_forEmptyGroupChat(state,  params.id),
        group: selectors.getGroup_forAnnouncementChat(state, params.id) || {},
        users: selectors.getClassUsers_forChatByClassId(state, params.id) || [],
        // eslint-disable-next-line hapi/hapi-scope-start
        onSetLastReadMessageIndex: ({ messageIndex }) => redux.dispatch(
            actions.communication.setLastReadMessageIndex({
                channelSid: params.sid,
                messageIndex
            })
        ),
        // eslint-disable-next-line hapi/hapi-scope-start
        onRequestMessages: () => redux.dispatch(
            actions.dataFetching.fetchMessages.nextPage({
                channelSid: selectors.getClassSid_byId(state, params.id)
            }).then(({ items }) => items)
        ),
        // eslint-disable-next-line hapi/hapi-scope-start
        requestFirstPageMessages: () => redux.dispatch(
            actions.dataFetching.fetchMessages.firstPage({
                channelSid: params.sid
            })
        ).then(({ items }) => items),
        ga: {
            onSubmitMessage: ['sent class message', 'class: sent message']
        },
        isOffline: selectors.getIsOffline(state),
        includeEveryoneMention: true,
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        rolesInteractions:selectors.getCurrentUserRoleInteractions(state),
        role: selectors.getCurrentUserRole(state),
        chatText: selectors.getChatTextBySid(state, params.id) || '',
        sid: params.id,
        localConversation: selectors.getLocalConversationByClassId(state,params.id),
        isAnnouncement: true,
        type: 'group',
        useProfanityFilter: selectors.getShouldUseProfanityFilter(state),
        conversationStarters: selectors.getAllConversationStarters(state),
        userIsInClass:  selectors.currentUserIsInClass(state, params.id),
        currentUser: selectors.getCurrentUser(state)?.user(),
        getCurrentTwilioChannel: handlers.communication.getCurrentChannelSid,
        twilioStartListening: handlers.communication.startListening
    }), {
        onSubmitMessage: actions.communication.sendLocalAnnouncementMessage,
        onSaveMessage: actions.communication.updateLocalMessageBody,
        onModerateMessage: actions.communication.moderateLocalMessage,
        onPinMessage: actions.communication.pinLocalMessage,
        onRemoveMessage: actions.communication.removeMessage,
        onRemoveOwnMessage: actions.communication.removeLocalMessage,
        onSetLocalLastReadMessageIndex: actions.communication.setLocalLastReadMessageIndex,
        onFlagInappropriate: actions.communication.flagMessageAsInappropriateLocal,
        openAlertDialog: actions.app.openAlertDialog,
        showNotification: (msg) => actions.alerts.notification.push({ message: msg })
    }
)(Frame);
