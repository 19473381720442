const UniqBy = require('lodash/uniqBy');

const { CHATS: Types, COMMUNICATION: Communication_types } = require('../action-types');
const { MESSAGES_SORT_TYPE } = require('../utils/constants');
const Storage = require('../utils/storage');

const initialState = {
    allMessagesSortingType: MESSAGES_SORT_TYPE.BY_DATE_UPDATED,
    messagesToSendOnServer: [],
    collapseHeaders: {
        dms: Storage.get('collapse-headers-dms') || false,
        groups: Storage.get('collapse-headers-groups') || false
    }
};

const reducer = (state = initialState, action) => {

    const { key } = (action.payload || {});

    switch (action.type) {
        case Types.CLEAR:
            delete state[key];
            return state;

        case Types.SET_ALL_MESSAGES_SORTING_TYPE:
            return {
                ...state,
                allMessagesSortingType: action.payload.sortType
            };

        case Communication_types.STORE_MESSAGE_FOR_SENDING_ON_SERVER: {

            // don't add message if already exist
            if (state.messagesToSendOnServer.find((message) => message.messageSid === action.payload.messageSid)) {
                return state;
            }

            return {
                ...state,
                messagesToSendOnServer: [
                    ...state.messagesToSendOnServer,
                    action.payload
                ]
            };
        }

        case Communication_types.REMOVE_MESSAGE_FOR_SENDING_ON_SERVER: {

            const { messageSid } = action.payload;

            return {
                ...state,
                messagesToSendOnServer: state.messagesToSendOnServer.filter((message) => {

                    return message.messageSid !== messageSid;
                })
            };
        }

        case Communication_types.REMOVE_MESSAGES_FOR_SENDING_ON_SERVER: {

            const { messages } = action.payload;

            return {
                ...state,
                messagesToSendOnServer: [...state.messagesToSendOnServer.filter((messageOne) => {

                    return !messages.find((sentMessage) => messageOne.messageSid === sentMessage.messageSid);
                })]
            };
        }

        case Types.SET_COLLAPSE_HEADERS_DMS:
            Storage.set('collapse-headers-dms', action.payload.setting);
            return {
                ...state,
                collapseHeaders: {
                    ...state.collapseHeaders,
                    dms: action.payload.setting
                }
            };

        case Types.SET_COLLAPSE_HEADERS_GROUPS:
            Storage.set('collapse-headers-groups', action.payload.setting);
            return {
                ...state,
                collapseHeaders: {
                    ...state.collapseHeaders,
                    groups: action.payload.setting
                }
            };

        default:
            return state;
    }
};

const selectors = {
    getChatTextBySid: (state, key) => state[key],
    getAllMessagesSortType: (state) => state.allMessagesSortingType,
    getAllMessagesToSendOnServer: (state) => UniqBy(state.messagesToSendOnServer, 'messageSid'),
    getCollapseHeaders_forDms: (state) => state.collapseHeaders.dms,
    getCollapseHeaders_forGroups: (state) => state.collapseHeaders.groups
};

module.exports = Object.assign(reducer, { selectors });
