const SortBy = require('lodash/sortBy');

const makePassionsText = ({ passions, categories, userName,isMe }) => {


    passions = Object.entries(passions)
        .filter(([, val]) => {

            if (Array.isArray(val)) {
                return val.length > 0;
            }

            return !!val;
        })
        .reduce((obj, [key, val]) => ({ // Turn back into an object
            ...obj,
            [key]: val
        }), {});

    if (Object.keys(passions).length === 0) {
        return '';
    }

    const formattedPassions = sortAndFormatPassions(passions,categories);

    let lastVerbUsed;

    return formattedPassions
        .reduce((passionsTexts, item, i) => {

            const {
                sortedVal,
                config: {
                    verb,
                    format
                },
                type
            } = item;

            const formatFunc = format || ((x) => x);
            const formatted = [].concat([].concat(sortedVal)).map(formatFunc);

            const itemText = listItemText(formatted,type);




            const fullItemText = `${itemText ? `${itemText}` : itemText}`;

            const getVerb = () => {

                const toReturn = ((verb === lastVerbUsed)) ? '' : verb;
                lastVerbUsed = verb;
                return toReturn;
            };

            // At the beginning of the list

            return [
                (`${isMe ? 'Your' : `${userName.trim()}'s`} passions ${getVerb()} ${fullItemText}`) + '.'
            ];




        }, []);
};


const filterOutKeys = (obj, keys) => {

    return Object.keys(obj)
        .filter((key) => !keys.includes(key))
        .reduce((newObject, key) => {

            return {
                ...newObject,
                [key]: obj[key]
            };
        }, {});
};

const filterInKeys = (obj, keys) => {

    return Object.keys(obj)
        .filter((key) => keys.includes(key))
        .reduce((newObject, key) => {

            return {
                ...newObject,
                [key]: obj[key]
            };
        }, {});
};



const sortAndFormatPassions = (passions,categories) => {

    const order = [
        'passions'
    ];

    return order
        .filter((type) => !!passions[type])
        .map((type) => {

            const usedPassions = passions[type];

            const usedCategoriesIds = Array.from(new Set(usedPassions.map((interest) => interest.categoryId)));
            const usedCategories = categories.filter((category) => usedCategoriesIds.includes(category.id));
            const sortedCategories = SortBy(usedCategories,['name']);

            const sortedVal = sortedCategories.map((category) => {


                return usedPassions.filter((interest) => {

                    return interest.categoryId === category.id;
                }).sort(sortInterests);


            }).flat();

            return {
                type,
                sortedVal,
                config: getItemsConfig(type)
            };
        });
};

const sortInterests = (a, b) => {

    const aSpecial = a && a.special;
    const bSpecial = b && b.special;


    return (Number(bSpecial) - Number(aSpecial )) || (a.categoryName.localeCompare(b.categoryName)) ||  a.name.localeCompare(b.name);

};

const getItemsConfig = (type) => {

    switch (type) {
        case 'passions':
            return {
                verb: 'include',
                format: ({ name }) => name
            };
    }
};

const listItemText = (items,type) => {

    if (!items) {
        return '';
    }

    switch (items.length) {
        case 0:
            return '';
        case 1:
            // eslint-disable-next-line
            const firstItem = String(items[0]);
            if (firstItem === 'true') {
                return '';
            }

            return items[0];
        case 2:
            return `${items[0]} and ${items[1]}`;
        default: {

            let text = '';

            items.forEach((item,index) => {

                if (index === items.length - 2){
                    text += `${item}`;
                }
                else if (index < items.length - 1){
                    text += `${item}, `;
                }
                else {

                    text += ` and ${item}`;
                }
            });

            return text;
        }
    }
};







module.exports = {

    filterOutKeys,
    filterInKeys,
    sortAndFormatPassions,
    getItemsConfig,
    makePassionsText

};
