const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: MuiSwitch } = require('@mui/material/Switch');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const { useCallback, useEffect } = React;

const internals = {};

module.exports = function Switch(props) {

    const { StyledSwitch } = internals;

    // Grab the input ref
    const ref = useCallback((el) => {

        if (el) {
            // Remove the role attribute to prevent nested focusable inputs
            el.removeAttribute('role');
            const inputEl = el.querySelector('input');
            inputEl.setAttribute('data-focus-outline', 'radius:20,padding:-3,zIndex:1');
        }
    }, []);

    useEffect(() => {

        if (!internals.initFocus) {
            return;
        }

        // Animate for half a second
        const animInterval = setInterval(() => {

            // Follow the switch thumb as it animates to the left
            AnimatedFocusIndicator.wrapFocusIndicatorForElement();
        }, 10);

        setTimeout(() => clearInterval(animInterval), 500);
    }, [props.checked]);

    useEffect(() => {

        return () => {

            internals.initFocus = false;
        };
    }, []);

    return (
        <StyledSwitch
            ref={ref}
            onFocus={() => {

                internals.initFocus = true;
            }}
            {...props}
        />
    );
};

module.exports.propTypes = {
    checked: T.bool
};

internals.StyledSwitch = Styled(MuiSwitch)`
    .MuiSwitch-input {
        left: 0;
        width: 100%;
    }
`;
