const { useCallback, useRef } = require('react');

// useDebounce hook to debounce a function
exports.useDebounce = function useDebounce(callback, delay) {

    const timeoutRef = useRef(null);

    const debouncedFunction = useCallback(
        (...args) => {

            // Clear previous timeout if function is called again within delay
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            // Set a new timeout
            timeoutRef.current = setTimeout(() => {

                callback(...args);
            }, delay);
        },
        [callback, delay]
    );

    return debouncedFunction;
};
