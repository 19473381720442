const React = require('react');
const T = require('prop-types');
const { ThemeProvider } = require('styled-components');
const { ConnectedRouter } = require('connected-react-router');
const { Routes } = require('../utils/strange-router-v3-shim');
const { Provider } = require('react-redux');
const { HelmetProvider } = require('react-helmet-async');
const { StyledEngineProvider, ThemeProvider: MuiThemeProvider } = require('@mui/material/styles');
const PageTitleByPath = require('utils/pageTitleByPath');

const MuiTheme = require('../styles/theme');
const GA = require('../utils/analytics');

const { useEffect } = React;

//LATO
require('../styles/offlineFonts/Lato/Lato-Light.woff2');
require('../styles/offlineFonts/Lato/Lato-Regular.woff2');
require('../styles/offlineFonts/Lato/Lato-Bold.woff2');
require('../styles/offlineFonts/Lato/Lato-Black.woff2');
require('../styles/offlineFonts/Lato/Lato.scss');

//MULISH
require('../styles/offlineFonts/Mulish/Mulish-Regular.woff2');
require('../styles/offlineFonts/Mulish/Mulish-Bold.woff2');
require('../styles/offlineFonts/Mulish/Mulish-ExtraBold.woff2');
require('../styles/offlineFonts/Mulish/Mulish.scss');

//ROBOTO SLAB
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Light.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Regular.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Bold.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-ExtraBold.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Black.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab.scss');

// Pull global styles
require('../styles/core.scss');

const internals = {};

const App = function App(props) {

    const {
        history,
        routes,
        store,
        theme = MuiTheme
    } = props;

    useEffect(() => {

        window.dispatchEvent(new Event('resize'));
    }, []);

    const onRouteUpdate = (onUpdateProps) => {

        GA.onRouteUpdate(onUpdateProps);

        const { location: { pathname } } = onUpdateProps;

        if (!pathname) {
            return;
        }

        const pageTitle = PageTitleByPath(pathname);

        if (pageTitle && document.title !== `Nearpeer - ${pageTitle}`) {
            document.title = `Nearpeer - ${pageTitle}`;

            // Focus main header to announce page change
            const mainHeader = document.getElementById('main-page-heading');

            if (mainHeader) {
                mainHeader.setAttribute('aria-label', `Navigated to ${pageTitle} page`);

                // Wait 10 ms cuz the main menu reclaims focus
                setTimeout(() => {

                    mainHeader.focus();

                    // Set a timeout to reset the aria-label for when the user refocuses the main header
                    setTimeout(() => {

                        mainHeader.setAttribute('aria-label', `${pageTitle} page`);
                    }, 2000);
                }, 10);
            }
        }
        else if (!pageTitle) {
            document.title = 'Nearpeer';
        }
    };

    return (
        <HelmetProvider>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <MuiThemeProvider theme={theme}>
                            <ConnectedRouter history={history}>
                                <Routes
                                    routes={routes}
                                    onUpdate={onRouteUpdate}
                                />
                            </ConnectedRouter>
                        </MuiThemeProvider>
                    </StyledEngineProvider>
                </ThemeProvider>
            </Provider>
        </HelmetProvider>
    );
};

App.propTypes = {
    history: T.object.isRequired,
    routes: T.array.isRequired,
    store: T.object.isRequired,
    theme: T.object.isRequired
};

module.exports = App;
