const React = require('react');
const T = require('prop-types');
const { default: styled } = require('styled-components');
const Moment = require('moment/moment');

const { default: Classes } = require('./styles.scss');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: RightArrowIcon } = require('@mui/icons-material/ChevronRight');

const UserBadge = require('containers/UserBadge');
const NoUserProfilePic = require('components/NoUserProfilePic');
const { getSizedImageUrl } = require('utils/image');
const SUR = require('utils/should-update-record');

const internals = {
    propTypes: {
        user: T.object.isRequired,
        dm: T.object,
        secondaryText: T.element,
        leftAvatar: T.element,
        showUserActions: T.bool,
        canViewBadge: T.bool,
        link: T.string,
        onClick: T.func,
        hasContainerElement: T.bool
    }
};

module.exports = class UserListItem extends React.Component {

    // NOTE this component implements shouldComponentUpdate()

    // NOTE propTypes defined above in order to work around
    // babel/webpack limitations, referencing static props within a class.

    static propTypes = internals.propTypes

    static defaultProps = {
        showUserActions: false
    };

    constructor() {

        super();

        this.propNames = Object.keys(internals.propTypes);
    }

    // Essentially identical to PureComponent,
    // but for the user prop only pays attention to id.
    shouldComponentUpdate(nextProps) {

        for (let i = 0; i < this.propNames.length; ++i) {
            const name = this.propNames[i];
            if (name === 'user') {
                if (SUR.shouldUpdate(this.props[name], nextProps[name])) {
                    return true;
                }
            }
            else if (this.props[name] !== nextProps[name]) {
                return true;
            }
        }

        return false;
    }

    render() {

        const {
            user,
            secondaryText,
            leftAvatar,
            canViewBadge,
            dm,
            rightArrowAriaLabel,
            link,
            onClick,
            push,
            ...rest
        } = this.props;

        const {
            StyledListItem,
            PrimaryTextRow,
            NameAndBadgeContainer,
            StyledListItemText
        } = internals;

        const _onClick = (evt) => {

            const targetTagName = evt?.target?.tagName?.toLowerCase();

            if (targetTagName === 'img' || targetTagName === 'button' || targetTagName === 'a') {
                evt.preventDefault();
                return;
            }

            // Run onClick if exists
            onClick?.();

            if (link) {
                push(link);
            }
        };

        return (
            <StyledListItem
                onClick={_onClick}
                {...rest}
            >
                <ListItemAvatar>
                    {leftAvatar || (
                        user.croppedPicture
                            ? <Avatar src={getSizedImageUrl(user.croppedPicture, 100)} alt='User profile picture' />
                            : <Avatar alt='Empty user profile picture'>
                                <NoUserProfilePic
                                    iconColor={'#ffffff'}
                                    insideAvatar={true}
                                    outlined={false}
                                />
                            </Avatar>
                    )}
                </ListItemAvatar>
                <StyledListItemText
                    primary={<PrimaryTextRow>
                        <NameAndBadgeContainer>
                            {`${user.firstName} ${user.lastName}`}<span className={`${user.enabled ? '' : Classes.userDisabled}`} />
                            {canViewBadge && <UserBadge mini className={Classes.userBadge} userType={user.type} />}
                        </NameAndBadgeContainer>
                        {(dm?.dateUpdated && dm.lastMessage) && <span className={Classes.primaryTextTimestamp}>
                            {Moment(dm.dateUpdated)
                                .calendar(null, {
                                    lastDay : '[Yesterday]',
                                    sameDay : '[Today]',
                                    nextDay : '[Tomorrow]',
                                    lastWeek : 'dddd',
                                    nextWeek : 'dddd',
                                    sameElse : 'MM/DD/YYYY'
                                })
                            }
                        </span>}
                    </PrimaryTextRow>}
                    secondary={secondaryText}
                />
                <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
                    <IconButton
                        edge='end'
                        size='large'
                        data-focus-outline='radius:25,padding:-7,zIndex:1'
                        aria-label={rightArrowAriaLabel || null}
                        onClick={() => {

                            // Run onClick if exists
                            onClick?.();

                            if (link) {
                                push(link);
                            }
                        }}
                    >
                        <RightArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </StyledListItem>
        );
    }
};

internals.StyledListItem = styled(ListItem)`
    min-height: 70px;

    display: flex;
    flex-flow: row nowrap;

    position: relative;

    // border-bottom
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 72px; // Adjust inset from the left
        width: calc(100% - 72px); // Adjust width to span only part of the element
        height: 1px;
        background-color: ${({ theme }) => theme.palette.list.divider};
    }

    .MuiListItem-container {
        flex: 1;
    }

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.palette.menu.hover};
    }
`;

internals.PrimaryTextRow = styled.span`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

internals.NameAndBadgeContainer = styled.span`
    display: flex;
    align-items: center;
`;

internals.StyledListItemText = styled(ListItemText)`
    margin-top: 0;
`;
