module.exports = (multiLineElement, inputAriaLabel) => {

    const hiddenSiblings = multiLineElement ? multiLineElement.parentNode.getElementsByTagName('textarea') : [];

    for (let i = 0; i < hiddenSiblings.length; ++i) {
        hiddenSiblings[i].setAttribute('aria-label', inputAriaLabel);
        // if (i !== hiddenSiblings.length - 1){
        //     hiddenSiblings[i].setAttribute('aria-hidden', true);
        // }
    }
};
