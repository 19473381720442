const { default: CreateMuiTheme } = require('@mui/material/styles/createTheme');
// The object below overrides specific values and/or extends the default material-ui theme, which can currently be found here:
// https://material-ui.com/customization/default-theme/#default-theme
// Any of the values in that default theme may be used throughout this project, even if they aren't explicitly defined here
// (e.g., `theme.shape.borderRadius` will return 4, even though `shape` isn't currently defined in this file)

const systemFontStack = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';

const defaultTheme = CreateMuiTheme();

const emptyBackground = '#f7f7f7';
// const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

const BREAKPOINTS = {
    xs: 0,
    tiny:420,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    mobileS: 320,
    mobileM: 375,
    mobileL: 425
};

const DEFAULT_BORDER_RADIUS = 10;
const BUTTON_BORDER_RADIUS = 40;

const breakpointsFull = {
    values: { ...BREAKPOINTS }
};

module.exports = CreateMuiTheme({
    typography: {
        fontFamily: ['Lato', systemFontStack].join(','),
        fonts: {
            Mulish:'Mulish,sans-serif',
            Lato:['Lato', systemFontStack].join(',')
        },
        h1: {
            fontSize: 30,
            fontWeight: 'bold'
        },
        h2: {
            fontSize: 28,
            fontWeight: 'bold'
        },
        h3: {
            fontSize: 26,
            fontWeight: 500
        },
        h4: {
            fontSize: 20,
            fontWeight: 500
        },
        body2: {
            fontSize: 12
        }
    },
    palette: {
        primary: {
            main: '#4611a9',
            contrastText: '#fff'
        },
        secondary: {
            main: '#a92a54',
            contrastText: '#fff'
        },
        text: {
            primary: '#000',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.65)',
            contrastText: '#fff'
        },
        error: {
            main: '#A7160C'
        },
        menu: {
            hover: 'rgba(0, 0, 0, 0.13)'
        },
        list: {
            hover: 'rgba(0, 0, 0, 0.08)',
            divider: 'rgba(0, 0, 0, 0.12)'
        }
    },
    shape: {
        borderRadius: DEFAULT_BORDER_RADIUS
    },
    breakpoints: breakpointsFull,
    components: {
        MuiButtonBase: {
            defaultProps: {
                focusRipple: true,
                disableRipple: false
            }
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                indicator:({ theme }) => ({
                    color: theme.palette.text.primary
                })
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    borderRadius: BUTTON_BORDER_RADIUS
                }
            }
        },
        MuiFormControl: {
            defaultProps:{
                variant:'standard'
            }
        },
        MuiFab: {
            defaultProps:{
                disableRipple: true
            }
        },
        MuiToggleButton: {
            defaultProps:{
                disableRipple: true
            }
        },
        MuiTextField: {
            defaultProps:{
                variant: 'standard'
            }
        },
        MuiAutocomplete: {
            defaultProps:{
                variant: 'standard'
            }
        },
        MuiSelect: {
            defaultProps:{
                variant: 'standard'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: 64,
                [defaultTheme.breakpoints.up('sm')]: {
                    minHeight: 64
                },
                // You can trigger this media query by making the height smaller
                '@media (min-width: 0px) and (orientation: landscape)': {
                    minHeight: 64
                }
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: emptyBackground
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...ownerState.color === 'primary' && {
                        color: '#000000'
                    },
                    ...ownerState.color === 'text.secondary' && {
                        color: 'rgba(0, 0, 0, 0.54)'
                    }
                })
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root:{
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.main
                })
            }
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    opacity: 0.7,
                    '&.Mui-selected': {
                        color: '#ffffff',
                        opacity: 1
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: DEFAULT_BORDER_RADIUS
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }
            }
        }
    }
});
