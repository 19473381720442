const React = require('react');
const PropTypes = require('prop-types');
const CenteredLayout = require('components/CenteredLayout');
const { default: Classes } = require('./styles.scss');
const PlainLayout = require('layouts/PlainLayout');
const GetQueryParams = require('utils/get-query-params');
const Loader = require('components/Loader');
const ChangePasswordCard = require('components/ChangePasswordCard');
const NearpeerLogo = require('static/nearpeer_logo_white.svg');

class PasswordReset extends React.PureComponent {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        checkResetToken: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
    }

    constructor() {

        super();

        this.state = {
            tokenCheckFinished: false,
            usePasswordRules: true
        };
    }

    componentDidMount() {

        const resetToken = GetQueryParams().t;

        this.props.checkResetToken({ resetToken })
            .then(({ payload: { result: { data } } }) => {

                this.setState({
                    tokenCheckFinished:true
                });
                if (data.status === 'invalid'){

                    return this.props.replace('/reset-link-expired');
                }

                this.setState({
                    usePasswordRules: data.usePasswordRules
                });
            });
    }

    submit = async (data) => {

        const { newPassword } = data;
        const resetToken = GetQueryParams().t;

        const submitRes = await this.props.onSubmit({ password: newPassword, resetToken });

        this.setState({
            tokenCheckFinished: false
        });

        return submitRes;
    }

    render() {

        const {
            tokenCheckFinished,
            usePasswordRules
        } = this.state;

        return <PlainLayout>
            <CenteredLayout
                mainClass={Classes.main}
            >
                {tokenCheckFinished ? <React.Fragment>
                    <div style={{ margin: '.67em 0 0', fontSize: '2.75em' }}>
                        <img style={{ height:'1em' }} src={NearpeerLogo} alt='' />
                    </div>
                    <ChangePasswordCard
                        usePasswordRules={usePasswordRules}
                        mainTitle='Reset Password'
                        onSubmit={this.submit}
                    />
                </React.Fragment> : <Loader />}
            </CenteredLayout>
        </PlainLayout>;
    }
}

module.exports = PasswordReset;
