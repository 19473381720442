const React = require('react');
const T = require('prop-types');

const {
    useState,
    forwardRef
} = React;

const { default: List } = require('@mui/material/List');
const { default: ListItemIcon } = require('@mui/material/ListItemIcon');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ExpandLess } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMore } = require('@mui/icons-material/ExpandMore');
const { default: Collapse } = require('@mui/material/Collapse');

const AppMenuItemComponent = require('./AppMenuItemComponent');
const { default: Styled } = require('styled-components');

// React runtime PropTypes
const AppMenuItemPropTypes = {
    name: T.string.isRequired,
    link: T.string,
    Icon: T.elementType,
    items: T.array,
    inset: T.bool,
    onClick: T.func,
    closeMainMenu: T.func,
    push: T.func
};

const internals = {};

const AppMenuItem = forwardRef((props, ref) => {

    const {
        name,
        link,
        onClick,
        closeMainMenu,
        Icon,
        inset = false,
        items = [],
        push,
        ...rest
    } = props;

    const {
        AppMenuItemComponentStyled,
        StyledList
    } = internals;

    const isExpandable = items && items.length > 0;
    const [isOpen, setIsOpen] = useState(false);

    let isHighlighted = link === window.location.pathname;

    if (items.length > 0) {
        isHighlighted = !!items.find(({ link: _link }) => _link === window.location.pathname);
    }

    if (!isExpandable) {
        return (
            <AppMenuItemComponentStyled
                component='li'
                link={link}
                onClick={onClick}
                isHighlighted={isHighlighted}
                ref={ref}
                {...rest}
            >
                {/* Display an icon if any */}
                {Icon && (
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                )}
                <ListItemText primary={name} inset={inset} />
                {/* Display the expand menu if the item has children */}
            </AppMenuItemComponentStyled>
        );
    }

    const toggleCollapse = () => {

        setIsOpen((prevOpen) => !prevOpen);
    };

    return (
        <>
            <AppMenuItemComponentStyled
                component='li'
                ref={ref}
                onClick={toggleCollapse}
                isHighlighted={isHighlighted}
            >
                {Icon && (
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                )}
                <ListItemText primary={name} inset={inset} />
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </AppMenuItemComponentStyled>
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
                <StyledList component='div' disablePadding>
                    {items.map((item) => (

                        // Recursively use this component
                        <AppMenuItem
                            closeMainMenu={closeMainMenu}
                            push={push}
                            key={item.name}
                            {...item}
                            onClick={() => {

                                if (item.onClick) {
                                    item.onClick();
                                }

                                if (item.link) {
                                    push(item.link);
                                }

                                if (!item.items) {
                                    closeMainMenu();
                                }
                            }}
                        />
                    ))}
                </StyledList>
            </Collapse>
        </>
    );
});

AppMenuItem.displayName = 'AppMenuItem';
AppMenuItem.propTypes = AppMenuItemPropTypes;

internals.StyledList = Styled(List)`
    // Top shadow
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px; // Adjust the height as desired
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent);
    }

    // Bottom shadow
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px; // Adjust the height as desired
        background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
    }

    width: 200px !important;
     ${(props) => props.theme.breakpoints.between( props.theme.breakpoints.values.mobileL,props.theme.breakpoints.values.sm)} {
        width: 180px !important;
    }
    ${(props) => props.theme.breakpoints.between( props.theme.breakpoints.values.mobileM,props.theme.breakpoints.values.mobileL)} {
        width: 160px !important;
    }
    ${(props) => props.theme.breakpoints.down( props.theme.breakpoints.values.mobileM)} {
        width: 145px !important;
    }
`;

internals.AppMenuItemComponentStyled = Styled(AppMenuItemComponent)`
    height: 48px;

    width: 200px !important;

    ${({ theme }) => theme.breakpoints.between(theme.breakpoints.values.mobileL, theme.breakpoints.values.sm)} {
        width: 180px !important;
    }
    ${({ theme }) => theme.breakpoints.between(theme.breakpoints.values.mobileM, theme.breakpoints.values.mobileL)} {
        width: 160px !important;
    }
    ${({ theme }) => theme.breakpoints.down(theme.breakpoints.values.mobileM)} {
        width: 145px !important;
    }

    &.Mui-disabled {
        opacity: 1;

        & .MuiListItemText-root {
            & .MuiTypography-root {
                color: rgb(70, 17, 169);
            }
        }
    }

    &:hover {
        background: ${({ theme }) => theme.palette.menu.hover};
    }

    ${({ theme, isHighlighted }) => {

        let styles = `
            @media (max-width: ${theme.breakpoints.values.mobileL}px) {
                & .MuiListItemIcon-root,
                & .menuItemIcon {
                    font-size: 0.8rem;
                }

                & .MuiListItemText-root {
                    & .MuiTypography-root {
                        font-size: 0.8rem;
                    }
                }
            }
        `;

        if (isHighlighted) {
            styles = `${styles}

            background: rgb(70, 17, 169) !important;
            color: #fff !important;

            & .MuiListItemIcon-root,
            & .menuItemIcon {
                color: #fff;
            }

            & .MuiListItemText-root {
                & .MuiTypography-root {
                    color: #fff;
                }
            }
            `;
        }

        return styles;
    }}
`;

module.exports = AppMenuItem;
