const React = require('react');
const PropTypes = require('prop-types');
const { default: styled } = require('styled-components');
const { NavLink: Link } = require('react-router-dom');

const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: Button } = require('@mui/material/Button');
const CenteredLayout = require('components/CenteredLayout');

const Fonts = require('styles/fonts.json');
const Colors = require('styles/colors.json');
const { default: Classes } = require('./styles.scss');

const PlainLayout = require('layouts/PlainLayout');
const Footer = require('components/Footer');
const LoginTextField = require('components/LoginTextField');
const NearpeerLogo = require('static/nearpeer_logo_white.svg');
const IsEmail = require('utils/is-email');

const internals = {};

module.exports = class ForgotPassword extends React.PureComponent {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        onInvalidSubmit: PropTypes.func.isRequired
    }

    constructor() {

        super();

        this.state = {
            email: '',
            emailError: null,
            passwordResetSuccess: false
        };

        this.validateEmail = this._validateEmail.bind(this);
        this.submitOrError = this._submitOrError.bind(this);
        this.confirmPopUpRead = this._confirmPopUpRead.bind(this);
    }

    emailChanged = (evt) => {

        const email = evt.target.value;

        this.setState({
            email
        });
    }

    onKeyDown = (ev) => {

        // 13 === 'enter' key
        if (ev.keyCode === 13) {

            this.submitOrError();
        }
    }

    _validateEmail(ev, cb) {

        cb = cb || (() => null);

        const email = ev ? ev.target.value : this.state.email;

        if (!email) {
            return this.setState({ emailError: 'Your email address is required' }, cb);
        }

        if (!IsEmail(email)) {
            return this.setState({ emailError: 'Invalid email address' }, cb);
        }

        return this.setState({ emailError: null }, cb);
    }

    _confirmPopUpRead(ev) {

        this.props.push('/login');
    }

    _submitOrError() {

        this.validateEmail(null, async  () => {

            if (this.state.emailError) {
                return this.props.onInvalidSubmit(this.state);
            }

            // To avoid a user enumeration exploit, we'll pretend it's
            // successful whether a user with the entered email exists or not.
            let res;
            let status = 200;

            try {
                res = await this.props.onSubmit(this.state);
            }
            catch (err) {
                // Ignore
            }

            if (res) {
                status = res.payload.result.status;
            }

            if (status === 200) {

                this.setState({
                    passwordResetSuccess: true
                });
            }
        });
    }

    render() {

        const {
            StyledButton
        } = internals;

        return <PlainLayout>
            <CenteredLayout
                footer={<Footer/>}
                footerClass={Classes.footerClass}
            >
                <div style={{ margin: '.67em 0 0', fontSize: '2.75em' }}>
                    <img style={{ height:'1em' }} src={NearpeerLogo} alt='' />
                </div>
                <h1 className={Classes.header} tabIndex={0}>Forgot&nbsp;Password</h1>
                <div className={Classes.inputs} onKeyDown={this.onKeyDown}>
                    <LoginTextField
                        type='email'
                        label='Email'
                        onBlur={this.validateEmail}
                        onChange={this.emailChanged}
                        value={this.state.email}
                        helperText={this.state.emailError}
                        error={this.state.emailError}
                        fullWidth={true}
                        inputProps={{
                            'aria-invalid': !!this.state.emailError
                        }}
                    />
                </div>
                <StyledButton
                    color={'primary'}
                    fullWidth={true}
                    size={'large'}
                    onClick={this.submitOrError}
                    data-focus-outline='radius:40,padding:4'
                >
                    Submit
                </StyledButton>
                <div style={{ height: 27 }}>
                    <Link
                        className={Classes.link}
                        style={{ textDecoration: 'none' }}
                        to='/login'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Log In
                    </Link>
                </div>
                <Dialog
                    open={this.state.passwordResetSuccess}
                    disableEscapeKeyDown
                    onClose={undefined}
                    aria-labelledby="alert-password-reset-title"
                    aria-describedby="alert--password-reset-description"
                >
                    <DialogTitle id="alert-reset-pw-title">{'Password Reset'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-reset-pw-success">
                            {'If you have an active account, you will receive a password reset email. Please check your email and click the link inside to reset your password. If you haven’t received this email within a few minutes, please check your spam folder.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            {'If you signed in using a Single Sign-On (SSO) provider, please reset your password on the provider\'s website.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.confirmPopUpRead} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </CenteredLayout>
        </PlainLayout>;
    }
};

internals.StyledButton = styled(Button)`
    background-color: ${Colors.lightText};
    margin-bottom: 15px;
    height: 3em;
    width: 100%;
    &:hover {
        background-color: ${Colors.lightText}
    }
    font-family: ${Fonts.headerFont};
    font-weight: bold;
    text-transform: none;
    font-size: 1rem;
    line-height: 3em;
`;
