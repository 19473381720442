const React = require('react');
const T = require('prop-types');
const { default: Styled, keyframes, css } = require('styled-components');
const { Virtuoso } = require('react-virtuoso');
const { default: Typography } = require('@mui/material/Typography');

const Loader = require('components/Loader');

const {
    MAX_CONTENT_WIDTH
} = require('utils/constants');

const EXTRA_HEADER_FOOTER_PADDING = 16;

const { default: Classes } = require('./styles.scss');
const ChatItem = require('./Item');

const {
    useRef,
    useCallback
} = React;

const internals = {};

const ChatList = function ChatList(props) {

    const {
        virtuosoRef,
        listContainerRef,
        messages,
        onSaveMessage,
        onModerateMessage,
        onPinMessage,
        onRemoveMessage,
        onRemoveOwnMessage,
        onFlagInappropriate,
        updateAlertFunction,
        showModerationControls,
        showNotification,
        rolesInteractions,
        rolePermissions,
        isAnnouncement,
        onRequestMessages,
        headerHeight,
        footerHeight,
        hasPrevPage
    } = props;

    const {
        AllChatsDisplayedContainer,
        AllChatsDisplayedLabel,
        ListContainer,
        ChatItemContainer,
        HeaderContainer,
        SpinnerContainer,
        StyledVirtuoso
    } = internals;

    const isInitialRender = useRef(true);
    const currentRange = useRef({ startIndex: 0, endIndex: 0 });

    // Gets called after Virtuoso has done some
    // calculations and added items to the DOM.
    const handleRangeChanged = useCallback(({ startIndex, endIndex }) => {

        currentRange.current = { startIndex, endIndex };

        if (!isInitialRender.current) {
            return;
        }

        const virtuosoScroller = listContainerRef.current?.firstElementChild;

        if (!virtuosoScroller) {
            return;
        }

        setTimeout(() => {

            virtuosoScroller.style.scrollBehavior = 'auto';
            virtuosoScroller.scrollTop = virtuosoScroller.scrollHeight;

            virtuosoRef.current.scrollToIndex({
                index: messages.length - 1,
                align: 'end',
                behavior: 'smooth'
            });

            setTimeout(() => {

                isInitialRender.current = false;
            }, 100);
        }, 0);
    }, []);

    const loadMoreMessages = useCallback(() => {

        if (hasPrevPage) {
            onRequestMessages();
        }
    }, [hasPrevPage]);

    return (
        <>
            <ListContainer ref={listContainerRef}>
                <StyledVirtuoso
                    ref={virtuosoRef}
                    data={messages}
                    followOutput // Stick to bottom when at bottom
                    initialTopMostItemIndex={messages.length - 1} // Start rendering at the last item
                    rangeChanged={isInitialRender.current ? handleRangeChanged : undefined} // Detects when Virtuoso updates the range
                    startReached={loadMoreMessages}
                    // Trigger startReached earlier
                    increaseViewportBy={{
                        top: 100,
                        bottom: 100
                    }}
                    components={{
                        Header: () => {

                            let HeaderContent = null;

                            if (hasPrevPage) {
                                HeaderContent = (
                                    <SpinnerContainer>
                                        <Loader />
                                    </SpinnerContainer>
                                );
                            }
                            else {
                                HeaderContent = (
                                    <AllChatsDisplayedContainer>
                                        <AllChatsDisplayedLabel>
                                            <Typography align={'center'} variant={'caption'}>
                                                All available chats are displayed
                                            </Typography>
                                        </AllChatsDisplayedLabel>
                                    </AllChatsDisplayedContainer>
                                );
                            }

                            return (
                                <HeaderContainer>
                                    {/* Header Spacer */}
                                    <div style={{ height: headerHeight + EXTRA_HEADER_FOOTER_PADDING }} />
                                    <div style={{ padding: '10px', textAlign: 'center' }}>
                                        {HeaderContent}
                                    </div>
                                </HeaderContainer>
                            );
                        },
                        Footer: () => {

                            {/* Footer Spacer */}
                            return <div style={{ height: footerHeight + EXTRA_HEADER_FOOTER_PADDING }} />;
                        }
                    }}
                    itemContent={(index, message) => {
                        // Render item content
                        const initDelayBase = 0.015;
                        const initDelayMultiplier = Math.max(messages.length - 1 - index, 0);

                        return (
                            <ChatItemContainer
                                $delay={
                                    isInitialRender.current
                                        ? Math.max(initDelayBase * initDelayMultiplier, 0)
                                        : 0
                                }
                            >
                                <ChatItem
                                    onSave={onSaveMessage}
                                    onModerate={onModerateMessage}
                                    onPin={onPinMessage}
                                    onRemove={onRemoveMessage}
                                    onRemoveOwn={onRemoveOwnMessage}
                                    onFlagInappropriate={onFlagInappropriate}
                                    updateAlertFunction={updateAlertFunction}
                                    showModerationControls={showModerationControls}
                                    showNotification={showNotification}
                                    rolesInteractions={rolesInteractions}
                                    rolePermissions={rolePermissions}
                                    className={Classes.cf}
                                    message={message}
                                    isAnnouncement={isAnnouncement}
                                />
                            </ChatItemContainer>
                        );
                    }}
                />
            </ListContainer>
        </>
    );
};

ChatList.propTypes = {
    listContainerRef: T.any,
    virtuosoRef: T.any,
    headerHeight: T.number,
    footerHeight: T.number,
    messages: T.array,
    onSaveMessage: T.func.isRequired,
    onModerateMessage: T.func.isRequired,
    onPinMessage: T.func.isRequired,
    onRemoveMessage: T.func.isRequired,
    onRemoveOwnMessage: T.func.isRequired,
    onRequestMessages: T.func.isRequired,
    onFlagInappropriate: T.func.isRequired,
    updateAlertFunction: T.func,
    showNotification: T.func,
    rolesInteractions:T.arrayOf(T.shape({
        id: T.number,
        name: T.string,
        label: T.string,
        schoolId: T.number,
        canViewProfile: T.bool,
        canViewInGroup: T.bool,
        canChat: T.bool,
        canChatWithoutConnection: T.bool,
        canSeeConnections: T.bool,
        canSeeUsersGroups: T.bool,
        canSeeSurveyFields: T.bool,
        canSeeExtendedProfile: T.bool

    })),
    rolePermissions:T.shape({
        id: T.number,
        canPostInAnnouncement: T.bool
    }),
    showModerationControls: T.bool,
    isAnnouncement: T.bool,
    hasPrevPage: T.bool
};

module.exports = ChatList;

internals.StyledVirtuoso = Styled(Virtuoso)`
    width: 100%;
    height: 100%;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
`;

internals.ListContainer = Styled.div`
    width: 100%;
    height: 100%;
`;

internals.ChatItemContainer = Styled.div`
    list-style-type: none;
    padding: 0 16px;

    margin: 0;
    margin: auto !important;

    max-width: ${MAX_CONTENT_WIDTH}px;

    @media (max-width: 600px) {
        max-width: none;
    }

    // TODO: Use css transitions instead
    /* Inline animations with dynamic translateX */
    ${({ $delay }) => {

        // Define keyframes for opacity and slide-in transform
        const fadeInOpacity = keyframes`
            from { opacity: 0; }
            to { opacity: 1; }
        `;

        return css`
            opacity: 0;
            animation:
                ${fadeInOpacity} 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) forwards ${$delay || 0}s
        `;
    }}
`;

// animation: ${fadeOpacity} 0.15s ease-in forwards, ${slideIn} 0.28s ease-in forwards;

internals.AllChatsDisplayedContainer = Styled.div`
    max-width: ${MAX_CONTENT_WIDTH}px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;

    @media (max-width: 600px) {
        max-width: none;
    }
`;

internals.AllChatsDisplayedLabel = Styled.div`
    display: inline-block;
    position: relative;
    top: 10px;
    margin: auto !important;
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-radius: 5px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

internals.HeaderContainer = Styled.div`
    position: relative;
    border-bottom: 1px ${({ theme }) => theme.palette.menu.hover};
`;

internals.SpinnerContainer = Styled.div`
    position: relative;
    transform: scale(0.5);
    top: -10px;
    height: 50px;
`;
