const React = require('react');
const T = require('prop-types');
const { useDispatch } = require('react-redux');
const { push: Push } = require('connected-react-router');
const { default: Classes } = require('./styles.scss');
const CenteredLayout = require('components/CenteredLayout');
const { analyticsTemplates } = require('utils/analytics');
const ChangePasswordCard = require('components/ChangePasswordCard');
const Loader = require('components/Loader');
const ChooseSignupMethod = require('components/ChooseSignupMethod');

const { useEffect } = React;

const internals = {};

module.exports = function ChoosePassword(props) {

    const {
        signupContext: {
            verificationToken,
            sso,
            usingPassword,
            usingSSO
        },
        usePasswordRules,
        onSubmit
    } = props;

    const dispatch = useDispatch();

    const ssoIncludesNearpeer = sso?.includes('nearpeer');
    const usingPwOrSSO = usingPassword || usingSSO;

    // eslint-disable-next-line hapi/hapi-scope-start, hapi/no-arrowception
    const submit = ({ newPassword, agreeToTerms }) => {

        analyticsTemplates.buttons('save password', 'create account: save password');

        onSubmit({
            password: newPassword,
            agreeToTerms,
            verificationToken
        });
    };

    useEffect(() => {

        if (usingSSO) {
            dispatch(Push('/signup-sso'));
        }
    }, [usingSSO]);

    if (ssoIncludesNearpeer && !usingPwOrSSO) {
        return (
            <CenteredLayout>
                <div style={{ height: '20vh' }}>
                    <Loader />
                    <ChooseSignupMethod />
                </div>
            </CenteredLayout>
        );
    }

    return <CenteredLayout
        header='Create Account'
        mainClass={Classes.main}>

        <ChangePasswordCard
            usePasswordRules={usePasswordRules}
            mainTitle='Email Verified'
            subtitle='Please set your new password'
            showAgreeToTerms={true}
            onSubmit={submit}
            isSignup
        />
    </CenteredLayout>;
};

module.exports.propTypes = {
    onSubmit: T.func.isRequired,
    signupContext: T.shape({
        verificationToken: T.string,
        sso: T.string,
        usingPassword: T.bool,
        usingSSO: T.bool
    }),
    usePasswordRules: T.bool
};
