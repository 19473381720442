const React = require('react');
const T = require('prop-types');
const Debounce = require('lodash/debounce');
const { default: styled } = require('styled-components');

const { default: Paper } = require('@mui/material/Paper');
const { default: MuiBadge } = require('@mui/material/Badge');
const { default: Divider } = require('@mui/material/Divider');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: TextField } = require('@mui/material/TextField');
const { default: Grid } = require('@mui/material/Grid');
const { default: FlatButton } = require('@mui/material/Button');

const Loader = require('components/Loader');
const UserListItem = require('components/UserListItem');

const { default: Classes } = require('./styles.scss');
const GroupHeader = require('../GroupHeader');
const SearchResultGrid = require('components/SearchResultGrid');
const BottomButtonContainer = require('components/BottomButtonContainer');
const { analyticsTemplates } = require('utils/analytics');
const AlertDialog = require('containers/AlertDialog');
const AppContextFilter = require('containers/AppContextFilter');
const FuzzyFilter = require('utils/fuzzyFilter');

const internals = {
    viewOptions: []
};

const VIEW_MATCHES = 0;
const VIEW_CONNECTIONS = 1;
const VIEW_GROUP = 2;
internals.viewOptions[VIEW_MATCHES] = 'Matches';
internals.viewOptions[VIEW_CONNECTIONS] = 'Connections';
internals.viewOptions[VIEW_GROUP] = 'Group List';

class ClassDetailPage extends React.Component {

    static propTypes = {
        history: T.object,
        onClickJoin: T.func,
        onClickLeave: T.func,
        openAlertDialog: T.func,
        onClickLoadMore: T.func,
        setClassSearchText: T.func,
        classSearchText: T.string,
        showJoin: T.bool,
        showLoadMore: T.bool,
        appContextFilter: T.string,
        activeFilterRoleGroup: T.object,
        classDetails: T.shape({
            class: T.object.isRequired,
            users: T.arrayOf(T.shape({
                id: T.any.isRequired,
                firstName: T.string.isRequired,
                lastName: T.string.isRequired,
                croppedPicture: T.string.isRequired
            }))
        }),
        rolePermissions : T.shape({
            id:T.number,
            roleId:T.number,
            schoolId:T.number,
            homeText:T.string,
            canCreateAnnouncement: T.bool,
            canEditGroups:T.bool,
            canViewBadge: T.bool,
            canSeeChatMessagesNoJoin: T.bool

        }),
        role : T.shape({
            id:T.number,
            name:T.string,
            label:T.string
        }),
        classSearchCriteria: T.object,
        onSubmitSearch: T.func,
        restoreScroll: T.func,
        rememberScroll: T.func
    }

    constructor(props) {

        super(props);

        this.state = {
            wide: null,
            submitting: false,
            displayAsList: true,
            filtersOpen: false,
            isViewDropdownOpen: false,
            listViewSelection: VIEW_MATCHES,
            anchorEl: null,
            classmateSearchVal: props.classSearchText ?  props.classSearchText : '',
            filteredClassmates: [],
            alertMessage: '',
            alertTitle: ''
        };

        // Add a mobile breakpoint ("media query listener") for use with inline styles
        this.mql = window.matchMedia('screen and (min-width: 600px)');

        this.handleMediaQuery = this._handleMediaQuery.bind(this);
        this.onClickLeave = this._onClickLeave.bind(this);
        this.onClickJoin = this._onClickJoin.bind(this);
        this.onClickEdit = this._onClickEdit.bind(this);
        this.openViewDropdown = this._openViewDropdown.bind(this);
        this.closeViewDropdown = this._closeViewDropdown.bind(this);
        this.onViewDropdownChange = this._onViewDropdownChange.bind(this);
        this.setClassmateSearchVal = this._setClassmateSearchVal.bind(this);
        this.searchClassmates = this._searchClassmates.bind(this);
        this.setFilteredClassmates = this._setFilteredClassmates.bind(this);
        this.onContextFilterChange = this._onContextFilterChange.bind(this);
        this.sendGaForSearch = Debounce(this._sendGaForSearch, 2500);
        this.searchByName = Debounce(this._debounceSearchByName, 600);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.classDetails && prevProps.classDetails.users && this.props.classDetails && this.props.classDetails.users && prevProps.classDetails.users.length !== this.props.classDetails.users.length) {
            this.setFilteredClassmates();
        }
    }

    dynamicConfirmFunction = () => {};
    dynamicCancelFunction = () => {};

    setDynamicConfirmFunction(newFunc) {

        this.dynamicConfirmFunction = newFunc;
    }

    setDynamicCancelFunction(newFunc) {

        this.dynamicCancelFunction = newFunc;
    }

    openAlertDialogWithProps = ({ title, message, confirm, cancel, declineLabel, confirmLabel }) => {

        this.setState({
            alertTitle: title,
            alertMessage: message,
            alertDeclineLabel: declineLabel,
            alertConfirmLabel: confirmLabel
        });

        this.setDynamicConfirmFunction(confirm);

        if (cancel) {
            this.setDynamicCancelFunction(cancel);
        }

        this.props.openAlertDialog();
    }

    _sendGaForSearch() {

        const { classmateSearchVal } = this.state;

        analyticsTemplates.buttons('submit peers search', `class details: search submit ${classmateSearchVal ? classmateSearchVal : 'empty'}`);
    }

    _searchClassmates(val) {

        this.sendGaForSearch();

        if (!val) {
            return [];
        }

        const { classDetails: { users: classmates } } = this.props;

        const normalize = (str) => str.toLowerCase().replace(/\s/g, '');

        const searchOnField = (obj, field, search) => {

            if (!obj[field] || !search) {
                return false;
            }

            return normalize(obj[field]).includes(normalize(search));
        };

        return classmates.filter((classmate) => {

            const simpleSearchFields = [
                'department',
                'firstName',
                'lastName',
                'major',
                'type',
                'studentName'
            ];

            const hasSimpleMatch = simpleSearchFields.find((field) => searchOnField(classmate, field, val));
            if (hasSimpleMatch) {
                return true;
            }

            const hasInterestMatch = classmate.similarities.interests
                .map(({ interest }) => interest)
                .find((interest) => {

                    return normalize(interest).includes(normalize(val));
                });

            if (hasInterestMatch) {
                return true;
            }

            const hasSimilarityMatch = Object.entries(classmate.similarities)
                .find(([key, similarityVal]) => {

                    if (similarityVal === true && normalize(key).includes(normalize(val))) {
                        return true;
                    }
                });

            if (hasSimilarityMatch) {
                return true;
            }

            return false;
        });
    }

    _debounceSearchByName(name) {

        const { classDetails: {  class: group } } = this.props;
        if (name) {
            // After this we'll end up with an array of arrays, each
            // searching through all classmates for each word in 'val'
            this.props.onSubmitSearch({
                classId: group.id,
                name
            });
        }
        else {
            this.props.onSubmitSearch({
                classId: group.id,
                allByMatches: true
            });
        }
    }

    _setClassmateSearchVal(evt) {

        const { classDetails: { class: group },setClassSearchText } = this.props;
        const value = evt.target.value;

        this.searchByName(value);

        setClassSearchText({
            id:group.id,
            text:value
        });
        this.setState({
            classmateSearchVal: value
        });
    }

    UNSAFE_componentWillMount() {

        this.handleMediaQuery(this.mql);
        this.mql.addListener(this.handleMediaQuery);
    }

    componentDidMount() {

        this.props.restoreScroll();
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
        this.props.rememberScroll();
    }

    _handleMediaQuery(mql) {

        this.setState({ wide: !!mql.matches });
    }

    _onClickJoin() {

        this.props.onClickJoin(this.props.classDetails.class);
    }

    _onClickLeave() {

        this.setState({
            alertMessage: 'Are you sure you\'d like to leave this group?',
            alertTitle: `Leave Group`
        });
        this.setDynamicConfirmFunction(() => {

            this.props.onClickLeave(this.props.classDetails.class);
        });
        this.setDynamicCancelFunction(() => {

        });
        this.props.openAlertDialog();
    }

    _onClickEdit() {

        const { history, classDetails } = this.props;

        history.push(`/app/classes/${classDetails.class.id}/edit`);
    }

    _openViewDropdown(ev) {

        ev.preventDefault();

        this.setState({
            isViewDropdownOpen: true,
            anchorEl: ev.currentTarget
        });
    }

    _onViewDropdownChange(ev, val) {

        analyticsTemplates.buttons('change peers view', `class: view peers ${internals.viewOptions[val]}`);

        this.setState({
            listViewSelection: val,
            isViewDropdownOpen: false,
            anchorEl: null
        });
    }

    _closeViewDropdown() {

        this.setState({
            isViewDropdownOpen: false,
            anchorEl: null
        });
    }

    _onContextFilterChange(contextFilter) {

        //TODO check will here be widget for sort type
        const { classDetails: { class: currentClass } } = this.props;
        const { classmateSearchVal } = this.state;

        if (classmateSearchVal) {
            this.props.onSubmitSearch({
                classId: currentClass.id,
                name:classmateSearchVal
            });
        }
        else {
            this.props.onSubmitSearch({
                classId: currentClass.id,
                allByMatches: true
            });
        }
    }

    _setFilteredClassmates() {

        const { classDetails: { users: allClassmates },appContextFilter,activeFilterRoleGroup } = this.props;
        const { classmateSearchVal } = this.state;

        const contextFiltered = allClassmates.filter((user) => {

            return appContextFilter === 'all' || activeFilterRoleGroup.id === user.role.roleGroupId;
        });

        if (!classmateSearchVal) {
            this.setState({
                filteredClassmates:contextFiltered
            });
        }

        this.setState({
            filteredClassmates: contextFiltered.filter((user) => FuzzyFilter(classmateSearchVal, `${user.firstName} ${user.lastName}`))
        });
    }

    getEmptyState(classmatesLength, connectionsFilterLength) {

        const { showJoin, classSearchCriteria } = this.props;
        const { listViewSelection, classmateSearchVal } = this.state;

        if (!classSearchCriteria) {
            return <div className={Classes.loaderContainer}><Loader /></div>;
        }

        let userMsg = '';

        if (!classmatesLength && classmateSearchVal) {
            userMsg = 'Search returned no results';
        }
        else if (!classmatesLength && classSearchCriteria.allByMatches) {
            userMsg = `There aren't any ${showJoin ? '' : ' other'} members of this group!`;
        }
        else if (!connectionsFilterLength && listViewSelection === VIEW_CONNECTIONS) {
            if (classSearchCriteria.allByMatches) {
                userMsg = 'You don\'t have any connections in this group yet!';
            }
            else {
                userMsg = 'You don\'t have any connections in this group that match the search criteria';
            }
        }

        return (
            <ListItem
                className={Classes.center}
            >
                <ListItemText
                    secondary={<span className={Classes.center}>
                        {userMsg}
                    </span>}
                />
            </ListItem>
        );
    }

    render() {

        if (!this.props.classDetails || !this.props.classDetails.users) {
            return <Loader />;
        }

        const { styles } = internals;

        const {
            showLoadMore,
            classDetails: {
                users: allClassmates,
                class: group
            },
            activeFilterRoleGroup
        } = this.props;

        const {
            classmateSearchVal,
            filteredClassmates
        } = this.state;

        const classmates = classmateSearchVal || activeFilterRoleGroup ? filteredClassmates : allClassmates;

        const { listViewSelection } = this.state;

        // const pinnedInfoExists = !!((group.pinnedInfo || '').trim());

        // const pinnedInfoWithLinks = pinnedInfoExists && RenderTextUtils.renderLinks(group.pinnedInfo.split());

        const connectionsFilter = classmates.filter((result) => result.peerStatus === 'accepted');

        const showEmptyState = (!classmates.length || (listViewSelection === VIEW_CONNECTIONS && !connectionsFilter.length));

        return <div className={Classes.wrapper}>
            <AlertDialog
                title={this.state.alertTitle}
                message={this.state.alertMessage}
                declineLabel={this.state.alertDeclineLabel}
                confirmLabel={this.state.alertConfirmLabel}
                confirmationCallback={() => {

                    this.dynamicConfirmFunction();
                }}
                cancelCallback={() => {

                    this.dynamicCancelFunction();
                }}
            />
            <BottomButtonContainer
                btnLabel='Connect'
                disabled
                // TODO unwrap this from BottomButtonContainer
                hide
            >
                <GroupHeader
                    group={group}
                    variant='details'
                    openAlertDialogWithProps={this.openAlertDialogWithProps}
                />

                <Paper className={`${Classes.toggleViewWrapper} contentWrapper`}>
                    <div>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                <SearchIcon />
                            </Grid>
                            <Grid item>
                                <StyledTextField
                                    id="class-member-search"
                                    label="Search"
                                    primary
                                    fullWidth
                                    value={classmateSearchVal}
                                    onChange={this.setClassmateSearchVal}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>

                <div style={{
                    margin: '0 0 10px',
                    textAlign: 'center'
                }}>
                    <AppContextFilter onChange={this.onContextFilterChange} />
                </div>
                {showEmptyState && this.getEmptyState(classmates.length, connectionsFilter.length)}

                {!showEmptyState && listViewSelection === VIEW_MATCHES && <SearchResultGrid results={classmates.filter((result) => !result.isMe)} />}
                {!showEmptyState && listViewSelection === VIEW_MATCHES && !!showLoadMore &&
                    <FlatButton
                        // Yeah these are inline styles, we'll copy paste them for the upgrade branch
                        style={{ ...styles.button, margin: '0 auto 32px' }}
                        color={'primary'}
                        onClick={() => {

                            this.props.onClickLoadMore(group.id);
                        }}
                    >
                        Load More Results
                    </FlatButton>
                }
            </BottomButtonContainer>
        </div>;
    }
}

module.exports = ClassDetailPage;

// eslint-disable-next-line
internals.groupListSortCompare = (badges) => (peer1, peer2) => {

    const hasBadge = (type) => {

        return badges && badges.find(({ name }) => name === type);
    };

    // push users with badges to the front of the list
    if (hasBadge(peer1.type) !== hasBadge(peer2.type)) {
        return hasBadge(peer1.type) ? -1 : 1;
    }

    // sort alphabetically
    return (`${peer1.firstName.toLowerCase()} ${peer1.lastName.toLowerCase()}` < `${peer2.firstName.toLowerCase()} ${peer2.lastName.toLowerCase()}`) ? -1 : 1;
};

internals.isSelectable = (user) => {

    if (!user.isMe && user.peerStatus === null) {
        return true;
    }

    return false;
};

internals.styles = {
    joinButton: {
        height: 36,
        width: 'auto',
        margin: 0
    },
    joinFab: {
        height: '40px',
        width: '40px',
        minWidth: '40px',
        margin: 0,
        borderRadius:'50%'
    },
    announcementFab: (isAnnouncement) => ({
        backgroundColor: isAnnouncement ? 'rgb(255, 64, 129)' : '#1D0050',
        color:isAnnouncement ? '#000000' : '#ffffff'
    }),
    shrinkHeader: {
        maxHeight: 0
    },
    buttonLabel: {
        fontSize: '12px'
    },
    button:{
        width: 'calc(100% - 64px)',
        display: 'flex',
        border: '1px solid',
        lineHeight: '34px'
    }
};

internals.Badge = styled(MuiBadge)`
    margin: 0 9px;
    .MuiBadge-badge {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
`;

internals.InlineTextField = function InlineTextField(props) {

    return <TextField
        {...props}

        InputProps={{
            classes:{
                root:Classes.muiInputRoot
            }
        }}
    />;
};

const StyledTextField = styled(internals.InlineTextField)`

  .MuiInputLabel-formControl {

    &:not(.MuiInputLabel-shrink) {
      transform: translate(0, 16px) scale(1);
    }
  }
`;
