// ** React Imports
const React = require('react');
const { useState, useCallback, useEffect } = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

// ** MUI Imports
const { default: Box } = require('@mui/material/Box');
const { default: Grid } = require('@mui/material/Grid');
const { default: Card } = require('@mui/material/Card');
const { default: Button } = require('@mui/material/Button');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Typography } = require('@mui/material/Typography');
const { default: CardHeader } = require('@mui/material/CardHeader');
const { default: CardContent } = require('@mui/material/CardContent');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputAdornment } = require('@mui/material/InputAdornment');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemIcon } = require('@mui/material/ListItemIcon');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: VisibilityIcon } = require('@mui/icons-material/Visibility');
const { default: VisibilityOffIcon } = require('@mui/icons-material/VisibilityOff');
const { default: ClearIcon } = require('@mui/icons-material/HighlightOff');
const { default: CheckCircleIcon } = require('@mui/icons-material/CheckCircle');

const { default: Classes } = require('./styles.scss');

// ** Third Party Imports
const Yup = require('yup');
const { useForm, Controller } = require('react-hook-form');
const { yupResolver } = require('@hookform/resolvers/yup');
const { CircularProgress } = require('@mui/material');

const { useTheme } = require('@mui/material/styles');

const { default: styled } = require('styled-components');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');
const { default: TextField } = require('@mui/material/TextField');
const { default: Checkbox } = require('@mui/material/Checkbox');
const FullScreenDialog = require('containers/FullScreenDialog');
const PrivacyPolicyContents = require('components/PrivacyPolicyContents');
const TosContents = require('components/TosContents');
const { useDispatch } = require('react-redux');
const { context } = require('app-context');
const { analyticsTemplates } = require('utils/analytics');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const LoginTextField = require('components/LoginTextField');

const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
    currentPassword: '',
    agreeToTerms: false,
    logoutOtherDevices: false
};

const internals = {};

const ChangePasswordCard = (props) => {

    const {
        mode = 'dark',
        onSubmit,
        mainTitle,
        subtitle,
        showAgreeToTerms = false,
        usePasswordRules = false,
        useCurrentPassword = false,
        useLogoutOtherDevices = false,
        passwordReset,
        isSignup = false
    } = props;

    const {
        OverrideStyles,
        StyledButton,
        StyledCheckbox
    } = internals;

    // ** States
    const [state, setState] = useState({
        showNewPassword: false,
        showConfirmNewPassword: false,
        showCurrentPassword: false,
        privacyPolicyOpen: false,
        tosOpen: false,
        showForgotPasswordModal: false
    });

    // ** Hooks
    const theme = useTheme();
    const dispatch = useDispatch();

    const schema = Yup.object().shape({
        ...(usePasswordRules ? {
            newPassword: Yup
                .string()
                .min(8,'Minimal length of password is 8 characters')
                .required('New password is required')
        } : {
            newPassword: Yup
                .string()
                .required('New password is required')
        }),
        confirmNewPassword: Yup
            .string()
            .required('Password confirm is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
        ...(showAgreeToTerms && {
            agreeToTerms: Yup.bool().required()
        }),
        ...(useCurrentPassword && {
            currentPassword: Yup.string().required('Current password is required')
        }),
        ...(useLogoutOtherDevices && {
            logoutOtherDevices: Yup.bool().required()
        })
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
        watch,
        setError
    } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const handleClickShowNewPassword = () => {

        setState({ ...state, showNewPassword: !state.showNewPassword });
    };

    const handleMouseDownNewPassword = (event) => {

        event.preventDefault();
    };

    const handleClickShowCurrentPassword = () => {

        setState({ ...state, showCurrentPassword: !state.showCurrentPassword });
    };

    const handleMouseDownCurrentPassword = (event) => {

        event.preventDefault();
    };

    const handleClickShowConfirmNewPassword = () => {

        setState({ ...state, showConfirmNewPassword: !state.showConfirmNewPassword });
    };

    const handleMouseDownConfirmNewPassword = (event) => {

        event.preventDefault();
    };

    const onPasswordFormSubmit = async (data) => {

        try {
            await onSubmit(data);
            reset(defaultValues);
            await dispatch(context.actions.alerts.notification.push({
                message: isSignup ?
                    'Password Updated Successfully'
                    : 'Password Changed Successfully'
            }));
        }
        catch (e) {

            //FIXME there is a bug with this catching a bug it works locally but on the staging it doesn't catch an error
            console.log({
                e
            });

            if (e?.response?.data?.reason === 'sameAsOldPassword') {

                setError('newPassword', { type: 'custom', message: 'New password must be different than the current password' },{ shouldFocus: true });
            }
            else if (e?.response?.data?.reason === 'wrongCurrent') {

                setError('currentPassword', { type: 'custom', message: 'Incorrect current password' },{ shouldFocus: true });
            }
            else if (e?.response?.data?.reason === 'oneOfOldPasswords') {

                setError('newPassword', { type: 'custom', message: 'New password can\'t be one of your old passwords' },{ shouldFocus: true });
            }
        }
    };

    const openPrivacyPolicy = (ev) => {

        ev.preventDefault();
        setState({ ...state, privacyPolicyOpen: true });
    };

    const closePrivacyPolicy = () => {

        setState({ ...state, privacyPolicyOpen: false });
    };

    const openTos = (ev) => {

        ev.preventDefault();
        setState({ ...state, tosOpen: true });
    };

    const closeTos = () => {

        setState({ ...state, tosOpen: false });
    };

    const escListener = (evt) => {

        if (evt.key === 'Escape') {
            setState({ ...state, showForgotPasswordModal: false });
        }
    };

    const forgotPwContentsRef = useCallback((node) => {

        if (node && !internals.initFocus) {
            internals.initPwContentsFocus = true;
            node.focus();
        }
    }, []);

    useEffect(() => {

        if (!state.showForgotPasswordModal) {
            internals.initPwContentsFocus = false;
        }
    }, [state.showForgotPasswordModal]);

    const currentPassword = watch('currentPassword');
    const newPassword = watch('newPassword');
    const confirmPassword = watch('confirmNewPassword');
    const agreeToTermsValue = watch('agreeToTerms');

    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsOrSpecialRegExp = /(?=.*?[0-9\-!"#$%&()*,./:;?@[\]^_`{|}~+<=>]+)/;
    const minLengthRegExp   = /.{8,}/;

    const upperCaseMatched = usePasswordRules ? uppercaseRegExp.test(newPassword) : true;
    const lowerCaseMatched = usePasswordRules ? lowercaseRegExp.test(newPassword) : true;
    const digitsOrSpecialMatched = usePasswordRules ? digitsOrSpecialRegExp.test(newPassword) : true;
    const minLengthMatched = usePasswordRules ? minLengthRegExp.test(newPassword) : true;

    const passwordsMatch = newPassword === confirmPassword;

    return (
        <Card
            sx={{
                backgroundColor: mode === 'dark' ? 'primary.main' : 'background.paper',
                border: 'none'
            }}
            variant={'outlined'}
        >
            <CardHeader
                title={mainTitle}
                titleTypographyProps={{
                    variant: 'h2',
                    style: { paddingTop: isSignup ? 0 : 16 }
                }}
                sx={{
                    color: mode === 'dark' ? 'primary.contrastText' : 'text.primary',
                    textAlign: isSignup ? 'center' : 'left',
                    padding: {
                        xs: 0.5,
                        md: 1.5
                    },
                    '&:last-child':{
                        paddingBottom: {
                            xs: 0,
                            md: 2.5
                        }
                    }
                }}
                subheader={<Typography>{subtitle}</Typography>}
            />
            <CardContent mt={0} pt={0} sx={{
                padding:{
                    xs: 0.5,
                    md: 1.5
                },
                '&:last-child':{
                    paddingBottom:{
                        xs: 0,
                        md: 2.5
                    }
                }
            }}>
                <form onSubmit={handleSubmit(onPasswordFormSubmit)}>
                    <OverrideStyles>
                        <Grid container spacing={1} sx={{ mt: 0 }}>
                            {useCurrentPassword ?  <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='currentPassword'
                                        control={control}
                                        rules={{ required: true }}
                                        // eslint-disable-next-line hapi/hapi-scope-start
                                        render={({ value, onChange }) => (
                                            <LoginTextField
                                                value={value}
                                                hasLightBackground={mode !== 'dark'}
                                                label='Current Password'
                                                onChange={onChange}
                                                id='input-current-password'
                                                error={Boolean(errors.currentPassword)}
                                                type={state.showCurrentPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:<InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label={'show current password'}
                                                            onClick={handleClickShowCurrentPassword}
                                                            onMouseDown={handleMouseDownCurrentPassword}
                                                            data-focus-outline='radius:40'
                                                        >
                                                            {state.showCurrentPassword && <VisibilityIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                            {!state.showCurrentPassword && <VisibilityOffIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.currentPassword && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{`${errors.currentPassword.message}`}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid> : null}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='newPassword'
                                        control={control}
                                        rules={{ required: true }}
                                        // eslint-disable-next-line hapi/hapi-scope-start
                                        render={({ value, onChange }) => (
                                            <LoginTextField
                                                required
                                                value={value}
                                                hasLightBackground={mode !== 'dark'}
                                                label='New Password'
                                                onChange={onChange}
                                                id='input-new-password'
                                                error={Boolean(errors.newPassword)}
                                                type={state.showNewPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:<InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label={'show new password'}
                                                            onClick={handleClickShowNewPassword}
                                                            onMouseDown={handleMouseDownNewPassword}
                                                            data-focus-outline='radius:40'
                                                        >
                                                            {state.showNewPassword && <VisibilityIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                            {!state.showNewPassword && <VisibilityOffIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.newPassword && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{`${errors.newPassword.message}`}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl fullWidth>
                                    <Controller
                                        name='confirmNewPassword'
                                        control={control}
                                        rules={{ required: true }}
                                        // eslint-disable-next-line hapi/hapi-scope-start
                                        render={({ value, onChange }) => (
                                            <LoginTextField
                                                required
                                                value={value}
                                                hasLightBackground={mode !== 'dark'}
                                                label='Confirm New Password'
                                                onChange={onChange}
                                                id='input-confirm-new-password'
                                                error={Boolean(errors.confirmNewPassword)}
                                                type={state.showConfirmNewPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:<InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label={'show confirm new password'}
                                                            onClick={handleClickShowConfirmNewPassword}
                                                            onMouseDown={handleMouseDownConfirmNewPassword}
                                                            data-focus-outline='radius:40'
                                                        >
                                                            {state.showConfirmNewPassword && <VisibilityIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                            {!state.showConfirmNewPassword && <VisibilityOffIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.secondary} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.confirmNewPassword && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{`${errors.confirmNewPassword.message}`}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {usePasswordRules ? <Grid item xs={12} >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: mode === 'dark' ? 'primary.contrastText' : 'text.primary',
                                        textAlign: 'left',
                                        fontSize:'.875rem'
                                    }}
                                >
                                    Password Requirements:
                                </Typography>
                                <Box
                                    component='ul'
                                    sx={{ pl: 0, mb: 0, '& li': { mb: 0, color: 'primary.contrastText', '&::marker': { fontSize: '1rem' } } }}
                                >
                                    <ListItem>
                                        <ListItemIcon>
                                            {!upperCaseMatched && <ClearIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.primary} />}
                                            {upperCaseMatched && <CheckCircleIcon htmlColor={mode === 'dark' ? 'rgb(76, 255, 80)' : theme.palette.success.main} />}
                                        </ListItemIcon>
                                        <ListItemText
                                            tabIndex={0}
                                            data-focus-outline='padding:3,radius:5'
                                            aria-label={`At least 1 uppercase letter, ${upperCaseMatched ? 'satisfied' : 'unsatisfied'}`}
                                            primary={'At least 1 uppercase letter'}
                                            primaryTypographyProps={{
                                                sx:{
                                                    color: upperCaseMatched ? (mode === 'dark' ? 'rgb(76, 255, 80)' : 'success.main') : mode === 'dark' ? 'text.contrastText' : 'text.primary',
                                                    fontSize:{
                                                        xs: '0.875rem',
                                                        sm: '1rem'
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            {!lowerCaseMatched && <ClearIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.primary} />}
                                            {lowerCaseMatched && <CheckCircleIcon htmlColor={mode === 'dark' ? 'rgb(76, 255, 80)' : theme.palette.success.main} />}
                                        </ListItemIcon>
                                        <ListItemText
                                            tabIndex={0}
                                            data-focus-outline='padding:3,radius:5'
                                            aria-label={`At least 1 lowercase letter, ${lowerCaseMatched ? 'satisfied' : 'unsatisfied'}`}
                                            primary={'At least 1 lowercase letter'}
                                            primaryTypographyProps={{
                                                sx:{
                                                    color: lowerCaseMatched ? (mode === 'dark' ? 'rgb(76, 255, 80)' : 'success.main') : mode === 'dark' ? 'text.contrastText' : 'text.primary',
                                                    fontSize:{
                                                        xs: '0.875rem',
                                                        sm: '1rem'
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            {!digitsOrSpecialMatched && <ClearIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.primary} />}
                                            {digitsOrSpecialMatched && <CheckCircleIcon htmlColor={mode === 'dark' ? 'rgb(76, 255, 80)' : theme.palette.success.main} />}
                                        </ListItemIcon>
                                        <ListItemText
                                            tabIndex={0}
                                            data-focus-outline='padding:3,radius:5'
                                            aria-label={`At least 1 numeral or special character, ${digitsOrSpecialMatched ? 'satisfied' : 'unsatisfied'}`}
                                            primary={'At least 1 numeral or special character'}
                                            primaryTypographyProps={{
                                                sx: {
                                                    color: digitsOrSpecialMatched ? (mode === 'dark' ? 'rgb(76, 255, 80)' : 'success.main') : mode === 'dark' ? 'text.contrastText' : 'text.primary',
                                                    fontSize: {
                                                        xs: '0.875rem',
                                                        sm: '1rem'
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            {!minLengthMatched && <ClearIcon htmlColor={mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.text.primary} />}
                                            {minLengthMatched && <CheckCircleIcon htmlColor={mode === 'dark' ? 'rgb(76, 255, 80)' : theme.palette.success.main} />}
                                        </ListItemIcon>
                                        <ListItemText
                                            tabIndex={0}
                                            data-focus-outline='padding:3,radius:5'
                                            aria-label={`At least 8 characters total, ${minLengthMatched ? 'satisfied' : 'unsatisfied'}`}
                                            primary={'At least 8 characters total'}
                                            primaryTypographyProps={{
                                                sx: {
                                                    color: minLengthMatched ? (mode === 'dark' ? 'rgb(76, 255, 80)' : 'success.main') : mode === 'dark' ? 'text.contrastText' : 'text.primary',
                                                    fontSize: {
                                                        xs: '0.875rem',
                                                        sm: '1rem'
                                                    }
                                                }
                                            }}

                                        />
                                    </ListItem>
                                </Box>
                            </Grid> : null}
                            {showAgreeToTerms ? <>
                                <Grid item xs={12} style={{ padding: '15px' }}>
                                    <FormControl>
                                        <Controller
                                            name='agreeToTerms'
                                            control={control}
                                            rules={{ required: true }}
                                            // eslint-disable-next-line hapi/hapi-scope-start
                                            render={( field ) => (
                                                <FormControlLabel
                                                    required
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                textAlign:'center',
                                                                color: mode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
                                                            }}
                                                        >
                                                            I agree with Nearpeer{'\''}s <a className={Classes.footerLink} href='' onClick={openTos} data-focus-outline='padding:3,radius:3'>Terms of Service</a> and <a className={Classes.footerLink} href='' onClick={openPrivacyPolicy} data-focus-outline='padding:3,radius:3'>Privacy Policy</a>.
                                                        </Typography>
                                                    }
                                                    sx={errors.agreeToTerms ? { '& .MuiTypography-root': { color: 'error.main' } } : null}
                                                    control={
                                                        <StyledCheckbox
                                                            {...field}
                                                            mode={mode}
                                                            checked={!!field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                            size='small'
                                                            name='agree-to-terms-checkbox'
                                                            sx={errors.agreeToTerms ? { color: 'error.main' } : null}
                                                            inputProps={{ 'data-focus-outline': 'radius:40' }}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.agreeToTerms && (
                                            <FormHelperText sx={{ color: 'error.main' }} id='agree-to-terms-checkbox'>
                                                Please agree to terms
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <FullScreenDialog
                                    title='Privacy Policy'
                                    open={state.privacyPolicyOpen}
                                    onRequestClose={closePrivacyPolicy}
                                >
                                    <PrivacyPolicyContents />
                                </FullScreenDialog>
                                <FullScreenDialog
                                    title='Terms of Service'
                                    open={state.tosOpen}
                                    onRequestClose={closeTos}
                                >
                                    <TosContents />
                                </FullScreenDialog>
                            </> : null}
                            {useLogoutOtherDevices ? <>
                                <Grid item xs={12} textAlign={'left'}>
                                    <FormControl>
                                        <Controller
                                            name='logoutOtherDevices'
                                            control={control}
                                            rules={{ required: true }}
                                            // eslint-disable-next-line hapi/hapi-scope-start
                                            render={(field) => (
                                                <FormControlLabel
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                textAlign:'center',
                                                                color: mode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
                                                            }}
                                                        >
                                                            Log me out from all other devices
                                                        </Typography>
                                                    }
                                                    sx={errors.logoutOtherDevices ? { '& .MuiTypography-root': { color: 'error.main' } } : null}
                                                    control={
                                                        <StyledCheckbox
                                                            {...field}
                                                            mode={mode}
                                                            checked={!!field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                            size='small'
                                                            name='logoutOtherDevices-checkbox'
                                                            sx={errors.logoutOtherDevices ? { color: 'error.main' } : null}
                                                            style={{ marginLeft: 8 }}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </> : null}
                            <Grid item xs={12}>
                                <StyledButton
                                    style={!usePasswordRules ? { marginTop: 20 } : {}}
                                    mode={mode}
                                    type='submit'
                                    disabled={isSubmitting || (
                                        (useCurrentPassword && !currentPassword)
                                        || !passwordsMatch
                                        || !upperCaseMatched
                                        || !lowerCaseMatched
                                        || !digitsOrSpecialMatched
                                        || !minLengthMatched
                                        || (showAgreeToTerms && !agreeToTermsValue)
                                    )}
                                    data-focus-outline='radius:40,padding:4'
                                    onClick={() => {

                                        AnimatedFocusIndicator.onBlurHandler();
                                    }}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            sx={{
                                                color: 'common.white',
                                                width: '20px !important',
                                                height: '20px !important',
                                                mr: theme.spacing(2)
                                            }}
                                        />
                                    ) : null}
                                    Save Password
                                </StyledButton>
                            </Grid>
                            {passwordReset && <Grid item style={{ height: 30 }}>
                                <a
                                    onClick={() => setState({ ...state, showForgotPasswordModal: true })}
                                    onKeyDown={(evt) => {

                                        if (evt.key === 'Enter' || evt.code === 'Space') {
                                            setState({ ...state, showForgotPasswordModal: true });
                                        }
                                    }}
                                    className={Classes.link}
                                    tabIndex={0}
                                    data-focus-outline='padding:4,radius:4'
                                >
                                    Forgot Current Password
                                </a>
                            </Grid>}
                        </Grid>
                    </OverrideStyles>
                </form>
            </CardContent>
            <Dialog
                open={state.showForgotPasswordModal}
                disableEscapeKeyDown
                onClose={() => setState({ ...state, showForgotPasswordModal: false })}
                aria-labelledby='alert-password-reset-title'
                aria-describedby='alert--password-reset-description'
            >
                <div
                    tabIndex={0}
                    ref={forgotPwContentsRef}
                    data-focus-outline='radius:15'
                    onKeyDown={escListener}
                >
                    <DialogTitle id='alert-Inappropriate-Message-title'>Password Reset</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-Inappropriate-Message-description'>
                            {'Clicking \'Confirm\' will send you a password reset email and log you out on all devices. If you haven\'t received this email within a few minutes, please check your spam folder.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setState({ ...state, showForgotPasswordModal: false })}
                            color='secondary'
                            data-focus-outline='radius:40'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {

                                analyticsTemplates.buttons('change_password', 'change_password: forgot password');
                                // passwordReset func logs the user out
                                passwordReset();
                            }}
                            color='primary'
                            data-focus-outline='radius:40'
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </Card>
    );
};

ChangePasswordCard.propTypes = {
    mode: T.string,
    useCurrentPassword: T.bool,
    useLogoutOtherDevices: T.bool,
    onSubmit: T.func.isRequired,
    mainTitle: T.string.isRequired,
    subtitle: T.string.isRequired,
    showAgreeToTerms: T.bool,
    usePasswordRules: T.bool,
    passwordReset: T.func,
    isSignup: T.bool
};

module.exports = ChangePasswordCard;

internals.OverrideStyles = Styled.div`
    .MuiFormControlLabel-asterisk {
        display: none;
    }
    .MuiFormLabel-asterisk {
        display: none;
    }
`;

internals.StyledButton = styled(Button)`
    background-color: ${({ theme,mode }) => mode === 'dark' ? theme.palette.primary.contrastText  : theme.palette.primary.main};
    color: ${({ theme,mode }) => mode === 'dark' ? theme.palette.primary.main  : theme.palette.primary.contrastText};
    height: 3em;
    width: 100%;
    padding: 0;
    &:hover {
        background-color: ${({ theme,mode }) => mode === 'dark' ? theme.palette.primary.contrastText  : theme.palette.primary.main};
        color: ${({ theme,mode }) => mode === 'dark' ? theme.palette.primary.main  : theme.palette.primary.contrastText};
    }
    &.Mui-disabled{
        background-color: ${({ theme,mode }) => mode === 'dark' ? theme.palette.primary.contrastText  : theme.palette.action.disabledBackground};
        color: ${({ theme }) => theme.palette.action.disabled};
    }
    font-family: ${Fonts.headerFont};
    font-weight: bold;
    text-transform: none;
    font-size: 1rem;
    line-height: 3em;
`;

internals.StyledCheckbox = styled(Checkbox)`
    &&& {
        color: ${({ theme, mode }) => mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main};
        fill: ${({ theme, mode }) => mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main};
    }
`;
