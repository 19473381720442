const { connect } = require('react-redux');
const { context } = require('app-context');
const { push: Push } = require('connected-react-router');
const ClassSearchDialog = require('../components/ClassSearchDialog');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => {

        const appContextFilter = selectors.getAppContextFilter(state);
        const classes = selectors.getClasses(state);
        const userClasses = selectors.getClasses_forMyClasses(state) || [];

        const filteredClasses = (classes || [])
            .filter(({ id }) => {

                // Filter out classes the user already belongs to
                return !userClasses.find((c) => c.id === id);
            })
            .filter(({ userPermissions }) => {

                if (appContextFilter === 'all') {
                    return true;
                }

                return userPermissions === appContextFilter;
            });

        return ({
            classes: filteredClasses,
            searchResults: selectors.getClasses_forClassSearch(state),
            rolePermissions: selectors.getCurrentUserRolePermissions(state),
            showJoin: (id) => selectors.getCanJoinClass(state, id)
        });
    }, {
        fetchClasses: actions.dataFetching.fetchClasses,
        onRequestSearch: actions.dataFetching.fetchClassSearchResults,
        onClickAddClass: actions.classes.join,
        push: Push
    }
)(ClassSearchDialog);
