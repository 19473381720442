const { default: ReactGA } = require('react-ga4');
const { LOCATION_CHANGE } = require('connected-react-router');
const { getDevice } = require('./device');

const Configuration = require('app-context/configuration');

const debug = __DEBUG__;
const isCordovaApp = !!window.cordova;

const firebaseObj = isCordovaApp ?  window.cordova.plugins.firebase : null;
const Storage = window.localStorage;
const GetQueryParams = require('./get-query-params');

// TODO figure out how to support beacon
// Try to use navigator.sendBeacon first, fallback to axios
// const supportsBeacon = String(process.env.ANALYTICS_API_HOST || '').includes('localhost') ? false : typeof navigator.sendBeacon === 'function';
const supportsBeacon = false;

let http;
if (supportsBeacon) {
    const baseUrl = process.env.ANALYTICS_API_HOST + process.env.ANALYTICS_API_PREFIX;
    http = {
        post: (path, payload) => {

            const blobData = new Blob([JSON.stringify(payload)], { type: 'application/json' });
            navigator.sendBeacon(baseUrl + path, blobData);
        }
    };
}
else {
    http = Configuration.analyticsApi.makeHttp();
}

const internals = { http };

const {
    SIGNUP,
    NOTIFICATIONS,
    AUTH,
    PROFILE_MANAGEMENT,
    CLASSES,
    DATA_FETCHING,
    COMMUNICATION,
    APP_FEEDBACK
} = require('../action-types');

if (debug) {
    console.log('INITIALIZE ANALYTICS!');
}

if (!isCordovaApp) {
    ReactGA.initialize(process.env.GA_TRACKING_ID, {
        gaOptions: {
            // Disables cookies.
            storage: 'none',
            // Your GA tracking id.
            trackingId: process.env.GA_TRACKING_ID,
            // Will return null if not set, and GA will then assign one.
            clientId: Storage.getItem('ga:clientId')
            //debug_mode: true
        }
    });
}

const AnalyticsUtils = {};
module.exports = AnalyticsUtils;

// Ga templates

AnalyticsUtils.analyticsTemplates = {

    navigationWithVal: (action, label, value) => {

        AnalyticsUtils.logEvent({
            category: 'navigation',
            action,
            label,
            value
        });
    },

    generic: (options) => {

        AnalyticsUtils.logEvent(options);
    }
};

[
    'buttons',
    'navigation',
    'events'
]
    .forEach((actionLabelEventType) => {

        AnalyticsUtils.analyticsTemplates[actionLabelEventType] = (action, label, item_id) => {

            AnalyticsUtils.logEvent({
                category: actionLabelEventType,
                action,
                label,
                item_id
            });
        };
    });

AnalyticsUtils.sendAnalyticsWithConfig = (gaConfig) => {

    if (gaConfig.length === 3) {
        const [gaTmpl, ...rest] = gaConfig;
        return AnalyticsUtils.analyticsTemplates[gaTmpl](...rest);
    }

    return AnalyticsUtils.analyticsTemplates.events(...gaConfig);
};

AnalyticsUtils.gaClickTemplate = (tmpl, action, label) => {

    return () => {

        AnalyticsUtils.analyticsTemplates[tmpl](action, label);
    };
};

// react-router onUpdate (for logging specific page-view actions)
// Take a look in the route configs for where these are applied

AnalyticsUtils.onRouteUpdate = (onUpdateProps) => {

    const { props: routeProps = {} } = onUpdateProps.route;
    const { ga } = routeProps;

    let gaTemplateName;
    let gaTemplateArgs;

    if (!ga) {
        return;
    }

    if (typeof ga === 'function') {
        // If it's a function, it must return { [templateName,] templateArgs }
        const { templateName, templateArgs } = ga(onUpdateProps);

        if (templateName) {
            gaTemplateName = templateName;
        }
        else {
            gaTemplateName = 'navigation';
        }

        gaTemplateArgs = templateArgs;
    }
    else if (Array.isArray(ga)) {
        const firstEl = ga[0];

        if (AnalyticsUtils.analyticsTemplates[firstEl]) {
            gaTemplateName = firstEl;
            gaTemplateArgs = ga.slice(1);
        }
        else {
            gaTemplateName = 'navigation';
            gaTemplateArgs = ga;
        }
    }

    // If these exist, send 'em out!
    if (gaTemplateName && gaTemplateArgs) {
        AnalyticsUtils.analyticsTemplates[gaTemplateName](...gaTemplateArgs);
    }
};


// redux middleware -- to listen for and respond to actions

const ignorePages = [
    '/app',
    '/app/messaging'
];

AnalyticsUtils.gaMiddleware = (store) => (next) => (action) => { // eslint-disable-line

    switch (action.type) {

        case LOCATION_CHANGE: {

            // const normalizedPath = action && action.payload && action.payload.pathname && action.payload.pathname.replace(/\/$/, '');
            const { location: { pathname = '' } = {} } = action.payload || {};

            const normalizedPath = pathname === '/' ? pathname : pathname.replace(/\/$/, '');

            if (ignorePages.indexOf(normalizedPath) === -1) {

                let loggedPath;

                // google ignores the pageView if the path is emptystring
                // So make sure this gets logged if '/' is the path
                if (pathname.split('?')[0] === '/') {
                    loggedPath = '/';
                }
                else {
                    loggedPath = normalizedPath;
                }

                const { r: rParam } = GetQueryParams();

                if (rParam) {
                    loggedPath += `?r=${rParam}`;
                }

                // TODO find a way to check if this path is valid, or if it is being redirected immediately
                AnalyticsUtils.logPageView(loggedPath);
            }

            break;
        }

        case SIGNUP.STEP_1.COMPLETE.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('create account', 'Create account: create account');
            break;

        case NOTIFICATIONS.ACCEPT.SUCCESS:

            if (action.payload.request.type === 'peer') {
                AnalyticsUtils.analyticsTemplates.buttons('connection accepted', 'notifications: connection accepted');
            }

            break;

        case NOTIFICATIONS.DECLINE.SUCCESS:

            if (action.payload.request.type === 'peer') {
                AnalyticsUtils.analyticsTemplates.buttons('connection ignored', 'notifications: connection ignored');
            }

            break;

        case AUTH.LOGOUT.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('logout', 'logged out');
            break;

        case AUTH.CLOSE_ACCOUNT.SUCCESS:
        case AUTH.ACCOUNT_CLOSED_CLEAR:
            AnalyticsUtils.analyticsTemplates.buttons('close account', 'user closed account');
            break;

        case PROFILE_MANAGEMENT.UPDATE_PROFILE.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('my profile saved', 'my profile: profile saved');
            break;

        case CLASSES.JOIN.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('class added', 'my classes: class added');
            break;

        case CLASSES.LEAVE.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('left class', 'my classes: left class');
            break;

        case DATA_FETCHING.FETCH_CLASS.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('entered class', 'my classes: entered class');
            break;

        case COMMUNICATION.REQUEST_PEER.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('peer connection requested', 'peer profile: connection requested');
            break;

        case COMMUNICATION.REQUEST_CHAT.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('peer chat requested', 'peer profile: chat requested');
            break;

        case APP_FEEDBACK.SUBMIT_BUG_REPORT.SUCCESS:

            AnalyticsUtils.analyticsTemplates.buttons('support submitted', `support: ${action.payload.request.bugType}`);
            break;
    }

    return next(action);
};

AnalyticsUtils.getPlatform = () => {

    if (!AnalyticsUtils.platform) {
        AnalyticsUtils.platform = getDevice();
    }

    return AnalyticsUtils.platform;
};

// Building blocks

AnalyticsUtils.logEvent = (_payload) => {

    const { noGa, ...payload } = _payload;

    // WIP Ignore anonymous events for now til we
    // figure out how to collect anon events and assign
    // a userId once the user logs in.
    if (!AnalyticsUtils.userId) {
        return;
    }

    if (debug) {
        console.log('LOG EVENT!');
        console.log(JSON.stringify(payload));
    }

    /*
        payload can contain:
        {
            category,
            action,
            label,
            value
        }
    */

    const eventActionName = payload.action.toLowerCase().replace(/ /g,'_');
    payload.action = eventActionName;
    payload.isMobile = !!isCordovaApp;
    payload.userId = AnalyticsUtils.userId;

    // const getToken = () => Storage.get('token');
    // const authorization = (token) => ({ headers: { Authorization: `Bearer ${token || getToken()}` } });

    if (process.env.LOG_ANALYTICS) {
        internals.http.post(
            '/user-activity',
            {
                ...payload,
                platform: AnalyticsUtils.getPlatform()
            }
        );
    }

    if (noGa) {
        return;
    }

    // Dimension18 is `app` -- set to 1 if in-app, 0 if not
    const dimension18 = isCordovaApp ? '1' : '0';

    if (isCordovaApp) {
        firebaseObj.analytics.logEvent(eventActionName, {
            category: payload.category,
            action: eventActionName,
            label: payload.label,
            dimension18,
            item_id: payload && payload.item_id ? payload.item_id : undefined
        });
    }
    else {
        ReactGA.gtag('event', eventActionName, {
            category: payload.category,
            action: eventActionName,
            label: payload.label,
            dimension18,
            item_id: payload && payload.item_id ? payload.item_id : undefined
        });
    }
};

AnalyticsUtils.setValue = (key, value) => {

    if (debug) {
        console.log('SET VALUE!');
        console.log(key + ': ', value);
    }

    if (!isCordovaApp) {
        const gaSet = {};
        gaSet[key] = value;

        ReactGA.set(gaSet);
    }
};

AnalyticsUtils.logPageView = (pathName) => {

    if (debug) {
        console.log('LOG PAGE VIEW!');
        console.log(pathName);
    }

    AnalyticsUtils.logEvent({
        category: 'page',
        action: 'view',
        label: 'pathName',
        value: pathName,
        noGa: true
    });

    // Dimension18 is `app` -- set to 1 if in-app, 0 if not

    const dimension18 = isCordovaApp ? '1' : '0';

    if (isCordovaApp) {
        firebaseObj.analytics.logEvent('page_view', {
            pathName,
            page: pathName,
            'page_path': pathName,
            dimension18
        });
    }
    else {
        ReactGA.set({ page: pathName });
        ReactGA.gtag('event', 'page_view', {
            'page_path': pathName,
            page: pathName,
            dimension18
        });
    }
};

AnalyticsUtils.getUserId = () => {

    return AnalyticsUtils.userId;
};

// These 2 funcs get called from initializers/analytics

AnalyticsUtils.setUserId = (userId) => {

    if (userId === AnalyticsUtils.userId) {
        return;
    }

    AnalyticsUtils.userId = userId;

    if (isCordovaApp) {
        firebaseObj.analytics.setUserId(`${userId}`);
        firebaseObj.analytics.logEvent('login');
    }
    else {
        AnalyticsUtils.setValue('userId', AnalyticsUtils.userId);
    }

    // The 'login button tap' event
    AnalyticsUtils.analyticsTemplates.buttons('login', 'user logged in');
};

AnalyticsUtils.unsetUserId = () => {

    if (AnalyticsUtils.userId !== null) {

        AnalyticsUtils.userId = null;

        if (isCordovaApp) {
            firebaseObj.analytics.setUserId(null);
        }
        else {
            AnalyticsUtils.userId = null;
            AnalyticsUtils.setValue('userId', null);
        }
    }
};
