const React = require('react');
const T = require('prop-types');
const StrangeForms = require('strange-forms');
const { default: TextField } = require('@mui/material/TextField');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: Select } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: Chip } = require('@mui/material/Chip');
const { default: Input } = require('@mui/material/Input');
const EmojiPicker = require('../EmojiPicker');
const { default: Classes } = require('./styles.scss');
const { default: styled } = require('styled-components');
const { USER_ROLE_IDS } = require('utils/constants');

const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(2)};
  margin-top: 10px;
  min-width: 120px;
  max-width: 550px;
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  margin: 2px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class CreateGroupPage extends StrangeForms(React.Component) {

    static propTypes = {
        class_: T.shape({
            type: T.string,
            name: T.string,
            emojiSymbol: T.string,
            facilitator: T.string,
            shortDescription: T.string,
            additionalDescription: T.string,
            classLocation: T.string,
            private: T.bool,
            number: T.string,
            room: T.string,
            campus: T.string,
            section: T.string,
            semester: T.string,
            credits: T.number,
            misc: T.string,
            roles: T.array,
            isAnnouncement: T.bool
        }),

        act: T.func.isRequired,
        schoolRoles: T.array.isRequired,
        // Beneficial for admins who manage several schools
        school: T.string,
        setGlobalInputPageError: T.func,
        removeGlobalInputPageError: T.func,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool
    }

    constructor(props) {

        super(props);

        this.strangeForm({
            // Declare the interesting fields.
            fields: [
                'emojiSymbol',
                'name',
                'type', // class or group
                'facilitator',
                'private',
                'shortDescription',
                'additionalDescription',
                'classLocation',
                'number', // class number
                'room',
                'campus',
                'section',
                'semester',
                'credits',
                'misc',
                'roles',
                'isAnnouncement'
            ],
            // Obtain a form field value from props.
            // Can also be a single string, single function, or null (see strange-forms API docs).
            get: (someProps, field) => someProps.class_[field] || '',
            // Also takes per-field format similar to get.
            act: props.act, //this.act.bind(this),
            // Defaults to (e) => e.target.value.  Also takes per-field format similar to get.
            getFormValue: {

                // handle checkbox
                private: (ev, isChecked) => isChecked,
                isAnnouncement: (ev, isChecked) => isChecked,
                emojiSymbol: (emojiSymbol) => emojiSymbol,
                '*': (e) => e.target.value
            }
        });

        this.error = this._error.bind(this);
    }

    getFormValue(e) {

        return e.target.value || '';
    }

    static validate(field, value) {

        if (field === 'name') {
            if (!value) {
                return 'Name is required';
            }
        }

        if (field === 'roles') {
            if (!value || value.length < 1) {
                return 'At least one role is required';
            }
        }

        return null;
    }

    static allValid(values) {

        return Object.keys(values).every((field) => {

            return CreateGroupPage.validate(field, values[field]) === null;
        });
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? CreateGroupPage.validate(field, this.fieldValue(field)) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            this.props.removeGlobalInputPageError();
            return null;
            // eslint-disable-next-line no-else-return
        } // eslint-disable-next-line no-else-return
        else {
            this.props.setGlobalInputPageError();
        }

        return CreateGroupPage.validate(field, this.fieldValue(field));
    }

    render() {

        const {
            schoolRoles,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        const filteredSchoolRoles = schoolRoles.filter((role) => {

            return !USER_ROLE_IDS.SUPERUSER.includes(role.id);
        });

        return <div>
            {/* <RadioButtonGroup
                name='type'
                label='Do you want to create a group?'
                labelId='type'
                onChange={this.proposeNew('type')}
                valueSelected={this.fieldValue('type')}
                defaultSelected='group'
            >
                <RadioButton
                    value='group'
                    label='Create a Group'
                    aria-label='Create a Group'
                />
                <RadioButton
                    value='class'
                    label='Create a Class'
                    aria-label='Create a Class'
                />
            </RadioButtonGroup> */}

            <div style={{ paddingTop: 20 }}>

                <InputLabel style={{ marginBottom: 0,display:'flex',color:'#000000' }} id={'demo-mutiple-chip-label'}>*Who should have permissions to join this group?</InputLabel>
                <StyledFormControl >
                    <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        inputProps={{
                            'aria-labelledby': 'demo-mutiple-chip-label'
                        }}
                        multiple
                        value={this.fieldValue('roles')}
                        onChange={this.proposeNew('roles')}
                        input={<Input id="select-multiple-chip" />}
                        error={this.state.rolesErrorShow}
                        onBlur={this.showError('roles')}
                        onFocus={this.showError('roles', false)}
                        renderValue={(selected) => {

                            return (
                                <StyledChips>
                                    {selected.map((value) => {

                                        const schoolRole = schoolRoles && schoolRoles.find((role) => {

                                            return role.id === value;
                                        });

                                        return <StyledChip key={value} label={`${schoolRole.label}`} />;
                                    })}
                                </StyledChips>
                            );
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="" disabled>
                            <ListItemText primary={`Select Roles`} />
                        </MenuItem>

                        {filteredSchoolRoles.map((schoolRole) => {

                            return (
                                <MenuItem key={schoolRole.id} value={schoolRole.id}>
                                    <Checkbox checked={this.fieldValue('roles').indexOf(schoolRole.id) > -1} />
                                    <ListItemText primary={`${schoolRole.label}`} />
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {this.state.rolesErrorShow && <FormHelperText  error={this.state.rolesErrorShow}>{this.error('roles')}</FormHelperText>}
                </StyledFormControl>
            </div>
            <div>
                <FormControlLabel
                    label='Is Announcements Only Group'
                    control={<Checkbox
                        checked={!!this.fieldValue('isAnnouncement')}
                        onChange={this.proposeNew('isAnnouncement')}
                    />}
                />
            </div>
            <div style={{ maxWidth:'20em',padding:'1em 0em' }}>
                <EmojiPicker
                    onChange={this.proposeNew('emojiSymbol')}
                    label={`Selected ${this.fieldValue('type') === 'group' || this.fieldValue('type') === null  ? 'Group' : 'Class'} Emoji:`}
                />
            </div>

            <div className={Classes.gridFieldset}>
                <TextField
                    fullWidth
                    label={`*${this.fieldValue('type') === 'group' || this.fieldValue('type') === null  ? 'Group' : 'Class'} Name`}
                    value={this.fieldValue('name')}
                    onChange={this.proposeNew('name')}
                    placeholder='Physics 101'
                    error={this.state.nameErrorShow}
                    onBlur={this.showError('name')}
                    onFocus={this.showError('name', false)}
                    helperText={this.error('name')}
                    inputProps={{ maxLength: 50,'aria-label':`${this.fieldValue('type') === 'group' || this.fieldValue('type') === null  ? 'Group' : 'Class'} Name Input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                    // NOTE: After upgrading MUI, maxLength will be removed for an inputProps obj:
                    // inputProps={{ maxLength: 50 }}
                    // This will need to be done for every TextField on this page, and several others across the app
                />

                <TextField
                    fullWidth
                    label='Facilitator'
                    value={this.fieldValue('facilitator')}
                    onChange={this.proposeNew('facilitator')}
                    placeholder='Mr. Meeseeks'
                    inputProps={{ maxLength: 50,'aria-label':`facilitator input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

                {(!isTeamsApp && !isCompany && !isCommunity && !isSchoolOnline) ? <TextField
                    fullWidth
                    label='Campus'
                    value={this.fieldValue('campus')}
                    onChange={this.proposeNew('campus')}
                    placeholder='Watson School of Engineering'
                    inputProps={{ maxLength: 50,'aria-label':`campus input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                /> : null}

                <TextField
                    fullWidth
                    label='Short Description'
                    value={this.fieldValue('shortDescription')}
                    onChange={this.proposeNew('shortDescription')}
                    placeholder='FYS'
                    inputProps={{ maxLength: 50,'aria-label':`Short description input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

                {this.fieldValue('type') === 'class' && <TextField
                    fullWidth
                    label='Number'
                    value={this.fieldValue('number')}
                    onChange={this.proposeNew('number')}
                    placeholder='EECE 252'
                    inputProps={{ 'aria-label':`Number input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />}

                {(!isTeamsApp && !isCompany && !isCommunity) ? <TextField
                    fullWidth
                    label='Term or Semester'
                    value={this.fieldValue('semester')}
                    onChange={this.proposeNew('semester')}
                    placeholder='F2017'
                    inputProps={{ 'aria-label': `Term or Semester input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                /> : null}

                <TextField
                    fullWidth
                    label='Additional Description'
                    value={this.fieldValue('additionalDescription')}
                    onChange={this.proposeNew('additionalDescription')}
                    placeholder='TR 10:30−11:45'
                    inputProps={{ maxLength: 100,'aria-label':`Additional Description input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

                {this.fieldValue('type') === 'class' && <TextField
                    type='number'
                    fullWidth
                    label='Credits'
                    value={this.fieldValue('credits')}
                    onChange={this.proposeNew('credits')}
                    inputProps={{ 'aria-label':`Credits input` }}
                    placeholder='4'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />}

                <TextField
                    fullWidth
                    label='Location'
                    value={this.fieldValue('classLocation')}
                    onChange={this.proposeNew('classLocation')}
                    placeholder='AS GOTWA201'
                    inputProps={{ maxLength: 50, 'aria-label':`Location input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

                {this.fieldValue('type') === 'class' && <TextField
                    fullWidth
                    label='Room'
                    value={this.fieldValue('room')}
                    onChange={this.proposeNew('room')}
                    placeholder='SCI 201'
                    inputProps={{ maxLength: 10, 'aria-label':`Room input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />}

                <TextField
                    fullWidth
                    label='Other Search Terms'
                    value={this.fieldValue('misc')}
                    onChange={this.proposeNew('misc')}
                    placeholder='DiffEQ'
                    inputProps={{ 'aria-label':`Other Search Terms input` }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

            </div>

            <h3>Privacy Settings</h3>
            <p>A private group is not visible in search and members must be manually added</p>

            <FormControlLabel
                label='Set As Private'
                control={<Checkbox
                    checked={!!this.fieldValue('private')}
                    onChange={this.proposeNew('private')}
                />}
            />
        </div>;
    }
}

module.exports = CreateGroupPage;
