const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: MuiPaper } = require('@mui/material/Paper');
const { default: MuiDialog } = require('@mui/material/Dialog');
const { default: AppBar } = require('@mui/material/AppBar');
const { default: MuiTypography } = require('@mui/material/Typography');
const { default: Toolbar } = require('@mui/material/Toolbar');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: CloseIcon } = require('@mui/icons-material/Close');
const { ELEMENT_IDS } = require('utils/constants');
const Snackbar = require('containers/Snackbar');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const {
    useRef,
    useEffect
} = React;

const internals = {};

module.exports = function FullScreenDialog(props) {

    const {
        children,
        onRequestClose,
        title,
        open,
        fixedToTop = null,
        ...rest
    } = props;

    const {
        Dialog,
        Paper,
        WithTopbarPaper,
        Header,
        FixedToTopWrapper,
        InnerWrapper,
        ScrollContainer
    } = internals;

    const titleRef = useRef();
    const paperRef = useRef();

    useEffect(() => {

        if (!open) {
            // TODO This is a bit wonky, could be better,
            // the ref situation is difficult
            titleRef.current = 'focus-on-next-render';
            AnimatedFocusIndicator.onBlurHandler();
        }
    }, [open]);

    const onTitleRef = (ref) => {

        if (open && ref && titleRef.current === 'focus-on-next-render') {
            titleRef.current = ref;
            ref.focus();
        }
    };

    const onScroll = (type) => {

        AnimatedFocusIndicator.wrapFocusIndicatorForElement();

        if (type === 'paper' && paperRef.current) {
            const focusIndicator = document.getElementById(ELEMENT_IDS.focusIndicator);
            const paperBoundingRect = paperRef.current.getBoundingClientRect();
            const focusIndicatorBoundingRect = focusIndicator.getBoundingClientRect();

            if (focusIndicatorBoundingRect.top < paperBoundingRect.top) {
                AnimatedFocusIndicator.onBlurHandler();
            }
            else if (focusIndicatorBoundingRect.top >= paperBoundingRect.top + paperBoundingRect.height - focusIndicatorBoundingRect.height) {
                AnimatedFocusIndicator.onBlurHandler();
            }
            else {
                AnimatedFocusIndicator.wrapFocusIndicatorForElement();
            }
        }
        else {
            AnimatedFocusIndicator.wrapFocusIndicatorForElement();
        }
    };

    return (
        <Dialog
            fullWidth
            fullScreen
            open={open}
            onClose={onRequestClose}
            $hasFixedToTop={!!fixedToTop}
            // Temp fix since it seems material-ui internals are setting
            // this property to something dynamic, and it's causing a WAVE error
            aria-labelledby=''
            {...rest}
        >
            <AppBar position='fixed' style={{ borderRadius: 0 }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onRequestClose}
                        aria-label="close"
                        size="large"
                        data-focus-outline='radius:40,padding:-4'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Header
                        tabIndex={0}
                        ref={onTitleRef}
                    >
                        {title}
                    </Header>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <InnerWrapper style={{ overflow: 'hidden', height: '100%' }}>
                {fixedToTop && (
                    <FixedToTopWrapper>
                        {fixedToTop}
                    </FixedToTopWrapper>
                )}
                <ScrollContainer
                    onScroll={() => onScroll('container')}
                >
                    {fixedToTop ?
                        <WithTopbarPaper onScroll={() => onScroll('paper')} ref={paperRef}>
                            {children}
                        </WithTopbarPaper> :
                        <Paper zDepth={0} onScroll={() => onScroll('paper')} ref={paperRef}>
                            {children}
                        </Paper>
                    }
                </ScrollContainer>
            </InnerWrapper>
            <Snackbar />
        </Dialog>
    );
};

module.exports.propTypes = {
    children: T.node.isRequired,
    open: T.bool.isRequired,
    onRequestClose: T.func.isRequired,
    title: T.oneOfType([T.string, T.element]),
    fixedToTop: T.element
};

internals.InnerWrapper = Styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
`;

internals.ScrollContainer = Styled.div`
    width: 100%;
    height: 100%;
    min-height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
`;

internals.Paper = Styled(MuiPaper)`
    max-width: 600px;
    margin: auto;
    width: 100%;
    min-height: 100%;
    padding: 30px 32px 40px;
    border-radius: 0 !important;
`;

internals.WithTopbarPaper = Styled(MuiPaper)`
    max-width: 600px;
    margin: auto;
    width: 100%;
    height: calc(100% - 136px);
    overflow-y:auto;
    padding: 30px 32px 25px;
    border-radius: 0 !important;
`;

internals.FixedToTopWrapper = Styled.div`
    z-index: 1;
    width: 100%;
    max-width: 600px;
    margin: auto;
`;

internals.Header = Styled(MuiTypography).attrs({
    variant: 'h1',
    align: 'center',
    color: 'inherit'
})`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
`;

internals.Dialog = Styled(MuiDialog)`
    .MuiDialog-paper {
        overflow-y: ${({ $hasFixedToTop }) => {

        return $hasFixedToTop ? 'hidden' : 'auto';
    }}
    }
`;
