const { useRef, useState } = require('react');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const { default: styled } = require('styled-components');
const { useDispatch } = require('react-redux');

const { default: AddIcon } = require('@mui/icons-material/Add');
const { default: FAB } = require('@mui/material/Fab');
const { default: List } = require('@mui/material/List');
const { default: MuiButton } = require('@mui/material/Button');
const { default: ChatIcon } = require('@mui/icons-material/Chat');
const { default: SettingsIcon } = require('@mui/icons-material/Settings');
const { default: PeopleIcon } = require('@mui/icons-material/People');

const { default: Classes } = require('./ClassDetailPage/styles.scss');
const ClassListItem = require('./ClassListItem');
const MaterialMenu = require('components/MaterialMenu');
const RadioButtonGroup = require('components/RadioButtonGroup');
const RadioButton = require('components/RadioButton');
const { groupChatLink, groupDetailsLink } = require('utils/group');
const { transient$Props } = require('styles/utils');

const { context } = require('app-context');
const { useAppSelector } = require('utils/redux');
const { useMediaQuery } = require('../../../../hooks/use-media-query');

const {
    actions,
    selectors: {
        all: selectors
    }
} = context;

const internals = {};

module.exports = function GroupHeader(props) {

    const { group, variant, openAlertDialogWithProps } = props;
    const { Container, HeaderButton, ButtonsContainer, NotificationSettings } = internals;
    const { isAnnouncement, notificationLevel } = group;

    const dispatch = useDispatch();
    const headerNotificationSettings = useRef(notificationLevel);

    const showJoin = useAppSelector(selectors.getCanJoinClass, group.id);

    const {
        canEditGroups,
        canSeeChatMessagesNoJoin
    } = useAppSelector(selectors.getCurrentUserRolePermissions);

    const menuButtonRef = useRef(null);

    const isWide = useMediaQuery('screen and (min-width: 600px)');

    const leaveGroup = () => {

        openAlertDialogWithProps({
            title: 'Leave Group',
            message: 'Are you sure you\'d like to leave this group?',
            confirm: () => dispatch(actions.classes.leave(group))
        });
    };

    const editGroup = () => {

        dispatch(actions.router.push(`/app/classes/${group.id}/edit`));
    };

    const openNotificationSettings = () => {

        openAlertDialogWithProps({
            title: 'Choose Your Notification Level',
            message: (
                <NotificationSettings
                    headerNotificationSettingsRef={headerNotificationSettings}
                    notificationLevel={notificationLevel}
                />
            ),
            declineLabel: 'Cancel',
            confirmLabel: 'Save',
            confirm: async () => {

                await dispatch(actions.classes.updateNotificationLevel({
                    id: group.id,
                    notificationLevel: headerNotificationSettings.current
                }));
            }
        });
    };

    const menuOptions = [];

    if (canEditGroups) {
        menuOptions.push({
            name: 'Edit Group',
            action: editGroup,
            ariaRole: 'button',
            ariaLabel: 'Edit Group'
        });
    }

    if (!isAnnouncement) {
        menuOptions.push({
            name: 'Notification Settings',
            action: openNotificationSettings,
            ariaRole: 'button',
            ariaLabel: 'Notification Settings'
        });
    }

    if (!showJoin) {
        menuOptions.push({
            name: 'Leave Group',
            action: leaveGroup,
            ariaRole: 'button',
            ariaLabel: 'Leave Group'
        });
    }

    const showSecondButton = showJoin
        && canSeeChatMessagesNoJoin
        && (group.sid || (isAnnouncement && group.id));

    const MiddleActionButtons = (
        <ButtonsContainer $isWide={isWide}>
            {(!showJoin || canSeeChatMessagesNoJoin) && <MaterialMenu
                className={Classes.settingsMenu}
                anchorOrigin={{ horizontal: 'left' }}
                buttonRef={menuButtonRef}
                data-focus-outline='radius:20,padding:3'
                iconElement={<SettingsIcon
                    style={{ color:'#ffffff' }}
                    onKeyDown={(evt) => {

                        if (evt.key === 'Enter' || evt.code === 'Space') {
                            menuButtonRef?.current?.click();
                        }
                    }}
                />}
                menuOptions={menuOptions}
            />}
            <HeaderButton
                group={group}
                isWide={isWide}
                showJoin={showJoin}
                isAnnouncement={isAnnouncement}
                variant={variant}
            />
            {showSecondButton && (
                <HeaderButton
                    group={group}
                    isWide={isWide}
                    showJoin={false}
                    isAnnouncement={isAnnouncement}
                    variant={variant}
                />
            )}
        </ButtonsContainer>
    );

    const superUserMinHeight = canSeeChatMessagesNoJoin && showJoin;

    return (
        <Container $superUserMinHeight={superUserMinHeight}>
            <List>
                <ClassListItem
                    displayDetails={variant === 'details'}
                    titleTopPadding={variant === 'chat'}
                    showPrivate
                    darkBackground
                    extraSecondaryText={
                        isAnnouncement
                            ? <>
                                <div style={{
                                    marginTop: 0,
                                    marginBottom: 4,
                                    color: 'white',
                                    fontStyle: 'italic',
                                    fontSize: 11
                                }}>
                                    (ANNOUNCEMENTS ONLY)
                                </div>
                            </>
                            : null
                    }
                    group={group}
                    middleActionButton={MiddleActionButtons}
                    className={Classes.header}
                    titleClass={Classes.title}
                    subtitleClass={Classes.subtitle}
                    infoClass={Classes.info}
                />
            </List>
        </Container>
    );
};

module.exports.propTypes = {
    group: T.object,
    variant: T.oneOf(['chat', 'details']),
    openAlertDialogWithProps: T.func.isRequired
};

internals.HeaderButton = function HeaderButton(props) {

    const {
        group,
        isWide,
        showJoin,
        isAnnouncement,
        variant
    } = props;

    const {
        Button,
        HeaderFAB
    } = internals;

    const dispatch = useDispatch();

    const joinGroup = () => {

        dispatch(actions.classes.join(group));
    };

    let btnText = '';
    let ariaLabel = '';
    let contrast = false;
    let FABIcon = ChatIcon;
    const buttonProps = {};

    if (showJoin) {
        btnText = 'Join';
        contrast = true;
        FABIcon = AddIcon;
        buttonProps.onClick = joinGroup;
    }
    else {
        buttonProps.component = Link;

        if (variant === 'chat') {
            btnText = 'Details';
            ariaLabel = 'Go to Group Details';
            FABIcon = PeopleIcon;
            buttonProps.to = groupDetailsLink(group);
        }
        else {
            // On group members page
            buttonProps.to = groupChatLink(group);

            if (isAnnouncement) {
                btnText = 'Posts';
                ariaLabel = 'Go to Group Posts';
                contrast = true;
            }
            else {
                btnText = 'Chat';
                ariaLabel = 'Go to Group Chat';
            }
        }
    }

    if (!isWide) {
        return (
            <HeaderFAB
                mini
                {...buttonProps}
                $contrast={contrast}
                data-focus-outline='radius:40,padding:4'
            >
                <FABIcon style={{ color: contrast ? '#fff' : '#000' }} />
            </HeaderFAB>
        );
    }

    return (
        <Button
            {...buttonProps}
            variant='contained'
            aria-label={ariaLabel}
            $isAnnouncement={isAnnouncement}
            $contrast={contrast}
            data-focus-outline='radius:40,padding:4'
        >
            {btnText}
        </Button>
    );
};

internals.HeaderButton.propTypes = {
    group: T.object,
    isWide: T.bool,
    showJoin: T.bool,
    isAnnouncement: T.bool,
    variant: T.oneOf(['chat'])
};

internals.Container = styled('div', transient$Props)`
    background-color: ${({ theme }) => theme.palette.primary.main};
    overflow: hidden;
    height: auto;
    position: relative;

    min-height: ${({ $superUserMinHeight }) => $superUserMinHeight ? 140 : 0}px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    // TODO Band-aids to override material styles
    ul,
    li {
        padding-top: 0;
        padding-bottom: 0 !important;
    }

    @media screen and (max-width: 600px) {
        margin-left: -1px;
        width: calc(100% + 2px);
    }

    .MuiListItemAvatar-root {
        padding-top: 10px;
    }
`;

internals.ButtonsContainer = styled('div', transient$Props)`
    display: flex;
    flex-flow: column nowrap;
    gap: ${({ theme }) => theme.spacing(1)};

    margin: 0;
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
    width: initial;
    height: initial;

    top: ${({ $isWide }) => $isWide ? 64 : 60}px;
    right: 12px;
`;

internals.HeaderFAB = styled(FAB, transient$Props)`
    width: 40px;
    height: 40px;
    margin: 0;
    borderRadius: 50%;
    background-color: ${({ $contrast }) => $contrast ? 'rgb(169, 42, 84)' : 'rgb(221, 251, 255)'};
    color: ${({ $contrast }) => $contrast ? '#fff' : '#000'};
    &:hover {
        background-color: ${({ $contrast }) => $contrast ? 'rgb(118, 29, 58)' : 'rgb(192, 244, 248)'};
    }
`;

internals.Button = styled(MuiButton, transient$Props)`
    transition: background-color 0.4s;
    background-color: ${({ $contrast }) => $contrast ? 'rgb(169, 42, 84)' : 'rgb(221, 251, 255)'};
    color: ${({ $contrast }) => $contrast ? '#fff' : '#000'};
    &:hover {
        background-color: ${({ $contrast }) => $contrast ? 'rgb(118, 29, 58)' : 'rgb(192, 244, 248)'};
    }
`;

internals.NotificationSettings = function GroupNotificationSettings(props) {

    const {
        headerNotificationSettingsRef,
        notificationLevel
    } = props;

    const [notificationSettings, setNotificationSettings] = useState(notificationLevel);

    const setRadioVal = (field) => {

        return (ev, ...a) => {

            const { value } = ev.target;

            switch (field) {
                case 'notification-settings':
                    setNotificationSettings(value);
                    headerNotificationSettingsRef.current = value;
                    return;
            }
        };
    };

    return (<div>
        <RadioButtonGroup
            name='notification-settings'
            labelId='notification-settings-label'
            valueSelected={notificationSettings}
            onChange={setRadioVal('notification-settings')}
        >
            <RadioButton
                value='every'
                label='Based on your amount of activity'
                aria-label='Every message'
                dataFocusOutline='radius:30,padding:-4'
            />
            <RadioButton
                value='muted'
                label='Muted'
                aria-label='Muted'
                dataFocusOutline='radius:30,padding:-4'
            />
        </RadioButtonGroup>
    </div>);
};

internals.NotificationSettings.propTypes = {
    headerNotificationSettingsRef: T.object,
    notificationLevel: T.string
};
