const Map = require('lodash/map');
const { combineReducers } = require('redux');
const { SIGNUP: Types, AUTH: AuthTypes } = require('../action-types');
const SignupDomain = require('../domain/signup');
const PrefsDomain = require('../domain/preferences');
const { getSignupVars, getIsInternalEmail } = require('utils/signup-context');

const signupContext = (state = getSignupVars('context'), action) => {

    switch (action.type) {
        case Types.CONTEXT_INIT: {

            const payload = { ...action.payload };

            if (getIsInternalEmail(payload.email)) {
                payload.usingSSO = false;
                payload.usingPassword = true;
            }

            return payload || {};
        }

        case Types.CONTEXT_UPDATE: {

            const payload = { ...action.payload };

            if (getIsInternalEmail(payload.email)) {
                payload.usingSSO = false;
                payload.usingPassword = true;
            }

            return {
                ...state,
                ...payload
            };
        }

        case Types.SCHOOL_CHECK.SUCCESS: {

            const { email } = action.payload.request;

            const isInternalEmail = getIsInternalEmail(email);

            const extras = {
                sso: action.payload.result.sso
            };

            if (isInternalEmail || !extras.sso) {
                extras.usingPassword = true;
                extras.usingSSO = false;
            }
            else {
                extras.usingPassword = false;
                extras.usingSSO = true;
            }

            return {
                ...state,
                ...action.payload.request,
                ...action.payload.result,
                ...extras
            };
        }

        case AuthTypes.LOGIN_AUTH_ZERO_TOKEN.SUCCESS:
            return {
                ...state,
                verificationToken: action.payload.result.verificationToken || state.verificationToken,
                roleId: action.payload.result.roleId || state.roleId,
                hasSSOToken: true
            };
        case Types.STEP_3.FETCH_SIGNUP_PROGRESS.SUCCESS:
            return {
                ...state,
                fetchedProgress: true
            };
        case Types.STEP_2.CHECK_PASSWORD.SUCCESS:
            return {
                ...state,
                verificationToken: action.payload.result.data.verificationToken || state.verificationToken,
                isVerified: action.payload.result.data.isVerified,
                isEmailVerified: action.payload.result.data.isEmailVerified,
                hasPassword: action.payload.result.data.hasPassword,
                hasSSOToken: action.payload.result.data.hasSSOToken
            };
        case Types.STEP_2.COMPLETE.SUCCESS: {

            // eslint-disable-next-line no-unused-vars
            const { password, ...payload } = { ...action.payload.request };

            return {
                ...state,
                ...payload
            };
        }

        case Types.STEP_2.COMPLETE_SSO:
            return {
                ...state,
                ...action.payload
            };
        case Types.VERIFY_EMAIL.SUCCESS:
            return {
                ...state,
                isEmailVerified: true
            };
        // Empty on login
        case AuthTypes.LOGIN.SUCCESS:
        case AuthTypes.LOGIN_TOKEN.SUCCESS:
        case AuthTypes.LOGOUT.SUCCESS:
        case AuthTypes.CLOSE_ACCOUNT.SUCCESS:
        case Types.COMPLETE.SUCCESS:
            return {};
        default:
            return state;
    }
};

const step1 = (state = getSignupVars('step1'), action) => {

    switch (action.type) {
        case Types.STEP_1.COMPLETE.SUCCESS:
            return action.payload.request;
        // Empty on login
        case AuthTypes.LOGIN.SUCCESS:
        case AuthTypes.LOGIN_TOKEN.SUCCESS:
        case AuthTypes.LOGOUT.SUCCESS:
        case AuthTypes.CLOSE_ACCOUNT.SUCCESS:
        case Types.COMPLETE.SUCCESS:
            return {};
        default:
            return state;
    }
};

const step2 = (state = getSignupVars('step2'), action) => {

    switch (action.type) {
        case Types.STEP_2.COMPLETE.SUCCESS:
            return {
                ...state,
                ...action.payload.request
            };
        case Types.STEP_2.COMPLETE_SSO:
            return {
                ...state,
                ...action.payload
            };
        case Types.STEP_2.CHECK_PASSWORD.SUCCESS:
            return {
                ...state,
                hasPassword: action.payload.result.data.hasPassword,
                hasSSOToken: action.payload.result.data.hasSSOToken
            };
        case AuthTypes.LOGIN_AUTH_ZERO_TOKEN.SUCCESS:
            return {
                ...state,
                hasSSOToken: true
            };
        // Empty on login
        case AuthTypes.LOGIN.SUCCESS:
        case AuthTypes.LOGIN_TOKEN.SUCCESS:
        case AuthTypes.LOGOUT.SUCCESS:
        case AuthTypes.CLOSE_ACCOUNT.SUCCESS:
        case Types.COMPLETE.SUCCESS:
            return {};
        default:
            return state;
    }
};

const step3 = (state = {}, action) => {

    switch (action.type) {
        case Types.STEP_3.COMPLETE:
        case Types.STEP_3.GO_BACK:
            return action.payload;
        case Types.STEP_3.FETCH_SIGNUP_PROGRESS.SUCCESS: {

            const fetchedProgress = action && action.payload && action.payload.result;
            const fetchedPrefs = fetchedProgress && fetchedProgress.preferences || {};

            const formattedProgress = fetchedProgress ? Object.keys(fetchedProgress)
                .reduce((collector, key) => {

                    const value = fetchedProgress[key];

                    if (PrefsDomain.prefFields.includes(key)
                    || PrefsDomain.profileFields.includes(key)
                    ) {

                        if (!collector.preferences) {
                            collector.preferences = state.preferences || fetchedPrefs;
                        }

                        collector.preferences[key] = value;

                        return collector;
                    }

                    collector[key] = fetchedProgress[key];

                    return collector;
                }, {}) : null;

            return {
                ...state,
                ...formattedProgress
            };
        }

        // Empty on login
        case AuthTypes.LOGIN.SUCCESS:
        case AuthTypes.LOGIN_TOKEN.SUCCESS:
        case AuthTypes.LOGOUT.SUCCESS:
        case AuthTypes.CLOSE_ACCOUNT.SUCCESS:
        case Types.COMPLETE.SUCCESS:
            return {};
        default:
            return state;
    }
};

const signupCompleted = (state = false, action) => {

    switch (action.type) {
        case Types.SIGNUP_COMPLETED:
            return true;
        default:
            return state;
    }
};

const stripNulls = (obj) => {

    return Object.keys(obj).reduce((collector, key) => {

        if (obj[key] === null) {
            return collector;
        }

        return { ...collector, [key]: obj[key] };
    }, {});
};

const reducer = combineReducers({
    context: signupContext,
    step1,
    step2,
    step3,
    signupCompleted
});

const selectors = {

    initPrerequisiteSatisfied: (state) => {

        return Object.keys(SignupDomain.Init)
            .every((domainKey) => {

                return typeof state.context[domainKey] !== 'undefined';
            });
    },

    step2PrerequisiteSatisfied: (state) => {

        const { context } = require('app-context');

        const redux = context.redux.hooks;
        const appSelectors = context.selectors.all;

        const mainState = redux.getState();

        const {
            usingSSO,
            usingPassword,
            verificationToken
        } = appSelectors.getSignupContext(mainState);

        const {
            hasPassword,
            hasSSOToken,
            password,
            agreeToTerms
        } = appSelectors.getSignupDetailsForStep2(mainState);

        // These are required for password and SSO
        if (!agreeToTerms || !verificationToken) {
            return false;
        }

        if (usingPassword && !(password || hasPassword)) {
            return false;
        }

        if (usingSSO && !hasSSOToken) {
            return false;
        }

        return true;
    },

    getSignupContext: (state) => state.context,
    getSignupDetailsForStep1: (state) => state.step1,
    getSignupDetailsForStep2: (state) => state.step2,
    getSignupDetailsForStep3: (state) => state.step3,
    getSignupCompleted: (state) => state.signupCompleted,

    getSignupStep3Role: (state) => {

        const step3Vals = selectors.getSignupDetailsForStep3(state);
        return step3Vals.role || {};
    },
    getSignupStep3RolePermissions: (state) => {

        const step3Vals = selectors.getSignupDetailsForStep3(state);
        return step3Vals.role?.permissions || {};
    },

    getSignupDetailsForCompletion: (state) =>

        Object.assign({},
            state.context,
            state.step2,
            state.step3, {
                type: state.step3.type,
                profileEmail: state.step3.profileEmail,
                birthdate: state.step3.birthdate,
                department: state.step3.departmentId,
                officeId: state.step3.officeId,
                yearHiredId: state.step3.yearHiredId,
                workRemote: state.step3.workRemote,
                major: state.step3.majorId ? [state.step3.majorId] : undefined,
                interests: JSON.stringify(Map(state.step3.interests, 'id')),
                passionInterests:  JSON.stringify(Map(state.step3.passionInterests, 'id')),
                veteran: state.step3.veteran,
                openToSocial: state.step3.openToSocial,
                fullTime: state.step3.fullTime,
                gender: state.step3.gender,
                housing: stripNulls(state.step3.housing || {}),
                transfer: stripNulls(state.step3.transfer || {}),
                preferences: state.step3.preferences,
                hometown: state.step3.hometown,
                incomingClass: state.step3.incomingClass,
                graduatingClass: state.step3.graduatingClass,
                onlineStudent: state.step3.onlineStudent
            }
        )
};

module.exports = Object.assign(reducer, { selectors });
