const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: Select } = require('@mui/material/Select');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: MenuItem } = require('@mui/material/MenuItem');

const internals = {};

module.exports = function SelectField(props) {

    const {
        value,
        options,
        onChange,
        variant = 'outlined',
        includeSelect = false,
        label,
        labelId,
        inputProps = {},
        ...rest
    } = props;

    const { SelectInputLabel } = internals;

    if (labelId) {
        inputProps['aria-labelledby'] = labelId;
    }

    return (
        <FormControl fullWidth variant={variant}>
            {label && <SelectInputLabel id={labelId}>{label}</SelectInputLabel>}
            <Select
                labelId={labelId}
                inputProps={inputProps}
                variant={variant}
                fullWidth
                value={value}
                onChange={onChange}
                {...rest}
            >
                {includeSelect && <MenuItem value='' key='select' aria-label='Select'>Select...</MenuItem>}
                {[].concat(options).map(({ value: optionValue, label: optionLabel }) => {

                    return (
                        <MenuItem
                            value={optionValue}
                            key={optionLabel}
                            aria-label={optionLabel}
                        >
                            {optionLabel}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

module.exports.propTypes = {
    value: T.any,
    options: T.arrayOf(T.shape({
        value: T.any
    })).isRequired,
    variant: T.oneOf(['standard', 'outlined']),
    onChange: T.func,
    includeSelect: T.bool,
    label: T.string,
    labelId: T.string,
    className: T.string,
    inputProps: T.object
};

internals.SelectInputLabel = Styled(InputLabel)`
    background: white;
    padding: 0 6px;
    margin-left: -6px;
`;
