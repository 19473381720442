const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { context } = require('app-context');
const UniqueId = require('lodash/uniqueId');

const { default: Button } = require('@mui/material/Button');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: InputAdornment } = require('@mui/material/InputAdornment');
const { default: VisibilityIcon } = require('@mui/icons-material/Visibility');
const { default: VisibilityOffIcon } = require('@mui/icons-material/VisibilityOff');
const CenteredLayout = require('components/CenteredLayout');
const { NavLink: Link } = require('react-router-dom');
const { default: Classes } = require('./styles.scss');
const Fonts = require('styles/fonts.json');
const Colors = require('styles/colors.json');
const { analyticsTemplates } = require('utils/analytics');
const IsEmail = require('utils/is-email');
const NearpeerLogo = require('static/nearpeer_logo_white.svg');
const PlainLayout = require('layouts/PlainLayout');
const Footer = require('components/Footer');
const Theme = require('styles/theme');
const { default: CircularProgress } = require('@mui/material/CircularProgress');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const AlertDialog = require('containers/AlertDialog');
const LoginTextField = require('components/LoginTextField');

const redux = context.redux.hooks;
const selectors = context.selectors.all;

const internals = {};

class Login extends React.PureComponent {

    static propTypes = {
        initContext: T.func.isRequired,
        checkSchoolSSO: T.func.isRequired,
        onSubmit: T.func.isRequired,
        onInvalidSubmit: T.func.isRequired,
        push: T.func.isRequired,
        isAuthenticated: T.bool,
        location: T.object
    }

    constructor(props) {

        super(props);

        this.id = UniqueId('login-');

        this.fields = [
            'email',
            'password'
        ];

        this.state = {
            email: '',
            password: '',
            isSubmitting: false,
            emailErrorShow: false,
            passwordErrorShow: false,
            showCurrentPassword: false,
            isEmailStep: true,
            invalidLoginPayload: false
        };

        this.mounted = false;
    }

    componentDidMount() {

        this.mounted = true;

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.props.isAuthenticated && this.mounted) {
            this.props.push('/app/welcome');
        }

        if (this.props.location && this.props.location.state && this.props.location.state.email && this.props.location.state.email.length && this.mounted) {
            this.emailChanged(this.props.location.state.email);
        }
    }

    componentWillUnmount() {

        this.mounted = false;
    }

    emailChanged = (evt) => {

        this.setState({
            email: evt.target.value,
            isEmailStep: true
        });
    }

    validate = (field) => {

        const { isEmailStep } = this.state;
        const value = this.state[field];

        switch (field) {
            case 'email':

                if (!value) {
                    return 'Email is required';
                }

                if (!IsEmail(value)) {
                    return 'Invalid email address';
                }

                break;

            case 'password':

                if (!isEmailStep && !value) {
                    return 'Password is required';
                }

                break;
        }

        return null;
    }

    showError = (field, show) => {

        show = (typeof show === 'undefined') ?  this.validate(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    error = (field) => {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        const error = this.validate(field);

        if (error !== null) {
            return <div
                id={this.id + '-' + field + '-error-message'}
                role='alert'
                aria-live='assertive'
            >
                {error}
            </div>;
        }

        return null;
    }

    hasError = (field) => {

        if (this.validate(field) !== null) {
            return true;
        }

        return false;
    }

    getDescribedById = (field) => {

        if (this.hasError(field)) {
            return (this.id + '-' + field + '-error-message');
        }

        return null;
    }

    passwordChanged = (evt) => {

        this.setState({ password: evt.target.value });
    }

    _validatePassword(ev) {

        const password = ev.target.value;

        if (!password) {
            return this.setState({ passwordError: 'Password is required' });
        }

        return this.setState({ passwordError: null });
    }

    onKeyDown = (ev) => {

        if (ev.key === 'Enter') {

            this.submitOrError();
        }
    }

    submitOrError = async () => {

        const { isEmailStep, email, password } = this.state;

        const {
            initContext,
            checkSchoolSSO,
            push
        } = this.props;

        const formattedEmail = email.replaceAll(' ', '');

        analyticsTemplates.buttons('login', 'login: login');

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {
            // On submit attempt, all errors are fair game to display
            this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = true;

                return collector;
            }, {}), AnimatedFocusIndicator.wrapFocusIndicatorForElement);

            return this.props.onInvalidSubmit(this.state);
        }

        AnimatedFocusIndicator.onBlurHandler();

        this.setState({ isSubmitting: true });

        if (isEmailStep) {
            initContext({
                email: formattedEmail
            });

            const res = await checkSchoolSSO({
                email: formattedEmail
            });

            // Err gets caught in error-handling
            if (res === undefined) {
                this.setState({ isSubmitting: false });
                return;
            }

            const {
                usingSSO,
                isVerified
            } = selectors.getSignupContext(redux.getState());

            if (usingSSO && !isVerified) {
                push('/signup-sso');
            }
            else if (usingSSO) {
                push('/login-sso');
            }
            else {
                // Give time for the focus indicator to hide, otherwise there's a jarring visual
                setTimeout(() => {

                    this.setState({ isEmailStep: false });
                }, 50);

                this.setState({ isSubmitting: false });
            }
        }
        else {
            await this.props.onSubmit({
                email: formattedEmail,
                password
            });

            this.setState({ isSubmitting: false });
        }
    }

    handleMouseDownCurrentPassword = (event) => {

        event.preventDefault();
    }

    handleClickShowCurrentPassword = () => {

        this.setState({
            showCurrentPassword:
            !this.state.showCurrentPassword
        });
    };

    render() {

        const {
            isEmailStep,
            isSubmitting,
            showCurrentPassword,
            email,
            password,
            passwordErrorShow
        } = this.state;

        const {
            StyledButton,
            TitleLockup,
            ProgressContainer,
            LinkContainer
        } = internals;

        return <PlainLayout>
            <CenteredLayout
                mainClass={Classes.mainClass}
                footer={<Footer/>}
                footerClass={Classes.footerClass}
            >
                <div style={{ margin: '.67em 0 0', fontSize: '2.75em' }}>
                    <img style={{ height:'1em' }} src={NearpeerLogo} alt='' />
                </div>
                <TitleLockup>
                    <h1 className={Classes.header} tabIndex={0}>Log&nbsp;In</h1>
                    {isSubmitting && <ProgressContainer>
                        <CircularProgress size={25} thickness={6} color='text' />
                    </ProgressContainer>}
                </TitleLockup>
                <div className={Classes.inputs} onKeyDown={this.onKeyDown}>
                    <LoginTextField
                        type='email'
                        label='Email'
                        autoFocus={isEmailStep}
                        onChange={this.emailChanged}
                        value={email}
                        error={this.state.emailErrorShow && this.error('email')}
                        aria-describedby={this.getDescribedById('email')}
                    />
                    <LoginTextField
                        type={showCurrentPassword ? 'text' : 'password'}
                        label='Password'
                        autoFocus={!isEmailStep}
                        onChange={this.passwordChanged}
                        value={password}
                        error={passwordErrorShow && this.error('password')}
                        aria-describedby={this.getDescribedById('password')}
                        style={{ display: isEmailStep ? 'none' : 'block' }}
                        InputProps={{
                            endAdornment:<InputAdornment position='end'>
                                <IconButton
                                    aria-label='show current password'
                                    onClick={this.handleClickShowCurrentPassword}
                                    data-focus-outline='radius:40'
                                >
                                    {this.state.showCurrentPassword && <VisibilityIcon htmlColor={Theme.palette.primary.contrastText} />}
                                    {!this.state.showCurrentPassword && <VisibilityOffIcon htmlColor={Theme.palette.primary.contrastText} />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </div>
                <StyledButton
                    onClick={this.submitOrError}
                    color={'primary'}
                    disabled={isSubmitting}
                    data-focus-outline='radius:40,padding:4'
                >
                    {isEmailStep ? 'Continue' : 'Submit'}
                </StyledButton>
                <div style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                    <LinkContainer>
                        <Link
                            onClick={() => analyticsTemplates.buttons('login', 'login: create account')}
                            className={Classes.link}
                            to='/signup'
                            data-focus-outline='radius:4,padding:5'
                        >
                            Create Account
                        </Link>
                    </LinkContainer>
                    <LinkContainer>
                        <Link
                            onClick={() => analyticsTemplates.buttons('login', 'login: forgot password')}
                            className={Classes.link}
                            to='/forgot-password'
                            data-focus-outline='radius:4,padding:5'
                        >
                            Forgot Password
                        </Link>
                    </LinkContainer>
                    <LinkContainer>
                        <Link
                            onClick={() => analyticsTemplates.buttons('login', 'login: trouble logging in')}
                            className={Classes.link}
                            to='/login-help'
                            data-focus-outline='radius:4,padding:5'
                        >
                            Trouble Logging In?
                        </Link>
                    </LinkContainer>
                </div>
                <div className={Classes.spacer} />
                {/* Dialog controlled via actions
                    fired in error-handling */}
                <AlertDialog
                    title='Login Error'
                    confirmLabel='Ok'
                />
            </CenteredLayout>
        </PlainLayout>;
    }
}

module.exports = Login;

internals.LinkContainer = Styled.div`
    height: 27px;
`;

internals.TitleLockup = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
`;

internals.ProgressContainer = Styled.div`
    position: absolute;
    right: -40px;
    margin-bottom: 10px;
`;

internals.StyledButton = Styled(Button)`
    background-color: ${Colors.lightText};
    margin-bottom: 15px;
    height: 3em;
    width: 100%;
    &:hover {
        background-color: ${Colors.lightText}
    }
    font-family: ${Fonts.headerFont};
    font-weight: bold;
    text-transform: none;
    font-size: 1rem;
    line-height: 3em;
    border-radius: 40px;
`;
