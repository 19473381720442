const { useEffect, useRef } = require('react');
const IsEqual = require('lodash/isEqual');

exports.useDeepCompareEffect = function useDeepCompareEffect(callback, dependencies) {

    const previousDepsRef = useRef();

    // Track whether dependencies have changed deeply
    const haveDepsChanged = !IsEqual(previousDepsRef.current, dependencies);

    useEffect(() => {
        // Only run the effect if dependencies have changed
        if (haveDepsChanged) {
            callback();
            previousDepsRef.current = dependencies; // Update with the new dependencies
        }
    }, [callback, haveDepsChanged]); // eslint-disable-line react-hooks/exhaustive-deps
};
