const React = require('react');
const T = require('prop-types');
const { default: Badge } = require('@mui/material/Badge');

module.exports = (props) => {

    const {
        numUnreads,
        className,
        // TODO figure out what to do with 'mini'
        mini, // eslint-disable-line no-unused-vars
        dispatch, // eslint-disable-line no-unused-vars
        ...others
    } = props;

    return (
        <Badge
            // TODO make sure screen readers are able to read this info somewhere
            // aria-label={`${numUnreads} Unread Messages`}
            badgeContent={numUnreads}
            color='secondary'
            max={9}
            className={className}
            overlap='circular'
            {...others}
        />
    );
};

module.exports.displayName = 'UnreadMessagesBadge';

module.exports.propTypes = {
    numUnreads: T.number,
    className: T.string,
    mini: T.bool,
    dispatch: T.func
};
