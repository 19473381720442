const React = require('react');
const T = require('prop-types');
const { useState } = require('react');
const { useForm } = require('react-hook-form');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: AssignmentIcon } = require('@mui/icons-material/Assignment');
const { default: EditIcon } = require('@mui/icons-material/Edit');
const { default: StartIcon } = require('@mui/icons-material/PlayCircleFilled');
const { default: CheckIcon } = require('@mui/icons-material/Done');
const { default: ReplayIcon } = require('@mui/icons-material/Replay');
const { default: StopIcon } = require('@mui/icons-material/HighlightOff');
const { default: DeleteIcon } = require('@mui/icons-material/Delete');
const { NavLink: Link } = require('react-router-dom');
const { default: styled } = require('styled-components');
const InviteModal = require('../InviteModal');

// eslint-disable-next-line hapi/hapi-scope-start
const StyledListItem = styled(ListItem)(({ theme, isSolved }) => ({
    flexWrap: 'wrap',
    cursor: 'pointer',
    paddingRight: 96,
    paddingLeft: isSolved ? 8 : 16,
    background: isSolved === false ? `linear-gradient(0deg, #A79CDC 25%, #DDD9F0 100%)` : '',
    '&:hover': {
        backgroundColor: theme.palette.action.disabledBackground
    },
    '&.MuiListItem-container': {
        '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.palette.action.disabledBackground}`
        }
    }
}));

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

const SurveyListItem = (props) => {

    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

    const {
        survey,
        canEdit,
        canSeeReport,
        showStart,
        onDelete,
        onStop,
        onInviteUsers,
        schoolRoles,
        schoolRoleGroups,
        badges,
        departments,
        yearsHiredList,
        officesList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        searchResults,
        onClickResetSearchFilters,
        activeSearchFilter,
        interestsActiveFilter_survey,
        majors,
        schoolId,
        showSchoolInfo
    } = props;

    const { control, watch, setValue, reset } = useForm({
        defaultValues: {
            selectedRole: '',
            selectedUsers: []
        }
    });

    const handleInviteSubmit = (data) => {
        onInviteUsers({
            surveyId: survey.id,
            ...data
        });
        setIsInviteModalOpen(false);
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return 'Not set';
        }

        return new Date(dateString).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const secondaryText = (
        <div>
            <div style={{ marginBottom: 8 }}>{survey.description}</div>
            {showSchoolInfo && survey.school && (
                <div style={{
                    marginBottom: 8,
                    fontWeight: 'bold',
                    fontSize: '0.95rem',
                    color: 'black'
                }}>
                    {survey.school}
                </div>
            )}
            <div style={{
                display: 'flex',
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '0.875rem',
                gap: '16px'
            }}>
                <span>Start: {formatDate(survey.startDate)}</span>
                <span>End: {formatDate(survey.endDate)}</span>
            </div>
        </div>
    );

    return (
        <StyledListItem
            isSolved={survey.solved}
            component={(showStart && survey.isActive) ? Link : 'li'}
            to={(showStart && survey.isActive) ? `/app/surveys/${survey.id}/start` : null}
        >
            <ListItemText
                primary={survey.title}
                secondary={secondaryText}
            />
            <StyledListItemSecondaryAction>
                {!survey.solved ? null : <Tooltip arrow={true} title={'Survey Completed'} placement={'top'}>
                    <CheckIcon />
                </Tooltip>
                }
                {(showStart && survey.isActive) ? <Tooltip arrow={true} title={!survey.solved ? 'Start The Survey' : 'Edit my answers'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label={!survey.solved ? 'Start The Survey' : 'Edit my answers'}
                        component={Link}
                        to={`/app/surveys/${survey.id}/start`}
                        size='large'
                        style={{ paddingRight: !canEdit && survey.solved ? 0 : 12 }}
                    >
                        {!survey.solved ? <StartIcon /> : <ReplayIcon />}
                    </IconButton>
                </Tooltip> : null}
                {(canEdit && survey.status === 'active') ? <Tooltip arrow={true} title={'Stop Survey'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="Stop Survey"
                        onClick={() => onStop(survey)}
                        size="large">
                        <StopIcon />
                    </IconButton>
                </Tooltip> : null}
                {(canEdit && survey.status === 'upcoming') ? <Tooltip arrow={true} title={'Edit'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="edit"
                        component={Link}
                        to={`/app/surveys/${survey.id}/edit`}
                        size="large">
                        <EditIcon />
                    </IconButton>
                </Tooltip> : null}
                {(canEdit && survey.status === 'upcoming') ? <Tooltip arrow={true} title={'Delete Survey'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="delete survey"
                        onClick={() => onDelete(survey)}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> : null}
                {!showStart && canEdit && (
                    <Tooltip arrow={true} title={'Copy Survey'} placement={'top'}>
                        <Link
                            to={`/app/surveys/create?copyFrom=${survey.id}`}
                            style={{ textDecoration: 'none' }}
                        >
                            <button
                                size="small"
                                color="primary"
                            >
                                Copy
                            </button>
                        </Link>
                    </Tooltip>
                )}
                {(survey?.schoolId === schoolId) && !showStart && canEdit && (
                    <Tooltip arrow={true} title={'Invite Users'} placement={'top'}>
                        <button
                            size="small"
                            color="primary"
                            onClick={() => setIsInviteModalOpen(true)}
                        >
                            Invite
                        </button>
                    </Tooltip>
                )}
                {(canSeeReport && survey.status !== 'upcoming') ? <Tooltip arrow={true} title={'Report'} placement={'top'}>
                    <IconButton
                        edge="end"
                        aria-label="report"
                        component={Link}
                        to={`/app/surveys/${survey.id}/report`}
                        size="large"
                        style={{ paddingRight: survey.solved ? 0 : 12 }}
                    >
                        <AssignmentIcon />
                    </IconButton>
                </Tooltip> : null}
            </StyledListItemSecondaryAction>
            <InviteModal
                control={control}
                setValue={setValue}
                watch={watch}
                reset={reset}
                open={isInviteModalOpen}
                onClose={() => setIsInviteModalOpen(false)}
                onSubmit={handleInviteSubmit}
                surveyId={survey.id}
                schoolRoles={schoolRoles}
                schoolRoleGroups={schoolRoleGroups}
                badges={badges}
                departments={departments}
                yearsHiredList={yearsHiredList}
                officesList={officesList}
                isCompany={isCompany}
                isCommunity={isCommunity}
                isSchoolOnline={isSchoolOnline}
                searchResults={searchResults}
                onClickResetSearchFilters={onClickResetSearchFilters}
                activeSearchFilter={activeSearchFilter}
                interestsActiveFilter_survey={interestsActiveFilter_survey}
                majors={majors}
            />

        </StyledListItem>
    );
};

SurveyListItem.propTypes = {
    survey: T.shape({
        id: T.number,
        title: T.string,
        description: T.string,
        isActive: T.bool,
        started: T.bool,
        solved: T.bool,
        status: T.oneOf(['active', 'upcoming', 'finished']),
        startDate: T.string,
        endDate: T.string,
        school: T.string,
        schoolId: T.number
    }),
    canEdit: T.bool,
    canSeeReport: T.bool,
    showStart: T.bool,
    onStop: T.func,
    onDelete: T.func,
    onInviteUsers: T.func,
    schoolRoles: T.array,
    schoolRoleGroups: T.array,
    badges: T.array,
    departments: T.array,
    yearsHiredList: T.array,
    officesList: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    searchResults: T.array,
    onClickResetSearchFilters: T.func,
    activeSearchFilter: T.object,
    interestsActiveFilter_survey: T.array,
    majors: T.array,
    schoolId: T.number,
    showSchoolInfo: T.bool
};

module.exports = SurveyListItem;
