const { useState, useLayoutEffect } = require('react');

exports.useResizeObserver = function useResizeObserver(ref) {

    const [size, setSize] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {

        if (!ref.current) {
            return;
        }

        const updateSize = () => {

            if (ref.current) {
                setSize({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight
                });
            }
        };

        // Initialize ResizeObserver
        const observer = new ResizeObserver(() => updateSize());
        observer.observe(ref.current);

        // Initial size update
        updateSize();

        // Cleanup observer on unmount
        return () => observer.disconnect();
    }, [ref]);

    return size;
};
