const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: Popper } = require('@mui/material/Popper');
const { default: Paper } = require('@mui/material/Paper');
const { default: Menu } = require('@mui/material/Menu');

const AppMenuItem = require('./AppMenuItem');

const {
    useState,
    useRef
} = React;

const internals = {};

// TODO submenu items aren't currently focusable.
// We'll want to update that for a11y

const StyledIconButton = Styled(IconButton)`
    &:focus-visible {
        background-color: rgba(255, 255, 255, 0.4);
    }
`;

module.exports = function AppMenu(props) {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleIconClick = (event) => {

        setOpen((prevOpen) => !prevOpen);
    };

    const closeMainMenu = () => {

        setOpen(false);
    };

    const {
        Icon,
        push,
        items
    } = props;

    return (
        <React.Fragment>
            <StyledIconButton
                id='main-menu-button'
                ref={anchorRef}
                aria-label='Main dropdown menu'
                aria-controls={open ? 'app-menu' : undefined}
                aria-expanded={open}
                aria-haspopup='true'
                onClick={handleIconClick}
                size='large'
            >
                {Icon}
            </StyledIconButton>
            <Popper
                placement='bottom-start'
                disablePortal={true}
                open={open}
                anchorEl={anchorRef.current}
                transition
            >
                <Paper>
                    <Menu
                        id='app-menu'
                        anchorEl={anchorRef.current}
                        open={open}
                        onClose={closeMainMenu}
                        MenuListProps={{
                            'aria-labelledby': 'main-menu-button',
                            role: 'menu'
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        {items.map((item, index) => {

                            return <AppMenuItem
                                key={item.name}
                                closeMainMenu={closeMainMenu}
                                push={push}
                                autoFocus={index === 0}
                                {...item}
                                onClick={() => {

                                    if (item.onClick) {
                                        item.onClick();
                                    }

                                    if (item.link) {
                                        push(item.link);
                                    }

                                    if (!item.items) {
                                        closeMainMenu();
                                    }
                                }}
                            />;
                        })}
                    </Menu>
                </Paper>
            </Popper>
        </React.Fragment>
    );
};

module.exports.propTypes = {
    id: T.string.isRequired,
    Icon: T.element,
    items: T.arrayOf(T.shape({
        name: T.string.isRequired,
        link: T.string,
        Icon: T.elementType,
        items: T.array,
        inset: T.bool,
        disabled: T.bool,
        onClick: T.func
    })),
    push: T.func
};
