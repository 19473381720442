const React = require('react');
const T = require('prop-types');
const { useState, useEffect, useMemo, useRef } = require('react');
const { default: Styled } = require('styled-components');
const SortBy = require('lodash/sortBy');
const { default: Button } = require('@mui/material/Button');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const { default: Select } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { default: ListItemText } = require('@mui/material/ListItemText');
const FullScreenDialog = require('containers/FullScreenDialog');
const { default: Classes } = require('./styles.scss');
const { NavLink: Link } = require('react-router-dom');
const { default: useMediaQuery } = require('@mui/material/useMediaQuery');
const InterestsInputField = require('containers/InterestsInputField');
const MaterialReactAutocomplete = require('components/material-react-autocomplete');
const FixMui4TextareaAutosizeAriaLabel = require('utils/fixMui4TextareaAutosizeAriaLabel');
const FuzzyFilter = require('utils/fuzzyFilter');
const {
    USERS_SORT_TYPE,
    STUDENT_USER_ROLE_NAMES
} = require('utils/constants');

const internals = {};

const FilterDialog = (props) => {

    const {
        onRequestClose,
        onSubmit,
        resetSearch,
        open,
        isParent,
        interests: interestsList,
        badges,
        discoverSortType,
        majors,
        canSearchHometown,
        canSearchHousing,
        conditionalDisplayFields: display,
        departments,
        officesList,
        yearsHiredList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        classId,
        classmateDiscoverSearchFilter,
        incomingClassList,
        graduatingClassList,
        isBlankSearch,
        appContextFilter,
        currentUserPermissions,
        availableRoleGroups,
        allRoles
    } = props;

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [studentName, setStudentName] = useState('');
    const [interests, setInterests] = useState([]);
    const [locationType, setLocationType] = useState('');
    const [radius, setRadius] = useState(null);
    const [incomingClass, setIncomingClass] = useState('');
    const [graduatingClass, setGraduatingClass] = useState('');

    const [careerAspiration, setCareerAspiration] = useState('');
    const [profession, setProfession] = useState('');
    const [bio, setBio] = useState('');
    const [departmentId, setDepartmentStateId] = useState('');
    const [officeId, setOfficeStateId] = useState('');
    const [yearHiredId, setYearHiredStateId] = useState('');
    const [workRemote, setWorkRemote] = useState('');
    const [title, setTitle] = useState('');
    const [openSocial, setOpenSocial] = useState('');

    const [isTransfer, setIsTransfer] = useState('');
    const [isVeteran, setIsVeteran] = useState('');
    const [isStudentOnline, setIsStudentOnline] = useState('');
    const [searchIsParent, setSearchIsParent] = useState('');
    const [badge, setBadge] = useState('');
    const [fullTimeStatus, setFullTimeStatus] = useState('');

    const bioInputEl = useRef(null);
    const [selectedRoleGroup, setSelectedRoleGroup] = useState('');
    const [selectedRoleGroupIsParent, setSelectedRoleGroupIsParent] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [filteredBadges, setFilteredBadges] = useState([]);
    const [filteredDepartments, setFilteredDepartments] = useState([]);
    const [majorText, _setMajorText] = useState('');
    const [majorId, setMajorId] = useState(null);
    const [majorIdError, setMajorIdError] = useState(null);

    const setMajorText = (value) => {

        _setMajorText(value);

        if (value === '') {
            setMajorId(null);
        }

        if (majorIdError) {
            setMajorIdError(null);
        }
    };

    const clearMajorId = () => {

        setMajorId(null);
        setMajorIdError(null);
    };

    const [canSelectedRoleGroupEditCareerAspiration, setCanSelectedRoleGroupEditCareerAspiration] = useState(false);
    const [canSelectedRoleGroupEditProfession, setCanSelectedRoleGroupEditProfession] = useState(false);
    const [canSelectedRoleGroupEditBio, setCanSelectedRoleGroupEditBio] = useState(false);
    const [canSelectedRoleGroupEditTitle, setCanSelectedRoleGroupEditTitle] = useState(false);
    const [canSelectedRoleGroupEditIncomingClass, setCanSelectedRoleGroupEditIncomingClass] = useState(false);
    const [canSelectedRoleGroupEditGraduatingClass, setCanSelectedRoleGroupEditGraduatingClass] = useState(false);
    const [canSelectedRoleGroupEditMajor, setCanSelectedRoleGroupEditMajor] = useState(false);
    const [canSelectedRoleGroupEditHomeTown, setCanSelectedRoleGroupEditHomeTown] = useState(false);
    const [canSelectedRoleGroupEditDepartment, setCanSelectedRoleGroupEditDepartment] = useState(false);
    const [canSelectedRoleGroupEditYearHired, setCanSelectedRoleGroupEditYearHired] = useState(false);
    const [canSelectedRoleGroupEditOffice, setCanSelectedRoleGroupEditOffice] = useState(false);
    const [canSelectedRoleGroupEditWorkRemote, setCanSelectedRoleGroupEditWorkRemote] = useState(false);
    const [canSelectedRoleGroupEditStudentName, setCanSelectedRoleGroupEditStudentName] = useState(false);
    const [canSelectedRoleGroupEditTransfer, setCanSelectedRoleGroupEditTransfer] = useState(false);
    const [canSelectedRoleGroupEditTimeStatus, setCanSelectedRoleGroupEditTimeStatus] = useState(false);
    const [canSelectedRoleGroupEditOnline, setCanSelectedRoleGroupEditOnline] = useState(false);
    const [canSelectedRoleGroupEditVeteran, setCanSelectedRoleGroupEditVeteran] = useState(false);
    const [canSelectedRoleGroupEditParent, setCanSelectedRoleGroupEditParent] = useState(false);
    const [canSelectedRoleGroupEditOpenSocial, setCanSelectedRoleGroupEditOpenSocial] = useState(false);

    const setInitialState = () => {

        setName('');
        setAge('');
        setStudentName('');
        setMajorId(null);
        setMajorText('');
        setInterests([]);
        setLocationType('');
        setRadius(null);
        setIncomingClass('');
        setGraduatingClass('');
        setIsTransfer('');
        setIsVeteran('');
        setIsStudentOnline('');
        setSearchIsParent('');
        setOpenSocial('');
        setCareerAspiration('');
        setProfession('');
        setBio('');
        setDepartmentStateId('');
        setOfficeStateId('');
        setYearHiredStateId('');
        setWorkRemote('');
        setTitle('');
        setBadge('');
        setFullTimeStatus('');

        //selected context filter
        if (appContextFilter !== 'all' && selectedRoleGroup === ''  && isBlankSearch){
            const currentSelectedRoleGroup = availableRoleGroups.find((item) => {

                return item.name === appContextFilter;
            });
            setSelectedRoleGroup(currentSelectedRoleGroup.name);
        }
    };

    const cancelSearch = async () => {

        await resetSearch(discoverSortType);
        onRequestClose();
    };

    const resetRoleDropdown = () => {

        const defaultSelectedRoleGroup = availableRoleGroups.find(({ name: _name }) => STUDENT_USER_ROLE_NAMES.includes(_name));
        setSelectedRoleGroup(defaultSelectedRoleGroup.name || '');
        setSelectedRoleGroupIsParent(false);
    };

    const setExistingClassmateFilter = () => {

        if (classmateDiscoverSearchFilter) {
            if (classmateDiscoverSearchFilter.major) {
                const savedMajorId = classmateDiscoverSearchFilter.major?.[0] || null;

                if (majorId) {

                    setMajorId(savedMajorId);
                    const savedMajorText = majors.find(({ id }) => id === savedMajorId)?.name;

                    if (savedMajorText) {
                        setMajorText(savedMajorText);
                    }
                }
            }

            if (classmateDiscoverSearchFilter.name) {
                setName(classmateDiscoverSearchFilter.name);
            }

            if (classmateDiscoverSearchFilter.age) {
                setAge(classmateDiscoverSearchFilter.age);
            }

            if (classmateDiscoverSearchFilter.contextFilter) {
                const currentSelectedRoleGroup = availableRoleGroups.find((item) => {

                    return item.name === classmateDiscoverSearchFilter.contextFilter;
                });
                setSelectedRoleGroup(currentSelectedRoleGroup.name);
            }

            if (classmateDiscoverSearchFilter.radius) {
                setRadius(classmateDiscoverSearchFilter.radius);
            }

            if (classmateDiscoverSearchFilter.isVeteran) {
                setIsVeteran(classmateDiscoverSearchFilter.isVeteran);
            }

            if (classmateDiscoverSearchFilter.searchIsParent) {
                setSearchIsParent(classmateDiscoverSearchFilter.searchIsParent);
            }

            if (classmateDiscoverSearchFilter.isTransfer) {
                setIsTransfer(classmateDiscoverSearchFilter.isTransfer);
            }

            if (classmateDiscoverSearchFilter.fullTimeStatus) {
                setFullTimeStatus(classmateDiscoverSearchFilter.fullTimeStatus);
            }

            if (classmateDiscoverSearchFilter.locationType) {
                setLocationType(classmateDiscoverSearchFilter.locationType);
            }

            if (classmateDiscoverSearchFilter.incomingClass) {
                setIncomingClass(classmateDiscoverSearchFilter.incomingClass);
            }

            if (classmateDiscoverSearchFilter.graduatingClass) {
                setGraduatingClass(classmateDiscoverSearchFilter.graduatingClass);
            }

            if (classmateDiscoverSearchFilter.interests && classmateDiscoverSearchFilter.interests.length > 0) {
                setInterests([...interestsList.filter((interest) => {

                    return classmateDiscoverSearchFilter.interests.includes(interest.id);
                })]);
            }

            if (classmateDiscoverSearchFilter.career) {
                setCareerAspiration(classmateDiscoverSearchFilter.career);
            }

            if (classmateDiscoverSearchFilter.profession) {
                setProfession(classmateDiscoverSearchFilter.profession);
            }

            if (classmateDiscoverSearchFilter.bio) {
                setBio(classmateDiscoverSearchFilter.bio);
            }

            if (classmateDiscoverSearchFilter.title) {
                setTitle(classmateDiscoverSearchFilter.title);
            }

            if (classmateDiscoverSearchFilter.departmentId) {
                setDepartmentStateId(classmateDiscoverSearchFilter.departmentId);
            }

            if (classmateDiscoverSearchFilter.officeId) {
                setOfficeStateId(classmateDiscoverSearchFilter.officeId);
            }

            if (classmateDiscoverSearchFilter.yearHiredId) {
                setYearHiredStateId(classmateDiscoverSearchFilter.yearHiredId);
            }

            if (classmateDiscoverSearchFilter.workRemote) {
                setWorkRemote(classmateDiscoverSearchFilter.workRemote);
            }

            if (classmateDiscoverSearchFilter.badge) {
                setBadge(classmateDiscoverSearchFilter.badge);
            }

            if (classmateDiscoverSearchFilter.openSocial) {
                setOpenSocial(classmateDiscoverSearchFilter.openSocial);
            }

            if (classmateDiscoverSearchFilter.studentName) {
                setStudentName(classmateDiscoverSearchFilter.studentName);
            }
        }
    };

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            setExistingClassmateFilter();
        }

        return () => {

            isMounted = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (canSelectedRoleGroupEditBio && bioInputEl && bioInputEl.current) {
                FixMui4TextareaAutosizeAriaLabel(bioInputEl.current,'Bio search field');
            }
        }

        return () => {

            isMounted = false;
        };

    }, [canSelectedRoleGroupEditBio]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (clearAll){
                submit();
            }
        }

        return () => {

            isMounted = false;
        };
    }, [clearAll]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {

            if (open) {
                if (availableRoleGroups.length === 1){
                    setSelectedRoleGroup(availableRoleGroups[0].name);
                }
                else {
                    if (!isBlankSearch) {
                        setExistingClassmateFilter();
                    }
                    else {

                        //selected context filter
                        if (appContextFilter !== 'all'){
                            const currentSelectedRoleGroup = availableRoleGroups.find((item) => {

                                return item.name === appContextFilter;
                            });
                            setSelectedRoleGroup(currentSelectedRoleGroup.name);
                        }
                        else {
                            resetRoleDropdown();
                        }
                    }
                }

                setClearAll(false);
            }
        }

        return () => {

            isMounted = false;
        };
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (isBlankSearch) {
                setInitialState();
                resetRoleDropdown();
            }
        }

        return () => {

            isMounted = false;
        };
    }, [isBlankSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            setInitialState();
            if (selectedRoleGroup === 'parent') {
                setSelectedRoleGroupIsParent(true);
            }
            else {
                setSelectedRoleGroupIsParent(false);
            }
            //FILTER BADGES USED BY ROLES SELECTED ROLE GROUP

            // TODO let's make some helpers to work with roles and role groups, check perms, etc.
            if (selectedRoleGroup && badges) {

                const currentRoleGroup = availableRoleGroups.find((item) => {

                    return item.name === selectedRoleGroup;
                });

                //Check permissions for all roles of selected role group
                const rolesOfRoleGroup = allRoles.length ? allRoles.filter((item) => {

                    return item.roleGroupId === currentRoleGroup.id;
                }) : [];

                const roleIds = rolesOfRoleGroup && rolesOfRoleGroup.map((role) => role.id);

                const badgesForPermission = badges.filter((badgeForPermission) => {

                    return roleIds.includes(badgeForPermission.roleId) && badgeForPermission.used === true;
                });

                setFilteredBadges(badgesForPermission);
            }

            //FILTER DEPARTMENTS USED BY ROLES OF SELECTED ROLE GROUP
            if (selectedRoleGroup && departments) {

                const currentRoleGroup = availableRoleGroups.find((item) => {

                    return item.name === selectedRoleGroup;
                });
                //Check permissions for all roles of selected role group
                const rolesOfRoleGroup = allRoles.length ? allRoles.filter((item) => {

                    return item.roleGroupId === currentRoleGroup.id;
                }) : [];
                const rolesIds = rolesOfRoleGroup && rolesOfRoleGroup.map((role) => role.id);
                const departmentsForSelectedRoleGroup = departments.filter((dept) => {
                    // eslint-disable-next-line react/prop-types
                    return dept.used === true && dept.usedBy.some((roleId) => rolesIds.includes(roleId));
                });

                setFilteredDepartments(departmentsForSelectedRoleGroup);
            }

            if (allRoles.length && selectedRoleGroup) {
                const currentRoleGroup = availableRoleGroups.find((item) => {

                    return item.name === selectedRoleGroup;
                });
                //Check permissions for all roles of selected role group
                const filteredRoles = allRoles.length ? allRoles.filter((item) => {

                    return item.roleGroupId === currentRoleGroup.id;
                }) : [];

                //Check permissions for all roles of selected role group
                const canEditCareerAspiration = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditCareerAspiration) : false;
                setCanSelectedRoleGroupEditCareerAspiration(canEditCareerAspiration);
                const canEditProfession = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditProfession) : false;
                setCanSelectedRoleGroupEditProfession(canEditProfession);
                const canEditBio = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditBio) : false;
                setCanSelectedRoleGroupEditBio(canEditBio);
                const canEditTitle = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTitle) : false;
                setCanSelectedRoleGroupEditTitle(canEditTitle);
                const canEditIncomingClass = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditIncomingClass) : false;
                setCanSelectedRoleGroupEditIncomingClass(canEditIncomingClass);
                const canEditGraduatingClass = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditGraduatingClass) : false;
                setCanSelectedRoleGroupEditGraduatingClass(canEditGraduatingClass);
                const canEditMajor = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditMajor) : false;
                setCanSelectedRoleGroupEditMajor(canEditMajor);
                const canEditHomeTown = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditHomeTown) : false;
                setCanSelectedRoleGroupEditHomeTown(canEditHomeTown);
                const canEditDepartment = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditDepartment) : false;
                setCanSelectedRoleGroupEditDepartment(canEditDepartment);
                const canEditWorkRemote = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditWorkRemote) : false;
                setCanSelectedRoleGroupEditWorkRemote(canEditWorkRemote);
                const canEditOffice = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOffice) : false;
                setCanSelectedRoleGroupEditOffice(canEditOffice);
                const canEditYearHired = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditYearHired) : false;
                setCanSelectedRoleGroupEditYearHired(canEditYearHired);
                /*const canEditBadge = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditBadge) : false;
                setCanSelectedRoleGroupEditBadge(canEditBadge);*/
                const canEditStudentName = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditStudentName) : false;
                setCanSelectedRoleGroupEditStudentName(canEditStudentName);
                const canEditTransfer = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTransfer) : false;
                setCanSelectedRoleGroupEditTransfer(canEditTransfer);
                const canEditTimeStatus = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTimeStatus) : false;
                setCanSelectedRoleGroupEditTimeStatus(canEditTimeStatus);
                const canEditOnline = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOnline) : false;
                setCanSelectedRoleGroupEditOnline(canEditOnline);
                const canEditVeteran = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditVeteran) : false;
                setCanSelectedRoleGroupEditVeteran(canEditVeteran);
                const canEditParent = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditParent) : false;
                setCanSelectedRoleGroupEditParent(canEditParent);
                const canEditOpenSocial = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOpenSocial) : false;
                setCanSelectedRoleGroupEditOpenSocial(canEditOpenSocial);
            }

            //if selected role is reseted reset search
            if (selectedRoleGroup === '' && open) {
                resetSearch(discoverSortType);
            }
        }

        return () => {

            isMounted = false;
        };
    }, [selectedRoleGroup]); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {

        const hasErrors = validateMajor();

        if (!hasErrors) {

            return onSubmit({ ...getSearchDetails(), classId }, discoverSortType);
        }
    };

    const submitAndClose = async (ev) => {

        ev.preventDefault();
        ev.stopPropagation();

        const res = await submit();

        if (res) {
            onRequestClose();
        }
    };

    const getSearchDetails = () => {

        const fieldsFromState = {
            name,
            age,
            studentName,
            major: majorId ? [majorId] : [],
            interests,
            radius,
            locationType,
            incomingClass,
            graduatingClass,
            isTransfer,
            isVeteran,
            isStudentOnline,
            searchIsParent,
            openSocial,
            departmentId,
            officeId,
            yearHiredId,
            workRemote,
            fullTimeStatus,
            badge,
            career: careerAspiration,
            profession,
            bio,
            title,
            contextFilter: selectedRoleGroup,
            contextFilterType: 'roleGroup'
        };

        if (!fieldsFromState.locationType) {
            if (canSearchHousing && !canSearchHometown) {
                fieldsFromState.locationType = 'housing';
            }
            else if (!canSearchHousing && canSearchHometown) {
                fieldsFromState.locationType = 'hometown';
            }
        }

        // radius and locationType rely on eachother and must be present or absent together

        if (fieldsFromState.radius && !fieldsFromState.locationType) {
            delete fieldsFromState.radius;
        }

        if (fieldsFromState.locationType && !fieldsFromState.radius) {
            delete fieldsFromState.locationType;
        }

        return fieldsFromState;
    };

    const validateMajor = () => {

        if (majorText && !majorId) {
            const errMsg = 'Please choose an item in the list';
            setMajorIdError(errMsg);
            return errMsg;
        }

        setMajorIdError(null);

        return false;
    };

    const clear = () => {

        setInitialState();
        setClearAll(true);
    };

    const clearFn = useRef(null);

    useEffect(() => {

        clearFn.current = clear;
    });

    const { ButtonGroup, FieldGroup, SectionHeader } = internals;

    let singleHousingOption = null;
    if ((canSearchHousing && discoverSortType !== USERS_SORT_TYPE.HOMETOWN &&  !canSearchHometown) || (canSearchHousing && discoverSortType === USERS_SORT_TYPE.HOUSING)) {

        if (isCompany || isCommunity || isSchoolOnline) {
            singleHousingOption = 'Where I Live';
        }
        else {
            singleHousingOption = 'housing';
        }

    }
    else if ((!canSearchHousing && canSearchHometown  && discoverSortType !== USERS_SORT_TYPE.HOUSING) || (canSearchHometown && discoverSortType === USERS_SORT_TYPE.HOMETOWN)) {

        if (isCompany || isCommunity || isSchoolOnline) {
            singleHousingOption = 'Where I Grew Up';
        }
        else {
            singleHousingOption = 'hometown';
        }
    }

    const pageTitle = 'Search';

    const sortedDepartments = SortBy(filteredDepartments, 'name');
    const sortedOffices = SortBy(officesList, 'name');
    const sortedMajors = useMemo(() => {

        return SortBy(majors, 'name');
    }, [majors.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const dialogProps = {
        onRequestClose,
        onSubmit,
        open,
        isParent,
        interests: interestsList,
        majors,
        filteredBadges,
        display,
        departments,
        yearsHiredList,
        officesList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        isStudentOnline,
        canSearchHometown,
        canSearchHousing,
        classId,
        incomingClassList,
        graduatingClassList,
        isBlankSearch,
        selectedRoleGroup,
        selectedRoleGroupIsParent
    };

    const nameExtraProps = {};

    const mqlMobile = useMediaQuery('(max-width: 600px)');

    if (mqlMobile) {
        nameExtraProps.style = {
            marginTop: 20
        };
    }

    if (!mqlMobile && STUDENT_USER_ROLE_NAMES.includes(selectedRoleGroup)) {
        nameExtraProps.style = {
            padding: '10px 0'
        };
        nameExtraProps.InputLabelProps = {
            style: {
                marginTop: 10
            }
        };
    }

    return (<FullScreenDialog
        title={<h2 className={Classes.filtersTitle}>{pageTitle}</h2>}
        {...dialogProps}
    >
        <form
            onSubmit={submitAndClose}
        >
            {availableRoleGroups.length > 1 ? <p className={Classes.headerInfoText}>Please choose the type of user and enter search criteria to match on.</p> : <p className={Classes.headerInfoText}>Please enter search criteria to match on.</p>}
            {availableRoleGroups.length > 1 && <FormControl sx={{
                minWidth: 200
            }}>
                <InputLabel id="selected-user-type">User Type</InputLabel>
                <Select
                    labelId="selected-user-type"
                    inputProps={{
                        'aria-labelledby': 'selected-user-type'
                    }}
                    value={selectedRoleGroup}
                    onChange={(ev) => setSelectedRoleGroup(ev.target.value)}
                >
                    {availableRoleGroups.map((x) => <MenuItem key={x.name} value={x.name}>{x.label}</MenuItem>)}
                </Select>
            </FormControl>}
            {selectedRoleGroupIsParent
                    && (currentUserPermissions.canViewStudentName || currentUserPermissions.canViewIncomingClass)
                    && (canSelectedRoleGroupEditStudentName || canSelectedRoleGroupEditIncomingClass)
                    && <SectionHeader style={{ gridColumn: 'span 2', marginTop: 25 }}>
                        About Students:
                    </SectionHeader>
            }
            {selectedRoleGroupIsParent
                && currentUserPermissions.canViewStudentName
                && canSelectedRoleGroupEditStudentName
                && <TextField
                    fullWidth
                    label='Student Name'
                    id={'student-name-input'}
                    value={studentName}
                    onChange={(ev) => setStudentName(ev.target.value)}
                />
            }
            {selectedRoleGroupIsParent && <SectionHeader style={{ marginTop: 30 }}>
                About Parents:
            </SectionHeader>}
            {selectedRoleGroup && <FieldGroup>
                <TextField
                    className={selectedRoleGroupIsParent ? 'fullWidth' : ''}
                    fullWidth
                    label='Name'
                    id={'name-input'}
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                    {...nameExtraProps}
                />
                {/*{canSelectedRoleGroupEditAge && <TextField
                    label='Age'
                    className={selectedRoleGroupIsParent ? 'fullWidth' : ''}
                    type='number'
                    id={'age-input'}
                    value={age}
                    onChange={(ev) => setAge(ev.target.value)}
                />}*/}
                {!selectedRoleGroupIsParent && currentUserPermissions.canViewMajor && canSelectedRoleGroupEditMajor && <div style={{ position: 'relative' }}>
                    <MaterialReactAutocomplete
                        // MaterialReactAutocomplete requires style and doesn't support className
                        openOnFocus
                        id='majors-autocomplete'
                        label='Major or Program'
                        aria-label='Major or Program'
                        style={{
                            width: '100%',
                            position: 'relative',
                            margin:'auto 0'
                        }}
                        inputRef={this.majorsTextfield}
                        menuProps={{ className: Classes.autocompleteMenu }}
                        errorStyle={{
                            position: 'absolute',
                            bottom: '-.75em'
                        }}
                        hintText='Type to search...'
                        handleClearField={() => {

                            setMajorText('');
                            clearMajorId();
                        }}
                        fullWidth
                        hideExtraPadding={mqlMobile}
                        onChange={({ id }) => {

                            setMajorId(id);
                        }}
                        onTextChange={setMajorText}
                        searchText={majorText}
                        value={majorId}
                        aria-invalid={!!majorIdError}
                        error={!!majorIdError}
                        helperText={majorIdError}
                        filter={FuzzyFilter}
                        maxSearchResults={10}
                        renderSuggestionsOnFocus
                        dataSource={sortedMajors}
                        dataSourceConfig={{
                            label: 'name',
                            id: 'id'
                        }}
                    />
                </div>}
                {currentUserPermissions.canViewCareerAspiration  && canSelectedRoleGroupEditCareerAspiration && <TextField
                    label={'Career Aspiration'}
                    id={'career-input'}
                    value={careerAspiration}
                    onChange={(ev) => setCareerAspiration(ev.target.value)}
                />}
                {currentUserPermissions.canViewProfession  && canSelectedRoleGroupEditProfession && <TextField
                    label={'Profession' }
                    id={'profession-input'}
                    value={profession}
                    onChange={(ev) => setProfession(ev.target.value)}
                />}
                {currentUserPermissions.canViewBio  && canSelectedRoleGroupEditBio && <TextField
                    label={'Bio'}
                    id={'bio-input'}
                    value={bio}
                    ref={bioInputEl}
                    multiline={true}
                    maxRows={3}
                    onChange={(ev) => setBio(ev.target.value)}
                />}
                {canSelectedRoleGroupEditHomeTown && (currentUserPermissions.canViewHomeTown) && <div className={`${Classes.fieldGroupContainer} ${!canSearchHometown && !canSearchHousing && Classes.fieldGroupContainerDisabled} fullWidth`}>
                    <div className={Classes.fieldGroupText}>
                        {!canSearchHousing && !canSearchHometown &&
                            ((isCompany || isCommunity || isSchoolOnline) ?
                                <span>
                                    <Link
                                        to='/app/profile/edit'
                                        data-focus-outline='radius:5'
                                    >
                                        Add “Where I Live” or “Where I Grew Up”
                                    </Link> to your profile to search for nearby {isCompany ? 'colleagues' : 'peers'}
                                </span> :
                                <span>
                                    <Link
                                        to='/app/profile/edit'
                                        data-focus-outline='radius:5'
                                    >
                                        Add a hometown or eligible housing location
                                    </Link> to your profile to search for nearby peers
                                </span>)
                        }
                    </div>
                    {(canSearchHousing || canSearchHometown) && <div className={Classes.rowContainer} role='group' aria-label='search for peers who live within this many miles or kilometers of location'>
                        <div className={`${Classes.fieldGroupItem} ${Classes.fieldGroupTextInline}`}>
                            {(canSearchHousing || canSearchHometown) && (currentUserPermissions.canViewHomeTown ) && canSelectedRoleGroupEditHomeTown && 'Within'}
                        </div>
                        <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>
                            <FormControl style={{ width: 160, margin:'0 0.5rem' }}>
                                <InputLabel id="distance-select">Distance</InputLabel>
                                <Select
                                    labelId="distance-select"
                                    inputProps={{
                                        'aria-labelledby': 'distance-select'
                                    }}
                                    value={radius}
                                    onChange={(ev) => setRadius(ev.target.value)}
                                >
                                    <MenuItem value={null}>Select…</MenuItem>
                                    <MenuItem value={0.5}>0.5 miles (0.8 km)</MenuItem>
                                    <MenuItem value={1}>1 mile (1.6 km)</MenuItem>
                                    <MenuItem value={5}>5 miles (8 km)</MenuItem>
                                    <MenuItem value={10}>10 miles (16 km)</MenuItem>
                                    <MenuItem value={15}>15 miles (24 km)</MenuItem>
                                    <MenuItem value={20}>20 miles (32 km)</MenuItem>
                                    <MenuItem value={50}>50 miles (80 km)</MenuItem>
                                    <MenuItem value={100}>100 miles (160 km)</MenuItem>
                                    <MenuItem value={500}>500 miles (804 km)</MenuItem>
                                </Select>
                            </FormControl>

                            of&nbsp;{singleHousingOption && `${(isCompany || isCommunity || isSchoolOnline) ? ` ${singleHousingOption}` : ` your ${singleHousingOption}`}`}
                        </div>
                        {!singleHousingOption && <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="location-select">location</InputLabel>
                                <Select
                                    labelId="location-select"
                                    inputProps={{
                                        'aria-labelledby': 'location-select'
                                    }}
                                    value={locationType}
                                    onChange={(ev) => setLocationType(ev.target.value)}
                                >
                                    <MenuItem value=''>Select…</MenuItem>
                                    {!selectedRoleGroupIsParent && <MenuItem value='housing' disabled={!canSearchHousing}>Where I Live</MenuItem>}
                                    <MenuItem value='hometown' disabled={!canSearchHometown}>{(isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : `My ${selectedRoleGroupIsParent ? 'City/Town' : 'Hometown'}`}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>}
                    </div>}
                </div>}
                {currentUserPermissions.canViewTitle && canSelectedRoleGroupEditTitle && <TextField
                    label='Title'
                    id={'title-input'}
                    value={title}
                    onChange={(ev) => setTitle(ev.target.value)}
                />}
                {currentUserPermissions.canViewDepartment && canSelectedRoleGroupEditDepartment && sortedDepartments.length > 0 && <FormControl  >
                    <InputLabel  id="department-select-input-label">Department</InputLabel>
                    <Select
                        labelId='department-select-input-label'
                        id={'department-select-input'}
                        inputProps={{
                            'aria-labelledby': 'department-select-input-label'
                        }}
                        fullWidth
                        value={departmentId}
                        MenuProps={{
                            className: 'role-dropdown'
                        }}
                        onChange={(ev) => setDepartmentStateId(ev.target.value)}
                        maxHeight={220}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        {sortedDepartments.map((department) => {

                            return <MenuItem
                                key={department.id}
                                value={department.id}
                            >
                                <ListItemText primary={department.name} />
                            </MenuItem>;
                        })}
                    </Select>
                </FormControl>}
                {currentUserPermissions.canViewOffice && canSelectedRoleGroupEditOffice && sortedOffices.length > 0 && <FormControl  >
                    <InputLabel  id="office-select-input-label">{isCompany ? 'Affiliated Office' : 'Campus'}</InputLabel>
                    <Select
                        labelId='office-select-input-label'
                        id={'office-select-input'}
                        inputProps={{
                            'aria-labelledby': 'office-select-input-label'
                        }}
                        fullWidth
                        value={officeId}
                        MenuProps={{
                            className: 'role-dropdown'
                        }}
                        onChange={(ev) => setOfficeStateId(ev.target.value)}
                        maxHeight={220}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        {sortedOffices.map((office) => {

                            return <MenuItem
                                key={office.id}
                                value={office.id}
                            >
                                <ListItemText primary={office.name} />
                            </MenuItem>;
                        })}
                    </Select>
                </FormControl>}
                {currentUserPermissions.canViewYearHired  && canSelectedRoleGroupEditYearHired &&  yearsHiredList.length > 0 && <FormControl  >
                    <InputLabel  id="year-hired-select-input-label">{'Year Hired'}</InputLabel>
                    <Select
                        labelId='year-hired-select-input-label'
                        id={'year-hired-select-input'}
                        inputProps={{
                            'aria-labelledby': 'year-hired-select-input-label'
                        }}
                        fullWidth
                        value={yearHiredId}
                        MenuProps={{
                            className: 'role-dropdown'
                        }}
                        onChange={(ev) => setYearHiredStateId(ev.target.value)}
                        maxHeight={220}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        {yearsHiredList.map((yearItem) => {

                            return <MenuItem
                                key={yearItem.id}
                                value={yearItem.id}
                            >
                                <ListItemText primary={yearItem.year} />
                            </MenuItem>;
                        })}
                    </Select>
                </FormControl>}
                {!!filteredBadges && filteredBadges.length > 0 && currentUserPermissions.canViewBadge  &&  (
                    <FormControl>
                        <InputLabel id="Badge-class">Badge</InputLabel>
                        <Select
                            labelId="Badge-class"
                            inputProps={{
                                'aria-labelledby': 'Badge-class'
                            }}
                            value={badge}
                            onChange={(ev) => setBadge(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {filteredBadges.map((x) => <MenuItem key={x.name} value={x.name}>{x.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}

                {currentUserPermissions.canViewTimeStatus  && canSelectedRoleGroupEditTimeStatus && <FormControl>
                    <InputLabel id="full-time-status">Full / Part-time</InputLabel>
                    <Select
                        labelId="full-time-status"
                        inputProps={{
                            'aria-labelledby': 'full-time-status'
                        }}
                        value={fullTimeStatus}
                        onChange={(ev) => setFullTimeStatus(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='full-time'>Full-time</MenuItem>
                        <MenuItem value='part-time'>Part-time</MenuItem>
                    </Select>
                </FormControl>}

                {currentUserPermissions.canViewTransfer  && canSelectedRoleGroupEditTransfer && <FormControl className={selectedRoleGroupIsParent ? 'fullWidth' : ''} >
                    <InputLabel id="transfer-status">Transfer Student</InputLabel>
                    <Select
                        labelId="transfer-status"
                        inputProps={{
                            'aria-labelledby': 'transfer-status'
                        }}
                        value={isTransfer}
                        onChange={(ev) => setIsTransfer(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}

                {!isSchoolOnline && currentUserPermissions.canViewOnline && canSelectedRoleGroupEditOnline && <FormControl>
                    <InputLabel id="online-student-status">Online Student</InputLabel>
                    <Select
                        labelId="online-student-status"
                        inputProps={{
                            'aria-labelledby': 'online-student-status'
                        }}
                        value={isStudentOnline}
                        onChange={(ev) => setIsStudentOnline(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}
                {currentUserPermissions.canViewOpenSocial  && canSelectedRoleGroupEditOpenSocial && <FormControl>
                    <InputLabel id="open-to-social-status">Open to Social</InputLabel>
                    <Select
                        labelId="open-to-social-status"
                        inputProps={{
                            'aria-labelledby': 'open-to-social-status'
                        }}
                        value={openSocial}
                        onChange={(ev) => setOpenSocial(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}

                {currentUserPermissions.canViewVeteran  && canSelectedRoleGroupEditVeteran && <FormControl>
                    <InputLabel id="veteran-status">Veteran</InputLabel>
                    <Select
                        labelId="veteran-status"
                        inputProps={{
                            'aria-labelledby': 'veteran-status'
                        }}
                        value={isVeteran}
                        onChange={(ev) => setIsVeteran(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}
                {currentUserPermissions.canViewParent  && canSelectedRoleGroupEditParent && <FormControl>
                    <InputLabel id="parent-status">Parent</InputLabel>
                    <Select
                        labelId="parent-status"
                        inputProps={{
                            'aria-labelledby': 'parent-status'
                        }}
                        value={searchIsParent}
                        onChange={(ev) => setSearchIsParent(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}
                {currentUserPermissions.canViewWorkRemote  && canSelectedRoleGroupEditWorkRemote && <FormControl>
                    <InputLabel id="work-remote-status">Work Remote</InputLabel>
                    <Select
                        labelId="work-remote-status"
                        inputProps={{
                            'aria-labelledby': 'work-remote-status'
                        }}
                        value={workRemote}
                        onChange={(ev) => setWorkRemote(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                </FormControl>}
                {/*PARENT SECTION ON BOTTOM*/}
                {selectedRoleGroupIsParent
                && currentUserPermissions.canViewIncomingClass
                && canSelectedRoleGroupEditIncomingClass
                && incomingClassList?.length > 0
                && (
                    <FormControl>
                        <InputLabel id="incoming-class">Incoming Class</InputLabel>
                        <Select
                            labelId="incoming-class"
                            inputProps={{
                                'aria-labelledby': 'incoming-class'
                            }}
                            value={incomingClass}
                            onChange={(ev) => setIncomingClass(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}

                {/* Add an extra div to force location search in the next row */}
                {selectedRoleGroupIsParent && <div />}

                {!selectedRoleGroupIsParent && incomingClassList?.length > 0 && currentUserPermissions.canViewIncomingClass && canSelectedRoleGroupEditIncomingClass && (
                    <FormControl>
                        <InputLabel id="incoming-class">Incoming Class</InputLabel>
                        <Select
                            labelId="incoming-class"
                            inputProps={{
                                'aria-labelledby': 'incoming-class'
                            }}
                            value={incomingClass}
                            onChange={(ev) => setIncomingClass(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}
                {!selectedRoleGroupIsParent && graduatingClassList?.length > 0 && currentUserPermissions.canViewGraduatingClass && canSelectedRoleGroupEditGraduatingClass && (
                    <FormControl>
                        <InputLabel id="graduating-class">Graduating Class</InputLabel>
                        <Select
                            labelId="graduating-class"
                            inputProps={{
                                'aria-labelledby': 'graduating-class'
                            }}
                            value={graduatingClass}
                            onChange={(ev) => setGraduatingClass(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {graduatingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}
                <div className={'fullWidth'}>
                    <InterestsInputField
                        values={interests}
                        isSearch={true}
                        indicatorZIndex={9999}
                        onChange={(ev, value) => {

                            setInterests(value);
                        }}
                    />
                </div>
            </FieldGroup>}
            <ButtonGroup role='group' aria-label='search buttons'>
                {selectedRoleGroup && <Button
                    variant='contained'
                    color='primary'
                    startIcon={<SearchIcon />}
                    style={{ gridArea:'submit' }}
                    // onClick={submitAndClose}
                    type={'submit'}
                    data-focus-outline='radius:40,padding:3'
                >
                    Search
                </Button>}
                {selectedRoleGroup && <Button
                    variant='contained'
                    onClick={clear}
                    style={{ gridArea:'clear' }}
                    type={'button'}
                    data-focus-outline='radius:40,padding:3'
                >
                    Clear
                </Button>}
                <Button
                    variant='contained'
                    color='secondary'
                    style={{ gridArea:'cancel' }}
                    onClick={cancelSearch}
                    startIcon={<CancelIcon />}
                    data-focus-outline='radius:40,padding:3'
                >
                    Cancel
                </Button>
            </ButtonGroup>
        </form>
    </FullScreenDialog>);
};

FilterDialog.propTypes = {
    onRequestClose: T.func.isRequired,
    onSubmit: T.func,
    resetSearch: T.func,
    open: T.bool,
    isParent: T.bool,
    interests: T.array.isRequired,
    badges: T.array.isRequired,
    majors: T.array.isRequired,
    departments: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    officesList: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    yearsHiredList: T.arrayOf(
        T.shape({
            id: T.number,
            year: T.number
        })
    ),
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    conditionalDisplayFields: T.shape({
        incomingClass: T.bool,
        graduatingClass: T.bool,
        onlineStudent: T.bool,
        housing: T.bool,
        openToSocial: T.bool,
        transfer: T.bool,
        major: T.bool,
        department: T.bool,
        career: T.bool,
        profession: T.bool,
        type: T.bool,
        parent: T.bool,
        title: T.bool
    }),
    allRoles: T.array,
    canSearchHometown: T.bool,
    canSearchHousing: T.bool,
    classId: T.number,
    incomingClassList: T.array,
    graduatingClassList: T.array,
    isBlankSearch: T.bool,
    schoolRoles: T.array,
    availableRoleGroups: T.array,
    appContextFilter: T.string,
    discoverSortType: T.string,
    currentUserPermissions: T.object,
    classmateDiscoverSearchFilter: T.object
};

module.exports = FilterDialog;

internals.FieldGroup = Styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)};
    grid-template-columns: 1fr;
    .fullWidth {
        grid-column: 1 / -1;
    }
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        .fullWidth {
            grid-column: span 2;
        }
    }
`;

internals.MajorField = Styled(TextField)`
    .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

internals.ButtonGroup = Styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing(1)};
    grid-template-columns: repeat(3, 1fr);
    margin-top : 0.5em;
    grid-template-areas: 'submit clear cancel';

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: repeat(2, 1fr);
        button {

            &:first-of-type {
                grid-column: span 2;
            }
        }
    }
`;

internals.SectionHeader = Styled(Typography).attrs({ variant: 'h2', align: 'center' })`
    && { font-size: 20px; font-weight: normal }
`;

internals.styles = {
    error: {
        position: 'absolute',
        bottom: '-.75em'
    },
    sectionHeader: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: '23px',
        margin: '35px 0 0',
        padding: 0
    },
    autocompleteMenu: {
        maxHeight: 220
    }
};
